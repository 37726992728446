import appAxios from "./config";
import parentServices from "./parent-services";
import studentServices from "./student-services";
import teacherServices from "./teacher-services";

const adminServices = {};

adminServices.loadTeachers = async (obj) => {
  const { data } = await appAxios.post("v1/admin/teachers", obj);
  return data;
};

adminServices.loadBooking = async (bookingId) => {
  const { data } = await appAxios.get("v1/admin/booking/" + bookingId);
  return data;
};

adminServices.loadAppointment = async (appointmentId) => {
  const { data } = await appAxios.get("v1/admin/appointment/" + appointmentId);
  return data;
};

adminServices.saveTeacher = async (obj) => {
  const { data } = await appAxios.post("v1/admin/teacher", obj);
  return data;
};

adminServices.saveParent = async (obj) => {
  const { data } = await appAxios.post("v1/admin/parent", obj);
  return data;
};

adminServices.loadAvailableTeachers = async (obj) => {
  const { data } = await appAxios.post("v1/admin/available-teachers", obj);
  return data;
};

adminServices.loadAvailableDates = async (obj) => {
  const { data } = await appAxios.post("v1/admin/load-available-dates", obj);
  return data;
};

adminServices.cancelBooking = async (obj) => {
  const { data } = await appAxios.post("v1/admin/booking/cancel", obj);
  return data;
};

adminServices.saveBookingFeedback = async (obj) => {
  const { data } = await appAxios.post("v1/admin/booking/feedback", obj);
  return data;
};

adminServices.deleteBooking = async (bookingId) => {
  const { data } = await appAxios.delete("v1/admin/booking/" + bookingId);
  return data;
};

adminServices.loadAvailableTimes = async (obj) => {
  const { data } = await appAxios.post("v1/admin/load-available-times", obj);
  return data;
};

adminServices.loadBookings = async (obj) => {
  const { data } = await appAxios.post("v1/admin/bookings", obj);
  return data;
};

adminServices.loadTeacher = async (teacherId) => {
  const { data } = await appAxios.get("v1/admin/teacher/" + teacherId);
  return data;
};

adminServices.loadEvaluators = async (obj) => {
  const { data } = await appAxios.post("v1/admin/evaluators", obj);
  return data;
};

adminServices.forgotPassword = async (obj) => {
  const { data } = await appAxios.post("v1/admin/forgot-password", obj);
  return data;
};

adminServices.resetForgotPassword = async (obj) => {
  const { data } = await appAxios.post("v1/admin/reset-forgot-password", obj);
  return data;
};

adminServices.loadParents = async (obj) => {
  const { data } = await appAxios.post("v1/admin/parents", obj);
  return data;
};

adminServices.loadParent = async (parentId) => {
  const { data } = await appAxios.get("v1/admin/parent/" + parentId);
  return data;
};

adminServices.loadLevels = async () => {
  const { data } = await appAxios.get("v1/admin/levels");
  return data;
};

adminServices.loadModules = async (obj) => {
  const { data } = await appAxios.post("v1/admin/modules", obj);
  return data;
};

adminServices.saveProgram = async (obj) => {
  const { data } = await appAxios.post("v1/admin/program", obj);
  return data;
};

adminServices.savePlan = async (obj) => {
  const { data } = await appAxios.post("v1/admin/plan", obj);
  return data;
};

adminServices.saveCourse = async (obj) => {
  const { data } = await appAxios.post("v1/admin/course", obj);
  return data;
};

adminServices.saveQuiz = async (obj) => {
  const { data } = await appAxios.post("v1/admin/quiz", obj);
  return data;
};

adminServices.setTeacherEvaluation = async (obj) => {
  const { data } = await appAxios.post("v1/admin/teacher/evaluator", obj);
  return data;
};

adminServices.loadStudents = async (obj) => {
  const { data } = await appAxios.post("v1/admin/students", obj);
  return data;
};

adminServices.loadStudent = async (studentId) => {
  const { data } = await appAxios.get("v1/admin/student/" + studentId);
  return data;
};

adminServices.loadActiveSubscriptions = async (obj) => {
  console.log("loadActiveSubscriptions---------------------", obj);
  const { data } = await appAxios.post("v1/admin/active-subscriptions", obj);
  return data;
};

adminServices.loadCoursesForBooking = async (obj) => {
  console.log("loadActiveSubscriptions---------------------", obj);
  const { data } = await appAxios.post(
    "v1/admin/load-courses-for-booking",
    obj,
  );
  return data;
};

adminServices.deleteProgram = async (programId) => {
  const { data } = await appAxios.delete("v1/admin/program/" + programId);
  return data;
};

adminServices.deletePlan = async (planId) => {
  const { data } = await appAxios.delete("v1/admin/plan/" + planId);
  return data;
};

adminServices.deleteCourse = async (courseId) => {
  const { data } = await appAxios.delete("v1/admin/course/" + courseId);
  return data;
};

adminServices.setCourseRequestStatus = async (obj) => {
  const { data } = await appAxios.post(
    "v1/admin/set-course-request-status",
    obj,
  );
  return data;
};

adminServices.setCourseRequestApproved = async (obj) => {
  const { data } = await appAxios.post(
    "v1/admin/set-course-request-approved",
    obj,
  );
  return data;
};

adminServices.deleteQuiz = async (quizId) => {
  const { data } = await appAxios.delete("v1/admin/quiz/" + quizId);
  return data;
};

adminServices.loadPrograms = async (obj) => {
  const { data } = await appAxios.post("v1/admin/programs", obj);
  return data;
};

adminServices.loadPlans = async (obj) => {
  const { data } = await appAxios.post("v1/admin/plans", obj);
  return data;
};

adminServices.loadCourses = async (obj) => {
  const { data } = await appAxios.post("v1/admin/courses", obj);
  return data;
};

adminServices.loadCourseRequest = async (obj) => {
  const { data } = await appAxios.post("v1/admin/load-courses-requests", obj);
  return data;
};

adminServices.loadTransactions = async (obj) => {
  const { data } = await appAxios.post("v1/admin/load-transactions", obj);
  return data;
};

adminServices.evaluateFirstQuizPDF = async ({ type, transactionId }) => {
  const { data } = await appAxios.get(
    "v1/admin/download-course-evaluation/" + type + "/" + transactionId,
  );
  return data;
};

adminServices.bookingPDF = async ({ transactionId }) => {
  const { data } = await appAxios.get(
    "v1/admin/download-booking/" + transactionId,
  );
  return data;
};

adminServices.loadQuizzes = async (obj) => {
  const { data } = await appAxios.post("v1/admin/quizzes", obj);
  return data;
};

adminServices.loadProgram = async (programId) => {
  const { data } = await appAxios.get("v1/admin/program/" + programId);
  return data;
};

adminServices.loadPlan = async (planId) => {
  const { data } = await appAxios.get("v1/admin/plan/" + planId);
  return data;
};

adminServices.loadCourse = async (courseId) => {
  const { data } = await appAxios.get("v1/admin/course/" + courseId);
  return data;
};

adminServices.loadQuiz = async (quizId) => {
  const { data } = await appAxios.get("v1/admin/quiz/" + quizId);
  return data;
};

adminServices.resetPassword = async (obj) => {
  const { data } = await appAxios.post("v1/admin/reset-password", obj);
  return data;
};

adminServices.saveProfileImage = async (base64) => {
  const { data } = await appAxios.post("v1/admin/profile/image", { base64 });
  return data;
};

adminServices.saveAppointment = async (obj) => {
  const { data } = await appAxios.post("v1/admin/appointment", obj);
  return data;
};

adminServices.deleteAppointment = async (appointmentId) => {
  const { data } = await appAxios.delete(
    "v1/admin/appointment/" + appointmentId,
  );
  return data;
};

adminServices.loadAppointments = async (obj) => {
  const { data } = await appAxios.post("v1/admin/appointments", obj);
  return data;
};

adminServices.loadEvents = async (obj) => {
  const { data } = await appAxios.post("v1/admin/events", obj);
  return data;
};

adminServices.saveStudent = async (obj) => {
  const { data } = await appAxios.post("v1/admin/student", obj);
  return data;
};

adminServices.saveStudentEvaluation = async (obj) => {
  const { data } = await appAxios.post("v1/admin/student/evaluation", obj);
  return data;
};

adminServices.cancelAppointment = async (obj) => {
  const { data } = await appAxios.post("v1/admin/appointment/cancel", obj);
  return data;
};

adminServices.saveBooking = async (obj) => {
  const { data } = await appAxios.post("v1/admin/booking", obj);
  return data;
};

adminServices.loadStudentByParent = async (parentId) => {
  const { data } = await appAxios.get("v1/admin/students/" + parentId);
  return data;
};
adminServices.loadSubscriptionsByParent = async (parentId) => {
  const { data } = await appAxios.get(`v1/admin/subscriptions/${parentId}`);
  return data;
};

adminServices.loadFreePacks = async () => {
  const { data } = await appAxios.get(`v1/admin/free-packs`);
  return data;
};

adminServices.deleteFreePlan = async (subscriptionId) => {
  const { data } = await appAxios.delete(
    `v1/admin/free-packs/` + subscriptionId,
  );
  return data;
};

adminServices.addFreePacks = async (obj) => {
  const { data } = await appAxios.post(`v1/admin/add-free-packs`, obj);
  return data;
};

adminServices.loadParentById = async (parentId) => {
  const { data } = await appAxios.get("v1/admin/parent/" + parentId);
  return data;
};

adminServices.loadBookingsByParent = async (obj) => {
  const { data } = await appAxios.post("v1/admin/bookings-by-parent", obj);
  return data;
};

adminServices.searchParents = async (searchTerm) => {
  const searchData = { search: searchTerm }; // Format the search term as an object
  const { data } = await appAxios.post("v1/admin/parents", searchData);
  return data;
};

adminServices.searchQuiz = async (searchTerm) => {
  const searchData = { search: searchTerm }; // Format the search term as an object
  const { data } = await appAxios.post("v1/admin/search-quiz", searchData);
  return data;
};

adminServices.saveDiscount = async (obj) => {
  const { data } = await appAxios.post("v1/admin/discount", obj);
  return data;
};

adminServices.loadDiscounts = async (obj) => {
  const { data } = await appAxios.post("v1/admin/discounts", obj);
  return data;
};

adminServices.loadDiscount = async (discountId) => {
  const { data } = await appAxios.get("v1/admin/discount/" + discountId);
  return data;
};

adminServices.loadDiscountCategories = async () => {
  const { data } = await appAxios.get("v1/admin/discount-categories");
  return data;
};

adminServices.loadCourseCategories = async () => {
  const { data } = await appAxios.get("v1/admin/course-categories");
  return data;
};

adminServices.loadSkills = async () => {
  const { data } = await appAxios.get("v1/admin/load-skills");
  return data;
};

adminServices.deleteDiscount = async (discountId) => {
  const { data } = await appAxios.delete("v1/admin/discount/" + discountId);
  return data;
};

export default adminServices;
