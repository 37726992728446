import React, { useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import DDImageField from "../../../../component/DDImageField";
import { Formik } from "formik";
import commonServices from "../../../../services/common-services";
import { useAppMutation } from "../../../../services";
import adminServices from "../../../../services/admin-services";
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";

const Step1 = ({ hideDialog, stepsData, handleNext, setStepsData }) => {
  const {
    mutate: loadSkills,
    isError: isSkillError,
    data: dataSkills,
    isSuccess: isSkillSuccess,
    isLoading: isSkillLoading,
  } = useAppMutation(adminServices.loadSkills);

  useEffect(() => {
    if (isSkillSuccess) {
      console.log("dataSkills----", dataSkills);
      setSkills(dataSkills);
    }
  }, [isSkillError, isSkillSuccess]);

  const [isLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    name: "",
    noOfBooking: "",
    noOfHours: "",
    //category: '',
    visible: false,
    active: false,
    profileImage: "",
    skills: [],
  });
  const [errMsg, setErrMsg] = React.useState("");
  const { t } = useTranslation();
  const [skills, setSkills] = React.useState([]);

  const handleChange = (prop) => (event) => {
    if (prop === "category") {
      setValues({
        ...values,
        [prop]: event.target.value,
        subCategory: undefined,
      });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const submitStep1 = async () => {
    setErrMsg("");

    const {
      name,
      noOfBooking,
      category,
      subCategory,
      visible,
      noOfHours,
      skills,
    } = values;

    if (name && noOfBooking && noOfHours) {
      const data = { ...stepsData, ...values };
      console.log("Values-------123", data);
      setStepsData(data);
      handleNext(2);
    } else {
      setErrMsg(t("errMsg.notFill"));
    }
  };

  useEffect(() => {
    if (stepsData?.name) {
      setValues({ ...stepsData });
      loadSkills();
    }
  }, [stepsData]);

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <FormControl
        sx={{ width: { xs: "100%", sm: "100%", xl: "100%" }, mt: 2 }}
      >
        <TextField
          required
          label={t("admin-plans.title.planName")}
          value={values.name}
          onChange={handleChange("name")}
        />
      </FormControl>

      <FormControl sx={{ width: { xs: "100%", sm: "100%", xl: "100%" } }}>
        <TextField
          required
          label={t("admin-plans.title.noOfBooking")}
          value={values.noOfBooking}
          onChange={handleChange("noOfBooking")}
        />
      </FormControl>

      <FormControl sx={{ width: { xs: "100%", sm: "100%", xl: "100%" } }}>
        <TextField
          required
          label={t("admin-plans.title.noOfHours")}
          value={values.noOfHours}
          onChange={handleChange("noOfHours")}
        />
      </FormControl>

      <FormControl sx={{ width: { xs: "100%", sm: "100%", xl: "100%" } }}>
        <Autocomplete
          style={{ width: "97%" }}
          multiple
          id="tags-outlined"
          options={skills}
          value={values.skills ? values.skills : []}
          freeSolo
          autoSelect
          onChange={(e, newValues) => {
            const val = e.target.value;
            console.log(newValues, val);

            if (e.target.value) {
              let { skills: cats } = values;

              const index = cats.findIndex(
                (ii) => ii.trim().toLowerCase() === val.trim().toLowerCase(),
              );
              if (index === -1) {
                cats = [...cats, val.trim().toLowerCase()];
                setValues({ ...values, skills: cats });
              }
            } else {
              setValues({ ...values, skills: newValues });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("common.skills")}
              placeholder={t("common.skills")}
              value={values.skills}
            />
          )}
        />
      </FormControl>

      {/*<FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}, mt: 2}}>
                <InputLabel id="subscriptionPlan">{t("admin-plans.title.selectPlan")}</InputLabel>
                <Select
                    required
                    labelId="category"
                    id="category"
                    value={values.category}
                    label={t("admin-plans.title.selectPlan")}
                    onChange={handleChange('category')}
                    sx={{ml: 1, mr: 1}}
                >
                    <MenuItem value={"packs"}>{t("admin-plans.title.withoutSubscription")}</MenuItem>
                    <MenuItem value={"subscription"}>{t("admin-plans.title.subscription")}</MenuItem>
                </Select>
            </FormControl>*/}

      <FormControl
        sx={{
          width: { xs: "100%", sm: "100%", xl: "100%" },
          mt: 2,
          display: "flex",
          alignItems: "start",
          ml: 1,
        }}
      >
        <Formik initialValues={values} onSubmit={() => {}}>
          {!values.newImage && values.images?.length > 0 ? (
            <DDImageField
              name={"profile_pic"}
              alt={"user profile pic"}
              onFileSelection={async (file) => {
                let b = await fetch(file).then((r) => r.blob());
                if (b.size > 1000000) {
                  setErrMsg(t("errMsg.sizeErr"));
                  setTimeout(() => {
                    setErrMsg("");
                  }, 3000);
                } else {
                  setValues({ ...values, newImage: file });
                }
              }}
              sx={{ width: 60, height: 60, margin: "0 auto 24px" }}
              path={values.images[0]}
              alt={values.firstName + " " + values.lastName}
            />
          ) : (
            <JumboAvatarField
              name={"profile_pic"}
              alt={"user profile pic"}
              src={values.newImage}
              onFileSelection={async (file) => {
                let b = await fetch(file).then((r) => r.blob());
                if (b.size > 1000000) {
                  setErrMsg(t("errMsg.sizeErr"));
                  setTimeout(() => {
                    setErrMsg("");
                  }, 3000);
                } else {
                  setValues({ ...values, newImage: file });
                }
              }}
              sx={{ width: 60, height: 60, margin: "0 auto 24px" }}
            />
          )}
        </Formik>
      </FormControl>

      <FormControlLabel
        label={t("admin-plans.title.active")}
        labelPlacement="top"
        sx={{
          display: "flex",
          alignItems: "start",
          width: { xs: "100%", sm: "100%", xl: "100%" },
          ml: 1,
          mt: 2,
        }}
        control={
          <Switch
            edge="end"
            checked={values.active}
            onChange={(event) => {
              setValues({ ...values, active: event.target.checked });
            }}
            inputProps={{
              "aria-labelledby": "switch-list-label-bluetooth",
            }}
          />
        }
      />

      <FormControlLabel
        label={t("admin-plans.title.visible")}
        labelPlacement="top"
        sx={{
          display: values.category === "packs" ? "flex" : "none",
          alignItems: "start",
          width: { xs: "100%", sm: "100%", xl: "100%" },
          ml: 1,
          mt: 2,
        }}
        control={
          <Switch
            edge="end"
            checked={values.visible}
            onChange={(event) => {
              setValues({ ...values, visible: event.target.checked });
            }}
            inputProps={{
              "aria-labelledby": "switch-list-label-bluetooth",
            }}
          />
        }
      />

      <Stack sx={{ width: "100%" }} spacing={2}>
        {errMsg && <Alert severity="error">{errMsg}</Alert>}
      </Stack>

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button onClick={hideDialog} sx={{ mr: 1 }}>
          {t("common.cancelBtn")}
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <LoadingButton
          loading={isLoading}
          variant={"variant"}
          onClick={submitStep1}
        >
          {t("common.nextBtn")}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Step1;
