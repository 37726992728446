import React from 'react';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CardHeader from "@mui/material/CardHeader";
import {alpha, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import Divider from "@mui/material/Divider";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import List from "@mui/material/List";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Stack from "@mui/material/Stack";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import Div from "@jumbo/shared/Div";
import DDImage from "../../../../component/DDImage";
import EscalatorWarningOutlinedIcon from '@mui/icons-material/EscalatorWarningOutlined';
import {useTranslation} from "react-i18next";


const StudentDetail = ({student, onClose}) => {
    const {t} = useTranslation();
    return (

        <Div sx={{m: theme => theme.spacing(-2.5, -3)}}>
            <CardHeader
                title={`${student?.firstName} ${student?.lastName}`}
                subheader={student?.job_title}
                avatar={student.images?.length > 0 ? (
                        <DDImage path={student.images[0]} alt={student.firstName + ' ' + student.lastName}/>) :
                    (<Avatar alt={student.firstName + ' ' + student.lastName}/>)
                }
                action={
                    <IconButton onClick={onClose}><CloseIcon/></IconButton>
                }
            />
            <List disablePadding>
                <ListItem sx={{px: 4}}>
                    {/*<ListItemAvatar sx={{minWidth: 66}}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <EscalatorWarningOutlinedIcon sx={{color: 'primary.light'}}/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>{t("admin-student.title.parent")}</Typography>}
                        secondary={<Typography variant={"h5"} mb={0}>{student?.firstName +' '+student?.lastName}</Typography>}
                    />*/}
                </ListItem>
                <Divider component={"li"}/>
                <ListItem sx={{px: 4}}>
                    <ListItemAvatar sx={{minWidth: 66}}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <MailOutlineIcon sx={{color: 'primary.light'}}/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>{t("common.email")}</Typography>}
                        secondary={<Typography variant={"h5"} mb={0}>{student?.email}</Typography>}
                    />
                </ListItem>
                <Divider component={"li"}/>
                <ListItem sx={{px: 4}}>
                    <ListItemAvatar sx={{minWidth: 66}}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <LocalPhoneIcon sx={{color: 'primary.light'}}/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>{t("admin-student.title.phone")}</Typography>}
                        secondary={<Typography variant={"h5"} mb={0}>{student?.phone?.fullNumber}</Typography>}
                    />
                </ListItem>
                <Divider component={"li"}/>
            </List>
            <Stack spacing={1} direction={"row"} sx={{px: 4, py: 2}}>
                <JumboChipsGroup
                    chips={[{label: "Student"}]}
                    mapKeys={{label: "name"}}
                    spacing={1}
                    size={"small"}
                    defaultColor={"#146ae4"}
                />
                {/*<IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: theme => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <ForumOutlinedIcon fontSize={'medium'} color={"inherit"}/>
                </IconButton>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: theme => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <LocalPhoneIcon fontSize={'medium'} color={"inherit"}/>
                </IconButton>*/}
            </Stack>
        </Div>
    );
};

export default StudentDetail;
