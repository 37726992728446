import Div from "@jumbo/shared/Div";
import React from "react";
import {Alert, Button, FormControl, Rating, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTranslation} from "react-i18next";
import {useAppMutation} from "../../../../services";
import teacherServices from "../../../../services/teacher-services";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const Step1 = ({booking, handleNext, hide, setBooking}) => {

    const [ratings, setRatings] = React.useState([
        {key: 1, value: 5},
        {key: 2, value: 5},
        {key: 3, value: 5},
        {key: 4, value: 5},
        {key: 5, value: 5},
        {key: 6, value: 5}
    ]);
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();

    const {
        mutate,
        isSuccess,
        isError,
        isLoading,
        data
    } = useAppMutation(teacherServices.saveBookingFeedBack);


    React.useEffect(() => {
        console.log('booking---------------', booking);
        if (booking.teacherFeedback?.ratings?.length > 0) {
            setRatings(booking.teacherFeedback.ratings)
        }
    }, [booking])


    React.useEffect(() => {

        if (isSuccess) {
            handleNext({step: 4})
        }
        if (isError) {
            setErrMsg(t('errMsg.saving'));
        }

    }, [isError, isSuccess])


    const handleSave = () => {

        const b = {...booking};
        b.teacherFeedback = b.teacherFeedback ? b.teacherFeedback : {};
        b.teacherFeedback = {...b.teacherFeedback, ratings}
        mutate({bookingId: b.bookingId, teacherFeedback: b.teacherFeedback})

    };


    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Div sx={{mt: 3}}>
            <FormControl noValidate autoComplete="off" fullWidth>
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 2,
                            mt: 0.5
                        },
                    }}
                >

                    <FormControl fullWidth sx={{mt: 0.5,}}>

                        <Div fullWidth sx={{'& > legend': {mt: 2}}}
                        > {ratings && ratings.map((item, index) => {
                            return <><Typography component="legend">{t('feedback.ratingLabel' + item.key)}</Typography>
                                <Rating
                                    name="simple-controlled"
                                    value={item.value}
                                    onChange={(event, newValue) => {

                                        if (newValue) {
                                            item.value = newValue;
                                            setRatings([...ratings]);
                                        }
                                        // alert(newValue)

                                    }}
                                /></>
                        })}
                        </Div>


                    </FormControl>

                    <Stack
                        sx={{
                            mb: 2,
                            width: '90%',
                            mx: 'auto',
                            display: 'block',
                            position: 'relative', // Add position relative to enable absolute positioning of loader
                        }}
                        spacing={2}>
                        {errMsg && <Alert severity="error">{errMsg}</Alert>}
                    </Stack>

                    <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                        <Button
                            onClick={() => {
                                hide()
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.closeBtn")}
                        </Button>
                        <Button
                            onClick={() => {


                                handleNext({step: 2})


                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.backBtn")}
                        </Button>
                        <Div sx={{flex: '1 1 auto'}}/>

                        <LoadingButton
                            variant="variant"
                            loading={isLoading}
                            onClick={handleSave}
                        >
                            {t("common.finishBtn")}
                        </LoadingButton>
                    </Div>
                </Div>
            </FormControl>
        </Div>
    </Div>;

}

export default Step1;
