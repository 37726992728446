import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import authServices from "../services/auth-services";

const user = authServices.getAuthUser();

if (process.env.NODE_ENV === "production") {
  const l = window.location.href;

  if (user) {
    if (authServices.isTeacher()) {
      i18n.use(Backend).use(initReactI18next).init({
        fallbackLng: "en",
        debug: false,
      });
    } else {
      i18n.use(Backend).use(initReactI18next).init({
        fallbackLng: "fr",
        debug: false,
      });
    }
  } else {
    if (l.includes("/teacher/")) {
      i18n.use(Backend).use(initReactI18next).init({
        fallbackLng: "en",
        debug: false,
      });
    } else {
      i18n.use(Backend).use(initReactI18next).init({
        fallbackLng: "fr",
        debug: false,
      });
    }
  }
} else {
  i18n.use(Backend).use(initReactI18next).init({
    fallbackLng: "en",
    debug: false,
  });
}

export default i18n;
