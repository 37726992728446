import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {Alert, Box, FormControl, InputLabel, MenuItem, TextField} from "@mui/material";
import Select from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import {useAppMutation} from "../../../services";
import Stack from "@mui/material/Stack";
import parentServices from "../../../services/parent-services";
import studentServices from "../../../services/student-services";
import {useTranslation} from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});


const Step1 = ({stepsData, handleNext}) => {

    const [values, setValues] = React.useState({firstName: '', lastName: '', title: '', dateOfBirth: ''});
    const {mutate, error, isError, data, isLoading, isSuccess} = useAppMutation(studentServices.completeStep1);
    const [errMsg, setErrMsg] = React.useState('');
    const [errTitle, setErrTitle] = React.useState(false);
    const [errFirstName, setErrFirstName] = React.useState(false);
    const [errLastName, setErrLastName] = React.useState(false);
    const [errPhone, setErrPhone] = React.useState(false);
    const [errDateOfBirth, setDateofBirth] = React.useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (stepsData) {
            const obj = {...values, ...stepsData}
            setValues(obj);
        }
    }, [stepsData])


    useEffect(() => {

        if (isSuccess) {
            handleNext(2);
        } else if (error && isError) {
            setErrMsg(error.message || error);
        }


    }, [isSuccess, isError])


    const getMaxDate = () => {
        return new Date().toISOString().split('T')[0];
    }


    const handleChange = (prop) => (event) => {
        console.log([prop], event.target.value)
        setValues({...values, [prop]: event.target.value});
    };


    const submitStep1 = async () => {
        setErrMsg('');

        const {firstName, lastName, dateOfBirth, phone, gender} = values;


        console.log(values)
        if (firstName && lastName && dateOfBirth && gender && phone) {
            const obj = {
                firstName, lastName, dateOfBirth, gender, phone
            };
            mutate(obj)
        } else {
            setErrMsg(t('errMsg.notFill'));
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }
    };


    const [phoneDetails, setPhoneDetails] = React.useState({
        countryCode: '',
        localNumber: ''
    });

    const handleChangePhone = (phone, country) => {

        console.log(country, phone);
        const dialCode = country.dialCode;
        const newPhone = {
            code: dialCode,
            fullNumber: phone,
            number: phone.slice(dialCode.length)
        }
        /*const countryCode = country.dialCode; // Get the country code
        const localNumber = phone.slice(countryCode.length); // Extract the local number by removing the country code
        const formattedPhone = `+${countryCode}${phone.replace(countryCode, '')}`;
        const label = country.name;

        console.log('Country Code:', countryCode);
        console.log('Local Number:', localNumber);
        console.log('Full Number:', phone);
        console.log('Formatted Phone Number:', formattedPhone);
        console.log('label:', label);

        setPhoneDetails({countryCode, localNumber});*/
        setValues({...values, phone: newPhone});
    };


    return (

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, mt: 5},
            }}
            noValidate
            autoComplete="off"
        >
            <Div>

                <Div sx={{minWidth: 120}}>

                    <FormControl sx={{
                        mt: 4,
                        ml: 1,
                        //width: "21%"
                        width: 150,

                        maxWidth: '100%',

                    }}>
                        <InputLabel error={errTitle}
                                    id="demo-simple-select-label">{t("student.title.profileStepGender")}</InputLabel>
                        <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.gender}
                            onChange={handleChange('gender')}
                            label={t("student.title.profileStepGender")}
                            error={errTitle}
                        >
                            <MenuItem value="">{t('common.select')}</MenuItem>
                            <MenuItem value="male">{t('common.male')}</MenuItem>
                            <MenuItem value="female">{t('common.female')}</MenuItem>
                        </Select>
                    </FormControl>
                </Div>


                <Div sx={{
                    mt: -2,
                }}>
                    <FormControl sx={{
                        width: 500,

                        maxWidth: '100%',

                    }}>
                        <TextField
                            required
                            error={errFirstName}
                            label={t("common.firstName")}
                            value={values.firstName}
                            onChange={handleChange('firstName')}

                        />
                    </FormControl>
                    <FormControl sx={{
                        width: 500,
                        maxWidth: '100%',

                    }}>
                        <TextField
                            required
                            error={errLastName}
                            label={t("common.lastName")}
                            value={values.lastName}
                            onChange={handleChange('lastName')}
                        />
                    </FormControl>
                    <FormControl sx={{
                        width: 500,

                        maxWidth: '100%',

                    }}>

                        <TextField
                            id="date"
                            error={errDateOfBirth}
                            label={(t('common.dateBirth'))}
                            type="date"
                            value={values.dateOfBirth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                max: getMaxDate()
                            }}
                            onChange={handleChange('dateOfBirth')}
                        />
                    </FormControl>
                </Div>


                <Div sx={{
                    mt: 2,
                    ml: 1
                }}>
                    <FormControl sx={{
                        width: 500,

                        maxWidth: '100%',

                    }}>
                        <PhoneInput
                            country={'fr'} // Default country code
                            value={values.phone?.fullNumber}
                            onChange={handleChangePhone}
                            inputStyle={{width: '97%'}} // Ensures full width styling
                            required
                        />
                    </FormControl>


                </Div>

                {/*<Div sx={{mt: 1, mb: 2, display: 'flex', alignItems: 'center'}}>
                    <FormControl variant="outlined" sx={{flex: '0 0 130px', marginRight: 2}}>
                        {values.phone?.code && <Autocomplete
                            defaultValue={values.phone}
                            options={PHONE_COUNTRIES}
                            autoHighlight
                            onChange={handleCountryChange}
                            getOptionLabel={(option) => {
                                return ' +' + option.code;
                            }}
                            disableClearable={true}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.countryCode?.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.countryCode?.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                    {option.countryCode}(+{option.code})
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('common.code')}
                                    error={errPhone}
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <React.Fragment>
                                                {values.phone && values.phone.countryCode && (
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={`https://flagcdn.com/w20/${values.phone.countryCode?.toLowerCase()}.png`}
                                                        srcSet={`https://flagcdn.com/w40/${values.phone.countryCode?.toLowerCase()}.png 2x`}
                                                        alt=""
                                                    />
                                                )}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />}
                    </FormControl>

                    <FormControl variant="outlined" sx={{ flex: 1 }}>
                        <TextField
                            required
                            id="phone"
                            label={t('common.phone')}
                            error={errPhone}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={values.phone?.number}
                            onChange={(event) => {
                                let inputNumber = event.target.value;

                                // Remove leading zeros
                                const sanitizedNumber = inputNumber.replace(/\D/g, '').replace(/^0+/, '');

                                let phone = values.phone;
                                phone.number = sanitizedNumber;

                                // Get selected country code
                                const selectedCountryCode = phone.code;

                                // Find the formatting rule for the selected country code
                                const countryFormattingRule = countryFormattingRules.find(rule => rule.code === selectedCountryCode);

                                // Enforce maximum digits restriction based on country code
                                if (countryFormattingRule) {
                                    const maxDigits = countryFormattingRule.maxDigits;

                                    // If the input length exceeds the maximum allowed digits, truncate the input
                                    if (phone.number.length > maxDigits) {
                                        phone.number = phone.number.slice(0, maxDigits);
                                    }

                                    // If the input length equals the maximum allowed digits, prevent further input
                                    if (phone.number.length === maxDigits) {
                                        event.preventDefault();
                                    }
                                }

                                setValues({ ...values, phone });
                            }}
                        />
                    </FormControl>






                </Div>*/}


            </Div>
            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                <Div sx={{flex: '1 1 auto'}}/>

                <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep1}>
                    {t("common.nextBtn")}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step1;
