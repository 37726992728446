import React, { useEffect } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import styled from "@emotion/styled";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Span from "@jumbo/shared/Span";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ContactDetail from "./ContactDetail";
import JumboGridItem from "@jumbo/components/JumboList/components/JumboGridItem";
import { useAppMutation } from "../../../services";
import adminServices from "../../../services/admin-services";
import DDImage from "../../../component/DDImage";
import { useTransactionsContext } from "./context";
import { useTranslation } from "react-i18next";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import BadgeIcon from "@mui/icons-material/Badge";
import EmailIcon from "@mui/icons-material/Email";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Chip from "@mui/material/Chip";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import DoNotDisturbOffIcon from "@mui/icons-material/DoNotDisturbOff";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const ProgramItem = ({ program, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const { globalState, setGlobalState } = useTransactionsContext();
  const { t } = useTranslation();
  const {
    mutate: setCourseRequestStatus,
    isError: isCRSError,
    isSuccess: isCRSSuccess,
    isLoading: isCRSLoading,
  } = useAppMutation(adminServices.setCourseRequestStatus);

  const {
    mutate: setCourseRequestApproved,
    isError: isCRAError,
    isSuccess: isCRASuccess,
    isLoading: isSCRALoading,
  } = useAppMutation(adminServices.setCourseRequestApproved);

  useEffect(() => {
    if (isCRSSuccess || isCRASuccess) {
      setGlobalState({ ...globalState, refreshTransactionsList: true });
    }

    if (isCRASuccess) {
      hideDialog(false);
    }
  }, [isCRSError, isCRSSuccess, isCRASuccess]);

  const showContactDetail = React.useCallback(() => {
    showDialog({
      content: <ContactDetail program={program} onClose={hideDialog} />,
    });
  }, [showDialog, program]);

  const handleItemAction = (menuItem, program) => {
    console.log("menuItem", menuItem, program);
    switch (menuItem.action) {
      case "approve":
        showDialog({
          variant: "confirm",
          title: t("admin-transactions.title.approvedTitle"),
          content: t("admin-transactions.title.approvedContent"),
          onYes: () => {
            setCourseRequestApproved({
              transactionId: program.transactionId,
              approved: true,
            });
          },
          onNo: hideDialog,
        });

        break;

      case "cancel":
        showDialog({
          variant: "confirm",
          title: t("admin-transactions.title.cancelTitle"),
          content: t("admin-transactions.title.cancelContent"),
          onYes: () => {
            setCourseRequestApproved({
              transactionId: program.transactionId,
              approved: false,
            });
          },
          onNo: hideDialog,
        });
    }
  };

  const handleEditHorizClick = () => {
    handleItemAction({ action: "edit" }); // This will open the menu or perform the default action.
  };

  const handleDeleteHorizClick = () => {
    handleItemAction({ action: "delete" }); // This will open the menu or perform the default action.
  };

  const handleRequestStatus = (obj) => {
    setCourseRequestStatus(obj);
  };

  if (view === "grid") {
    return (
      <JumboGridItem xs={12} lg={6}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            avatar={
              program.course.images?.length > 0 ? (
                <DDImage path={program.course.images[0]} alt={program.name} />
              ) : (
                <Avatar alt={program?.title}>
                  <InsertDriveFileIcon />
                </Avatar>
              )
            }
            title={
              <Typography variant={"h6"} color={"text.primary"} mb={0.25}>
                {program.course?.name}
              </Typography>
            }
            subheader={
              <Typography variant={"body1"} color={"text.secondary"}>
                {program.designation}
              </Typography>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              <ListItem sx={{ p: (theme) => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <ListItemAvatar
                    sx={{ display: { xs: "none", sm: "block" } }}
                    onClick={showContactDetail}
                  >
                    {program.student?.images?.length > 0 ? (
                      <DDImage
                        path={program.student?.images[0]}
                        alt={program.title}
                      />
                    ) : (
                      <Avatar alt={program?.title}>
                        <PermIdentityIcon />
                      </Avatar>
                    )}
                  </ListItemAvatar>
                </ListItemIcon>
                <ListItemText
                  primary={t("admin-transactions.title.studentName")}
                  secondary={`${program.student?.firstName} ${program.student?.lastName}`}
                />
              </ListItem>
              <ListItem sx={{ px: 1.5 }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t("admin-transactions.title.email")}
                  secondary={program.student?.email}
                />
              </ListItem>
            </List>
            <Divider sx={{ my: 2 }} />

            <Stack direction={"row"} alignItems={"center"} sx={{ minWidth: 0 }}>
              <Item>
                {program.paid ? (
                  <JumboChipsGroup
                    chips={[{ label: "Paid" }]}
                    mapKeys={{ label: "name" }}
                    spacing={1}
                    size="small"
                    defaultColor="#146ae4"
                  />
                ) : (
                  <JumboChipsGroup
                    chips={[{ label: "Requested" }]}
                    mapKeys={{ label: "name" }}
                    spacing={1}
                    size="small"
                    defaultColor="#000"
                  />
                )}
              </Item>
            </Stack>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <React.Fragment>
      <JumboListItem
        componentElement={"div"}
        itemData={program}
        /*secondaryAction={
                    <JumboDdMenu
                        icon={<MoreHorizIcon/>}
                        menuItems={[
                            {icon: <TaskAltIcon/>, title: t("admin-transactions.title.approved"), action: "approve"},
                            {icon: <DoNotDisturbOffIcon/>, title: t("admin-transactions.title.cancel"), action: "cancel"}
                        ]}
                        onClickCallback={(menu) => handleItemAction(menu, program)}
                    />
                }*/
        sx={{
          cursor: "pointer",
          borderTop: 1,
          borderColor: "divider",
          "&:hover": {
            bgcolor: "action.hover",
          },
        }}
      >
        <ListItemAvatar onClick={showContactDetail}>
          {program.course?.images?.length > 0 ? (
            <DDImage path={program.course?.images[0]} alt={program.title} />
          ) : (
            <Avatar alt={program?.title}>
              <InsertDriveFileIcon />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          onClick={showContactDetail}
          primary={
            <Typography variant={"body1"} component={"div"}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{ minWidth: 0 }}
              >
                <Item
                  sx={{
                    flexBasis: { xs: "100%", sm: "50%", md: "35%" },
                  }}
                >
                  <Typography
                    variant={"h5"}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap
                  >
                    {program.course?.name}
                  </Typography>
                  <Typography
                    variant={"body1"}
                    noWrap
                    color={"text.secondary"}
                    sx={{
                      display: { sm: "none" },
                    }}
                  >
                    {program.email}
                  </Typography>
                </Item>

                <ListItemAvatar
                  sx={{ display: { xs: "none", sm: "block" } }}
                  onClick={showContactDetail}
                >
                  {program.student?.images?.length > 0 ? (
                    <DDImage
                      path={program.student?.images[0]}
                      alt={program.title}
                    />
                  ) : (
                    <Avatar alt={program?.title}>
                      <PermIdentityIcon />
                    </Avatar>
                  )}
                </ListItemAvatar>

                {/*<Item
                                    sx={{
                                        flexBasis: {sm: '6%', md: '6%'},
                                        display: {xs: 'none', sm: 'block'}
                                    }}
                                >
                                    <ListItemAvatar onClick={showContactDetail}>
                                        {program.student?.images?.length > 0 ? (
                                                <DDImage path={program.student?.images[0]} alt={program.title}/>) :
                                            (<Avatar alt={program?.title}>
                                                <BadgeIcon/>
                                            </Avatar>)}
                                    </ListItemAvatar>
                                </Item>*/}
                <Item
                  sx={{
                    flexBasis: { sm: "50%", md: "28%" },
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  <Typography variant={"body1"} noWrap>
                    {t("admin-transactions.title.studentName")}
                  </Typography>
                  <Typography variant={"body1"} noWrap>
                    {program?.student?.firstName} {program?.student?.lastName}
                  </Typography>
                </Item>
                <Item
                  sx={{
                    flexBasis: { md: "40%" },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <Typography variant={"body1"} noWrap>
                    {t("admin-transactions.title.email")}
                  </Typography>
                  <Typography variant={"body1"} noWrap>
                    {program.student?.email}
                  </Typography>
                </Item>

                <Item
                  sx={{
                    flexBasis: { md: "30%" },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <Typography variant={"body1"} noWrap>
                    {t("admin-transactions.title.amount")}
                  </Typography>
                  <Typography variant={"body1"} noWrap>
                    {program.amount}
                  </Typography>
                </Item>

                <Item
                  sx={{
                    flexBasis: { md: "22%" },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  {program.paid ? (
                    <JumboChipsGroup
                      chips={[{ label: "Paid" }]}
                      mapKeys={{ label: "name" }}
                      spacing={1}
                      size="small"
                      defaultColor="#146ae4"
                      sx={{ width: "65%" }}
                    />
                  ) : (
                    <JumboChipsGroup
                      chips={[{ label: "Requested" }]}
                      mapKeys={{ label: "name" }}
                      spacing={1}
                      size="small"
                      defaultColor="#000"
                    />
                  )}
                </Item>

                {/*<Item
                                    sx={{
                                        flexBasis: {md: '20%'},
                                        display: {xs: 'none', md: 'block'},
                                        textAlign: 'center'
                                    }}
                                >
                                    {program.status === 'new' &&
                                        <Chip
                                           color={"error"}
                                           size={"small"}
                                           label={"Status"}
                                           onClick={() => {
                                               handleRequestStatus({
                                                   transactionId: program.transactionId,
                                                   status: 'in-processing'
                                               })
                                           }}
                                        />
                                    }
                                    {program.status === 'in-processing' &&
                                        <Chip
                                           color={"warning"}
                                           size={"small"}
                                           label={"In Processing"}
                                        />
                                    }
                                    {program.status === 'completed' &&
                                        <Chip
                                           color={"success"}
                                           size={"small"}
                                           label={"Completed"}
                                        />
                                    }
                                </Item>*/}

                {/*<Item
                                    sx={{
                                        flexBasis: {md: '22%'},
                                        display: {xs: 'none', md: 'block'}
                                    }}
                                >
                                    <JumboChipsGroup
                                        chips={program.labels}
                                        mapKeys={{label: "name"}}
                                        spacing={1}
                                        size={"small"}
                                        max={1}
                                    />
                                </Item>*/}
              </Stack>
            </Typography>
          }
        />
      </JumboListItem>
    </React.Fragment>
  );
};
/* Todo program, view prop define */
export default ProgramItem;
