import React, { useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Select from "@mui/material/Select";
import { useAppMutation } from "../../../services";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import teacherServices from "../../../services/teacher-services";
import FileBase64 from "react-file-base64";
import DDFile from "../../../component/DDFile";

const Step3 = ({ stepsData, handleNext }) => {
  const [values, setValues] = React.useState({
    teachingSpecialities: [],
    ageGroupPreferences: [],
    teachingDiploma: "",
  });

  const { mutate, error, isError, data, isLoading, isSuccess } = useAppMutation(
    teacherServices.completeStep3,
  );
  const [errMsg, setErrMsg] = React.useState("");
  const { t } = useTranslation();
  const [value, setValue] = React.useState();

  useEffect(() => {
    console.log(isSuccess, isError);
    if (stepsData) {
      setValues({ ...values, ...stepsData });
    }
  }, [stepsData]);

  useEffect(() => {
    if (error && isError) {
      setErrMsg(error.message);
    } else if (isSuccess) {
      handleNext(4);
    }
  }, [isError, isSuccess]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const submitStep3 = async () => {
    setErrMsg("");
    const {
      teachingSpecialities,
      ageGroupPreferences,
      teachingDiploma,
      newTeachingDiplomaFile,
      base64,
    } = values;

    if (
      teachingSpecialities.length > 0 &&
      ageGroupPreferences.length > 0 &&
      teachingDiploma
    ) {
      mutate({
        teachingSpecialities,
        ageGroupPreferences,
        base64,
        teachingDiploma,
      });
    } else {
      setErrMsg("Fields not filled.");
    }
  };

  const backToStep2 = async () => {
    handleNext(2);
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {},
      }}
      noValidate
      autoComplete="off"
    >
      <Div sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
        <FormControl
          sx={{
            width: "50%",
            mt: 1,
          }}
        >
          <TextField
            id="outlined-multiline-static"
            label={t("teacherForm.diploma")}
            value={values.teachingDiploma}
            name="comments"
            onChange={handleChange("teachingDiploma")}
          />
        </FormControl>

        <FormControl
          sx={{
            width: "50%",
            mt: 1,
          }}
        >
          <Typography variant={"h4"}>{t("teacherForm.diploma")}</Typography>
          <FileBase64
            multiple={false}
            max
            onDone={(file) => {
              if (file.file.size > 1000000) {
                setErrMsg(t("common.sizeErr"));
                setTimeout(() => {
                  setErrMsg("");
                }, 3000);
                setValues({ ...values, base64: undefined });
              } else {
                setValues({ ...values, base64: file.base64 });
              }
            }}
          />

          {values.base64 && <DDFile base64={values.base64} />}
          {!values.base64 && values?.teachingDiplomaFiles?.length > 0 && (
            <DDFile path={values?.teachingDiplomaFiles[0]} />
          )}
        </FormControl>

        <FormControl
          sx={{
            width: "50%",
            mt: 1,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            {t("teacherForm.speciality")}
          </InputLabel>
          <Select
            multiple
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.teachingSpecialities}
            onChange={handleChange("teachingSpecialities")}
            label={t("teacherForm.speciality")}
          >
            <MenuItem value="conversation">Conversation practice</MenuItem>
            <MenuItem value="vocabulary">Vocabulary building</MenuItem>
            <MenuItem value="writing">Writing skills</MenuItem>
            <MenuItem value="reading">Reading comprehension</MenuItem>
            <MenuItem value="listening">Listening comprehension</MenuItem>
            <MenuItem value="business">Business English</MenuItem>
            <MenuItem value="academic">Academic English</MenuItem>
            <MenuItem value="purpose">
              English for specific purposes (e.g., tourism, hospitality,
              healthcare)
            </MenuItem>
            <MenuItem value="awareness">
              Cultural awareness and intercultural communication
            </MenuItem>
            <MenuItem value="exam">
              Exam preparation (e.g., TOEFL, IELTS, Cambridge exams)
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: "50%",
            mt: 1,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            {t("teacherForm.ageGroup")}
          </InputLabel>
          <Select
            multiple
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.ageGroupPreferences}
            onChange={handleChange("ageGroupPreferences")}
            label={t("teacherForm.ageGroup")}
          >
            <MenuItem value="3-5-years-old">3-5 years old</MenuItem>
            <MenuItem value="6-8-years-old">6-8 years old</MenuItem>
            <MenuItem value="9-11-years-old">9-11 years old</MenuItem>
            <MenuItem value="12-15-years-old">12-15 years old</MenuItem>
            <MenuItem value="16-18-years-old">16-18 years old</MenuItem>
            <MenuItem value="18-plus-years-old">+ 18ans</MenuItem>
          </Select>
        </FormControl>
      </Div>

      <Stack sx={{ width: "100%" }} spacing={2}>
        {errMsg && <Alert severity="error">{errMsg}</Alert>}
      </Stack>
      <Div sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button onClick={backToStep2} sx={{ mr: 1 }}>
          {t("common.backBtn")}
        </Button>
        <Div sx={{ flex: "1 1 auto" }} />
        <Button variant={"contained"} onClick={submitStep3}>
          {t("common.nextBtn")}
        </Button>
      </Div>
    </Box>
  );
};

export default Step3;
