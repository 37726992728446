import React from 'react';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CardHeader from "@mui/material/CardHeader";
import {alpha, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import TagIcon from '@mui/icons-material/Tag';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Divider from "@mui/material/Divider";
import PaymentsIcon from '@mui/icons-material/Payments';
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import List from "@mui/material/List";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Stack from "@mui/material/Stack";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import { CheckCircle, Cancel } from '@mui/icons-material';
import QuizIcon from '@mui/icons-material/Quiz';
import Div from "@jumbo/shared/Div";
import {useTranslation} from "react-i18next";
import DDImage from "../../../../component/DDImage";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ViewStreamIcon from '@mui/icons-material/ViewStream';

const formatQuestionsList = (questions) => (
    <List>
        {questions.map((q, index) => (
            <React.Fragment key={index}>
                <ListItem>
                    <Typography variant="h6" component="div">
                        {`${index + 1}. ${q.questionText}`}
                    </Typography>
                </ListItem>
                <ListItem>
                    <List>
                        {q.choices.map((choice, i) => (
                            <ListItem key={i}>
                                <Typography variant="body2" style={{ display: 'flex', alignItems: 'center',color: 'black' }}>
                                    {`${String.fromCharCode(65 + i)}. ${choice.choiceText}`}
                                    {choice.isCorrect && (
                                        <CheckCircleIcon sx={{ color: 'green', marginLeft: 1,fontSize: 18 }} />
                                    )}
                                </Typography>
                            </ListItem>
                        ))}
                    </List>
                </ListItem>
            </React.Fragment>
        ))}
    </List>
);

const ContactDetail = ({program, onClose}) => {
    console.log("P----------",program);
    const {t} = useTranslation();

    const questionsList = program?.questions ? formatQuestionsList(program.questions) : null;

    return (
        <Div sx={{m: theme => theme.spacing(-2.5, -3),mb:2}}>
            <CardHeader
                title={program?.name}
                //subheader={program?.name}
                avatar={program?.images?.length > 0 ? (
                        <DDImage path={program.images[0]} alt={program?.name}/>) :
                    (<Avatar alt={program?.name}>
                        <QuizIcon />
                    </Avatar>)
                }
                action={
                    <IconButton onClick={onClose}><CloseIcon/></IconButton>
                }
            />
            <List disablePadding>
                <ListItem sx={{px: 4}}>
                    <ListItemAvatar sx={{minWidth: 66}}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <ViewStreamIcon sx={{color: 'primary.light'}}/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>{t("admin-quiz.title.quizTitle")}</Typography>}
                        secondary={<Typography variant={"h5"} mb={0}>{program?.title}</Typography>}
                    />
                </ListItem>
                <Divider component={"li"}/>
                <ListItem sx={{px: 4}}>
                    <ListItemAvatar sx={{minWidth: 66}}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <AccessTimeIcon sx={{color: 'primary.light'}}/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>{t("admin-quiz.title.duration")}</Typography>}
                        secondary={<Typography variant={"h5"} mb={0}>{program?.duration}</Typography>}
                    />
                </ListItem>
                <Divider component={"li"}/>
                <ListItem sx={{px: 4}}>
                    <ListItemAvatar sx={{minWidth: 66}}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            {program?.active ? (
                                <CheckCircle sx={{ color: 'green' }} />
                            ) : (
                                <Cancel sx={{ color: 'red' }} />
                            )}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography variant={"body1"} color={"text.secondary"} mb={.5}>
                                {t("admin-quiz.title.active")}
                            </Typography>
                        }
                        secondary={
                            <Typography variant={"h5"} mb={0}>
                                {program?.active ? 'Active' : 'Deactive'}
                            </Typography>
                        }
                    />
                </ListItem>
                <Divider component={"li"}/>
                <ListItem sx={{px: 4}}>

                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>{t("admin-quiz.title.description")}</Typography>}
                        secondary={<Typography
                            variant="body1"
                            mb={0.5}
                            dangerouslySetInnerHTML={{
                                __html: program.descriptionHTML,
                            }}
                        />}
                    />
                </ListItem>
                <Divider component={"li"}/>
                <ListItem sx={{ px: 4 }}>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary" mb={0.5}>{t("admin-quiz.title.questions")}</Typography>}
                        secondary={questionsList}
                    />
                </ListItem>
                <Divider component={"li"}/>

            </List>

            {/*<Stack spacing={1} direction={"row"} sx={{px: 4, py: 2}}>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: theme => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <ForumOutlinedIcon fontSize={'medium'} color={"inherit"}/>
                </IconButton>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: theme => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <LocalPhoneIcon fontSize={'medium'} color={"inherit"}/>
                </IconButton>
            </Stack>*/}
        </Div>
    );
};

export default ContactDetail;
