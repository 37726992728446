import {createContext, useContext, useState} from 'react';

const CoursesContext = createContext({});

export const useCoursesContext = () => {
    return useContext(CoursesContext);
}

export const CoursesProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({ refreshCoursesList : false});

    return <CoursesContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </CoursesContext.Provider>
}
