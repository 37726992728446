import React from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CardHeader from "@mui/material/CardHeader";
import {
  alpha,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import BadgeIcon from "@mui/icons-material/Badge";
import EmailIcon from "@mui/icons-material/Email";
import Divider from "@mui/material/Divider";
import PaymentsIcon from "@mui/icons-material/Payments";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import List from "@mui/material/List";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Chip from "@mui/material/Chip";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import Div from "@jumbo/shared/Div";
import { useTranslation } from "react-i18next";
import DDImage from "../../../../component/DDImage";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

const ContactDetail = ({ program, onClose }) => {
  console.log("P----------", program);
  const { t } = useTranslation();

  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3), mb: 2 }}>
      <CardHeader
        title={program?.title}
        subheader={
          <React.Fragment>
            {program.status === "completed" && (
              <Chip
                color="success"
                size="small"
                label={t("admin-requests.title.completed")}
                sx={{ mr: 1 }} // Add margin for spacing between Chips
              />
            )}
            {program.status === "in-processing" && (
              <Chip
                color="warning"
                size="small"
                label={t("admin-requests.title.inProcessing")}
              />
            )}
            {program.status === "new" && (
              <Chip
                color={"error"}
                size={"small"}
                label={t("admin-requests.title.newRequest")}
              />
            )}
          </React.Fragment>
        }
        avatar={
          program?.course.images?.length > 0 ? (
            <DDImage path={program.course.images[0]} alt={program?.title} />
          ) : (
            <Avatar alt={program?.title}>
              <InsertDriveFileIcon />
            </Avatar>
          )
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <List disablePadding>
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            {program.student?.images?.length > 0 ? (
              <DDImage path={program.student?.images[0]} alt={program.title} />
            ) : (
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
                }}
                alt={program?.title}
              >
                <PermIdentityIcon />
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                {t("admin-requests.title.studentName")}
              </Typography>
            }
            secondary={
              <Typography
                variant={"h5"}
                mb={0}
              >{`${program.student?.firstName} ${program.student?.lastName}`}</Typography>
            }
          />
        </ListItem>
        <Divider component={"li"} />
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
              }}
            >
              <EmailIcon sx={{ color: "primary.light" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                {t("admin-requests.title.email")}
              </Typography>
            }
            secondary={
              <Typography variant={"h5"} mb={0}>
                {program?.student?.email}
              </Typography>
            }
          />
        </ListItem>

        <Divider component={"li"} />

        <ListItem sx={{ px: 4 }}>
          <ListItemText
            primary={
              <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                {t("admin-quiz.title.description")}
              </Typography>
            }
            secondary={
              <Typography
                variant="body1"
                mb={0.5}
                dangerouslySetInnerHTML={{
                  __html: program.description,
                }}
              />
            }
          />
        </ListItem>

        <Divider component={"li"} />
      </List>

      {/*<Stack spacing={1} direction={"row"} sx={{px: 4, py: 2}}>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: theme => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <ForumOutlinedIcon fontSize={'medium'} color={"inherit"}/>
                </IconButton>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: theme => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <LocalPhoneIcon fontSize={'medium'} color={"inherit"}/>
                </IconButton>
            </Stack>*/}
    </Div>
  );
};

export default ContactDetail;
