import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Switch,
    TextField
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Step1 from "./step1";
import Step2 from "./step2";
import Step4 from "./step4";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import {useRequestsContext} from "../context";
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import DDImageField from "../../../../component/DDImageField";
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";



const Steps = ({disableSmLogin}) => {

    const [stepsData, setStepsData] = useState({});
    const [activeStep, setActiveStep] = React.useState(0);
    const {globalState, setGlobalState} = useRequestsContext();
    const [requestCourse, setRequestCourse] = useState([]);
    const {mutate: loadCourse, isError, data, isSuccess, isLoading} = useAppMutation(adminServices.loadCourse);
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const steps = [t('admin-plans.title.basicInfo'), t("common.desc"), t("admin-plans.title.planPricing")];

    useEffect(() => {

        if (globalState && globalState.courseRequestId) {
            console.log(globalState.program)
            setRequestCourse(globalState.program)
            //alert(JSON.stringify(requestCourse))
        }

    }, [globalState])


    const handleNext = (step) => {


        if (step === 5) {
            hideDialog();
        } else {
            setActiveStep(step - 1);
        }
    };

    const submitStep1 = async () => {
        setErrMsg('');


    };



    const hideDialog = () => {
        setGlobalState({...globalState, program : undefined, courseId: undefined, refreshRequestsList: true, openAddEditProgram: false});
    }


    // width: {xs: '100%', sm: '100%', xl: '50%'}

    return (
        <Div sx={{
            width: '100%',
            minHeight: 500,
            minWidth: {xs: 500, sm: 300, xl: 500},
        }}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},
            }}
            noValidate
            autoComplete="off"
        >
            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}}}>
                <TextField
                    label={t("admin-plans.title.planName")}
                    value={requestCourse?.title}
                    disabled
                />
            </FormControl>


            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}}}>
                <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    defaultValue="Default Value"
                    label={t("admin-requests.title.description")}
                    value={requestCourse?.description}
                    sx={{width:'100%'}}
                    disabled
                />
            </FormControl>

            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}}}>
                <TextField
                    label={t("admin-requests.title.studentName")}
                    value={`${requestCourse?.student?.firstName} ${requestCourse?.student?.lastName}`}
                    disabled
                />
            </FormControl>

            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}}}>
                <TextField
                    label={t("admin-requests.title.email")}
                    value={requestCourse?.student?.email}
                    disabled
                />
            </FormControl>

            <Div
                sx={{
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    mt:2
                }}
            >


                <Button
                    variant={"contained"}
                    size={"small"}
                    sx={{mr: 1, width: "50%",mt:.5}}
                    onClick={submitStep1}
                >
                    {t("common.nextBtn")}
                </Button>
                <Button
                    variant={"contained"}
                    size={"small"}
                    sx={{mr: 1, width: "50%",mt:2}}
                    onClick={hideDialog}
                >
                    {t("common.cancelBtn")}
                </Button>
            </Div>



            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>

        </Box>
        </Div>
    );

};

export default Steps;
