import React, {useState} from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import CalendarWrapper from "./CalendarWrapper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {useTranslation} from "react-i18next";
import {Alert, IconButton,} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Stack from "@mui/material/Stack";
import Snackbar from '@mui/material/Snackbar';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import {useCalendarContext} from "../context";
import {isPastDate} from "../../../../utils/constants/appActions";
import styled from "@emotion/styled";
import 'moment/locale/fr';


const localizer = momentLocalizer(moment);
const dayFormat = (date, culture, localizer) => localizer.format(date, 'DD/MM/YYYY', culture);
const dayRangeHeaderFormat =({start,end}, culture, localizer) =>  localizer.format(start, 'DD/MM/YYYY', culture) +'-'+localizer.format(end, 'DD/MM/YYYY', culture);

const   dateFormat = 'DD';
const   dayHeaderFormat = 'DD/MM/YYYY';

const StyledCircle = styled('div')(({theme, color}) => ({
    backgroundColor: color,
    width: '10px',
    height: '10px',
    borderRadius: '50%',
}));

const EventComponent = ({event, index}) => {
    console.log('event----', event)

    const formattedStartTime = new Date(event.start).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});

    if (event.bookingId) {
        return (
            <Div sx={{display: 'flex', alignItems: 'center', height: 20}}
                 key={'key-calendar-' + index}>
                <Typography sx={{fontSize: 10}} variant="caption" gutterBottom component="div">
                    {event.student.firstName}
                </Typography>
                <Typography sx={{fontSize: 10, ml: .5}} variant="caption" gutterBottom component="div">
                    {event.student.lastName}
                </Typography>
                <Typography sx={{fontSize: 10, ml: .5}} variant="caption" gutterBottom component="div">
                    {"(" + formattedStartTime + ")"}
                </Typography>
                {event.attended === 'not-attended' && <Div sx={{ml: 1}}><StyledCircle color="#D22B2B"/></Div>}
            </Div>
        );
    } else if (event.appointmentId) {
        return (
            <Div sx={{display: 'flex', alignItems: 'center', height: 20}}
                 key={'key-calendar-' + index}>
                <Typography sx={{fontSize: 10}} variant="caption" gutterBottom component="div">
                    {event.student.firstName}
                </Typography>
                <Typography sx={{fontSize: 10, ml: .5}} variant="caption" gutterBottom component="div">
                    {event.student.lastName}
                </Typography>
                <Typography sx={{fontSize: 10, ml: .5}} variant="caption" gutterBottom component="div">
                    {"(" + formattedStartTime + ")"}
                </Typography>
                {event.attended === 'not-attended' && <Div sx={{ml: 1}}><StyledCircle color="#D22B2B"/></Div>}

            </Div>
        );


    } else if (event.scheduleId) {
        return (
            <Div sx={{display: 'flex', alignItems: 'center', height: 20}}
                 key={'key-calendar-' + index}>
                <Typography sx={{fontSize: 10}} variant="caption" gutterBottom component="div">
                    {event.teacher.firstName}
                </Typography>
                <Typography sx={{fontSize: 10, ml: .5}} variant="caption" gutterBottom component="div">
                    {event.teacher.lastName}
                </Typography>
                <Typography sx={{fontSize: 10, ml: .5}} variant="caption" gutterBottom component="div">
                    {"(" + formattedStartTime + ")"}
                </Typography>

            </Div>
        );
    }
    return null
}
const AgendaEventComponent = ({event}) => {


    if (event.scheduleId) {
        return <Div>
            <Typography variant="h6" gutterBottom component="div">
                {event.teacher.firstName} {event.teacher.lastName}
            </Typography>
        </Div>
    } else {
        return <Div sx={{ display: 'flex'}}>
            <Typography variant="h6" gutterBottom component="div">
                {event.student.firstName} {event.student.lastName}
            </Typography>
            {event.attended === 'not-attended' && <Div sx={{ml: 1, mt : 0.5}}><StyledCircle color="#D22B2B"/></Div>}
        </Div>
    }

};

const CalendarBasic = () => {
    const {globalState, setGlobalState} = useCalendarContext();
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [errMsg, setErrMsg] = React.useState('');
    const [events, setEvents] = useState([]);
    const params = useParams();
    const [searchStartDate, setSearchStartDate] = useState(new Date());
    const {
        mutate: loadEvents,
        isSuccess: isLESuccess,
        data: leResult,
        isLoading: isLELoading,
        isError: isLEError
    } = useAppMutation(adminServices.loadEvents);


    React.useEffect(() => {
        if (!minDate) {
            const currentMax = new Date();
            setMinDate(new Date());
            currentMax.setDate(currentMax.getDate() + 30);
            setMaxDate(currentMax);
            // loadActiveSubscriptions()
        }
    }, [minDate])

    React.useEffect(() => {

        if (globalState.refreshList) {
            loadEvents({
                startDate: searchStartDate,
                showBookings: globalState.showBookings,
                showAppointments: globalState.showAppointments,
                showAvailabilities: globalState.showAvailabilities,
                search : globalState.search
            })
            setGlobalState({...globalState, refreshList: false})
        }

    }, [globalState])

    React.useEffect(() => {

        if (searchStartDate) {
            loadEvents({
                startDate: searchStartDate, showBookings: globalState.showBookings,
                showAppointments: globalState.showAppointments,
                showAvailabilities: globalState.showAvailabilities
            })
        }

    }, [searchStartDate]);


    React.useEffect(() => {
        if (isLESuccess) {
            console.log('Bookings----------', leResult)
            const es = [];
            for (const e of leResult) {
                console.log('Booking', e.bookingId);
                es.push({...e, start: new Date(e.startDate), end: new Date(e.endDate)})
            }
            setEvents(es);
        }
    }, [isLESuccess])


    const handleSelectSlot = (slotInfo) => {


        const selectedDate = slotInfo.start;

        // Set the time of the current date to midnight
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        // Check if the selected date is in the past
        if (false && selectedDate < currentDate) {
            setErrMsg(t("errMsg.pastDateMsg"));
            setSnackbarOpen(true);
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        const maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth() + 3);
        maxDate.setHours(0, 0, 0, 0);

        // Check if the selected date is within the next month
        if (false && selectedDate < currentDate || selectedDate >= maxDate) {
            setErrMsg(t("teacher-appointment.title.monthMsg"));
            setSnackbarOpen(true);
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        if (false && isPastDate(selectedDate)) {
            setErrMsg(t("teacher-appointment.title.monthMsg"));
            setSnackbarOpen(true);
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        /* setSelectedDate(selectedDate);


       // Adjust time zone offset when formatting the date

       const adjustedDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000);
       const booking = {
           startDate: adjustedDate.toISOString().slice(0, 10), // Format the adjusted date for date textfield
       }*/

        setGlobalState({...globalState, selectedDate, add: true})


    };

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setErrMsg('');
    };

    const handleEventClick = (event) => {


        if (event.bookingId) {
            const v = {
                studentId: event.student?.studentId,
                teacherId: event.teacher?.teacherId,
                bookingId: event.bookingId,
                description: event.description,
                sstartDate: new Date(event.startDate),
                startDate: moment(event.start).format('YYYY-MM-DD'),
                startTime: moment(event.start).format('HH:mm'),
                teacherFeedback: event.teacherFeedback,
                attended : event.attended,
            };
            setGlobalState({...globalState, booking: v, minDate, maxDate, addEditBooking: true})
        } else if (event.appointmentId) {
            const v = {
                studentId: event.student?.studentId,
                teacherId: event.teacher?.teacherId,
                appointmentId: event.appointmentId,
                description: event.description,
                sstartDate: new Date(event.startDate),
                startDate: moment(event.start).format('YYYY-MM-DD'),
                startTime: moment(event.start).format('HH:mm'),
                evaluation: event.evaluation,
                attended : event.attended,
            };
            setGlobalState({...globalState, appointment: v, minDate, maxDate, addEditAppointment: true})
        }

    };


    const handleNavigate = (newDate, view, action) => {
        console.log('Navigating:', action, 'to', newDate, view);
        if (newDate.getMonth() != searchStartDate.getMonth()) {
            setSearchStartDate(newDate);
        }
    };

    moment.locale("fr");



    return (
        <React.Fragment>
            {/*<Typography variant={"h1"} mb={3}>{t('pages.title.basicCalendar')}</Typography>*/}
            <Stack
                sx={{
                    mb: 2,
                    mt: -2
                }}
                spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>


            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                open={snackbarOpen}
                autoHideDuration={2500}
                startAccessor="startDate"
                endAccessor="endDate"
                onClose={handleSnackbarClose}
                message={errMsg}
                action={
                    <IconButton color={"primary"} size={"small"}>
                        <WarningAmberOutlinedIcon/>
                    </IconButton>
                }
            />

            <Card>
                <CardContent>
                    <CalendarWrapper>
                        <Calendar
                            formats={{dayHeaderFormat, dateFormat, dayFormat, dayRangeHeaderFormat}}
                            messages={{
                                next: "Suivant",
                                previous: "Précédent",
                                today: "Aujourd’hui",
                                month: "Mois",
                                week: "Semaine",
                                day: "Jour",
                                agenda: 'Agenda',
                                event: 'Évènement',
                                date: 'Date',
                                time: 'Créneau',
                            }}
                            localizer={localizer}
                            events={events}
                            step={15}
                            defaultDate={new Date()}
                            onNavigate={handleNavigate}
                            style={{height: 600, overflowX: 'auto'}}
                            selectable={true} // Enable user selection
                            onSelectSlot={handleSelectSlot}
                            startAccessor="start"
                            endAccessor="end"
                            onSelectEvent={(event) => {
                                handleEventClick(event)
                            }}
                            eventPropGetter={(event, start, end, isSelected) => {
                                let color = '';

                                if (event.scheduleId) {
                                    color = '#8C52FF';
                                } else if (event.appointmentId) {
                                    color = event.evaluation ? '#454545' : '#FFB733'
                                    color = event.cancelled ? '#D22B2B' : color;

                                } else if (event.bookingId) {
                                    color = event.teacherFeedback?.ratings?.length > 0 ? '#1D6ADE' : '#59B2FF';
                                    color = event.cancelled ? '#D22B2B' : color;
                                }

                                return {
                                    style: {
                                        backgroundColor: color,
                                        color: isSelected ? '#FFF' : "#FFF",
                                    },
                                };
                            }}
                            components={{
                                event: EventComponent,
                                agenda: {
                                    event: AgendaEventComponent,
                                },
                            }}
                        />
                    </CalendarWrapper>
                </CardContent>
            </Card>
        </React.Fragment>

    )
        ;
};

export default CalendarBasic;
