import Div from "@jumbo/shared/Div";
import React, {useState} from "react";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    TextField
} from "@mui/material";
import {AccountCircle} from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from "@mui/material/Select";
import {COUNTRIES, PHONE_COUNTRIES, timings} from "../../../../utils/constants/appActions";
import {useTranslation} from "react-i18next";
import {useAppMutation} from "../../../../services";
import teacherServices from "../../../../services/teacher-services";
import {useCalendarContext} from "../context";


const steps = ['Basic Info', 'Complete', 'Finish'];


const Step1 = ({
                   booking,
                   handleNext,
                   hide,
                   setShowEvaluation,
                   setShowFeedback,
                   setStepsData,
                   evaluation,
                   feedbacks
               }) => {

    const {globalState, setGlobalState} = useCalendarContext();
    const [values, setValues] = React.useState({student: {phoneCountry: {code: '33'}}});
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const [showNotAttendedConfirmation, setNotAttendedShowConfirmation] = useState(false);
    const {
        mutate: studentAttendedBooking,
        isSuccess,
        isLoading,
        isError,
        data: dEvaluation
    } = useAppMutation(teacherServices.studentAttendedBooking);


    React.useEffect(() => {

        if (booking) {

            const v = {...booking}

            if (v.parent?.phone?.code) {
                const phoneCountry = PHONE_COUNTRIES.find(item => item.code === v.parent.phone.code);
                v.parent.phoneNumber = v.parent.phone.number
                v.parent.phoneCountry = phoneCountry;
            }


            if (v.student?.residenceCountry) {

                const country = COUNTRIES.find(item => item.code === v.student.residenceCountry);
                v.student.country = country;

            }

            setValues({...v})

        }

    }, [booking]);

    React.useEffect(() => {

        if (isSuccess) {
            setValues({...values, attended: 'not-attended'})
            setNotAttendedShowConfirmation(false);
        }

    }, [isSuccess]);


    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleComplete = () => {

        handleNext({step: 2, isCompleted: true})
    }

    const handleChildInfoClick = () => {
        const g = {...globalState, showStudentInfo: true, studentId: values.studentId}
        setGlobalState(g);
    };

    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Div sx={{mt: 3}}>
            <FormControl noValidate autoComplete="off">
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 1.4,
                            mt: 0.5
                        },
                    }}
                >


                    <FormControl fullWidth sx={{mt: 0.5}}>
                        {values.firstName && values.lastName && (
                            <TextField
                                disabled
                                id="outlined-multiline-static"
                                label={t("teacher-appointment-form.title.studentName")}
                                value={`${values.firstName} ${values.lastName}`}
                                readOnly
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </FormControl>
                    {/*<FormControl sx={{
                        width: {xs: '100%', sm: '100%', xl: '100%'},

                    }}>

                        <TextField
                            disabled
                            id="email"
                            label={t('teacher-appointment-form.title.parentMail')}
                            InputLabelProps={{shrink: true}}
                            fullWidth
                            value={values.parent?.email}
                        />
                    </FormControl>*/}

                    {/*<FormControl sx={{
                        width: {xs: '100%', sm: '100%', xl: '48%'},

                    }}>

                        <TextField
                            disabled
                            id="firstName"
                            value={values.parent?.firstName}
                            label={t('teacher-appointment-form.title.firstName')}
                            InputLabelProps={{shrink: true}}
                            fullWidth
                        />
                    </FormControl>*/}

                    {/*<FormControl sx={{
                    width: {xs: '100%', sm: '100%', xl: '100%'},

                    }}>

                        <TextField
                           disabled
                           id="lastName"
                           value={values.parent?.lastName}
                           label={t('teacher-appointment-form.title.lastName')}
                           InputLabelProps={{shrink: true}}
                           fullWidth
                        />
                    </FormControl>*/}

                    {values.parent &&
                        <Div sx={{mt: 1, mb: 2, display: 'flex', alignItems: 'center'}}>
                            <FormControl variant="outlined" sx={{flex: '0 0 140px', marginRight: 2}}>
                                <Autocomplete
                                    disabled
                                    value={values.parent?.phoneCountry}
                                    id="country-select-demo"
                                    options={PHONE_COUNTRIES}
                                    autoHighlight
                                    getOptionLabel={(option) => {
                                        return ' +' + option.code;
                                    }}
                                    disableClearable={true}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                                                alt=""
                                            />
                                            {option.label} ({option.countryCode}) +{option.code}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('teacher-profile.title.code')}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <React.Fragment>
                                                        {values.parent?.phoneCountry && (
                                                            <><img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${values.parent?.phoneCountry?.countryCode?.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${values.parent?.phoneCountry?.countryCode?.toLowerCase()}.png 2x`}
                                                                alt=" "
                                                            /></>
                                                        )}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl variant="outlined" sx={{flex: 1}}>
                                <TextField
                                    disabled
                                    id="phoneNumber"
                                    label={t('parent-signup.title.phone')}
                                    fullWidth
                                    value={values.parent?.phoneNumber}
                                />
                            </FormControl>
                        </Div>}


                    <FormControl fullWidth>
                        <TextField
                            disabled
                            id="date"
                            label={t("teacher-appointment-form.title.date")}
                            type="date"
                            value={values.startDate}
                            sx={{width: "100%"}}
                            InputLabelProps={{
                                shrink: true,
                            }}

                            readOnly
                        />
                    </FormControl>


                    <FormControl fullWidth sx={{mt: 1}}>

                        {true && <TextField label={t("parent.title.time")} disabled={true} value={values.startTime}/>}
                        {false && <>
                            <InputLabel
                                id="demo-simple-select-label">{t("teacher-appointment-form.title.time")}</InputLabel>
                            {values.startTime && (
                                <Select labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.startTime}
                                        label={t("teacher-appointment-form.title.time")}
                                        readOnly
                                    //onChange={handleChange('startTime')}
                                >
                                    {timings.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}</>}
                    </FormControl>
                    <FormControl sx={{mt: 1.5}} fullWidth>
                        <TextField
                            id="outlined-multiline-static"
                            label={t("common.desc")}
                            disabled
                            multiline
                            rows={2}
                            value={values.description}
                            name="description"
                            //onChange={handleChange('description')}
                            readOnly
                        />
                    </FormControl>


                    <Stack
                        sx={{
                            mb: 2,
                            width: '90%',
                            mx: 'auto',
                            display: 'block',
                            position: 'relative', // Add position relative to enable absolute positioning of loader
                        }}
                        spacing={2}>
                        {errMsg && <Alert severity="error">{errMsg}</Alert>}
                    </Stack>
                    {!showNotAttendedConfirmation && (<Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Button
                            onClick={() => {
                                hide()
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.closeBtn")}
                        </Button>
                        <Button onClick={handleChildInfoClick} sx={{mr: 1}}>
                            {t('common.studentBtn')}
                        </Button>
                        <Div sx={{flex: '1 1 auto'}}/>
                        {!values.cancelled && values.teacherFeedback?.ratings?.length === 0 && (values.attended !== 'not-attended') &&
                            <LoadingButton

                                onClick={() => {

                                    setNotAttendedShowConfirmation(true);
                                }}
                                sx={{mr: 1}}
                            >
                                {values.attended && values.attended === 'not-attended' ? 'Attended' : 'Not Attended'}
                            </LoadingButton>}
                        {evaluation && <Button
                            onClick={() => {
                                setShowEvaluation(true);
                            }}
                            sx={{mr: 1}}
                        >
                            {t('common.evaluationBtn')}
                        </Button>}

                        {feedbacks && <Button
                            onClick={() => {
                                setShowFeedback(true);
                            }}
                            sx={{mr: 1}}
                        >
                            Feedbacks
                        </Button>}


                        {(!values.cancelled && values?.attended !== 'not-attended') &&
                            <LoadingButton variant={"variant"} onClick={handleComplete}>
                                {t("common.nextBtn")}
                            </LoadingButton>}

                    </Div>)}

                    {showNotAttendedConfirmation && (
                        <Alert severity="warning" sx={{mb: 1, display: 'flex', alignItems: 'center'}}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                <span
                                    style={{marginRight: '15px'}}>{t('errMsg.notAttendBooking')}</span>
                                <Stack direction="row" spacing={2}>
                                    <LoadingButton loading={isLoading}
                                                   onClick={() => {
                                                       studentAttendedBooking({
                                                           bookingId: values.bookingId,
                                                           attended: 'not-attended'
                                                       })
                                                       setValues({...values, attended: 'not-attended'})
                                                   }}>{t('common.yesBtn')}</LoadingButton>
                                    <Button onClick={() => {
                                        setNotAttendedShowConfirmation(false)
                                    }}>{t('common.noBtn')}</Button>
                                </Stack>
                            </div>
                        </Alert>
                    )}

                </Div>
            </FormControl>
        </Div>
    </Div>;

}

export default Step1;
