import React, { useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import DDFile from "../../../../component/DDFile";
import FileBase64 from "react-file-base64";
import { HOBBIES, learningStyle } from "../../../../utils/constants/appActions";
import { useAppMutation } from "../../../../services";
import adminServices from "../../../../services/admin-services";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Step3 = ({ hide, setStepsData, stepsData, handleNext }) => {
  const {
    mutate: loadSkills,
    isError: isSkillError,
    data: dataSkills,
    isSuccess: isSkillSuccess,
    isLoading: isSkillLoading,
  } = useAppMutation(adminServices.loadSkills);

  useEffect(() => {
    if (isSkillSuccess) {
      console.log("dataSkills----", dataSkills);
      setSkills(dataSkills);
    }
  }, [isSkillError, isSkillSuccess]);

  const [skills, setSkills] = React.useState([]);

  const [values, setValues] = React.useState({
    teachingSpecialities: [],
    ageGroupPreferences: [],
    teachingDiploma: "",
    learningStyles: [],
    hobbies: [],
    summary: "",
    webDesc: "",
    skills: [],
  });
  const [errMsg, setErrMsg] = React.useState("");
  const { t } = useTranslation();
  const [value, setValue] = React.useState();
  const [hobbies, setHobbies] = React.useState([]);
  const ref = React.useRef();

  useEffect(() => {
    if (stepsData) {
      setValues({ ...values, ...stepsData });
      loadSkills();
    }

    const translatedHobbies = HOBBIES.map((hobby) => ({
      key: hobby.key,
      value: t(hobby.key),
    }));
    setHobbies(translatedHobbies);
  }, [stepsData]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const submitStep3 = async () => {
    setErrMsg("");

    const {
      teachingSpecialities,
      ageGroupPreferences,
      teachingDiploma,
      base64,
      selectedFile,
      learningStyles,
      hobbies,
      summary,
      webDesc,
      skills,
    } = values;

    setStepsData({
      ...stepsData,
      teachingSpecialities,
      ageGroupPreferences,
      teachingDiploma,
      base64,
      learningStyles,
      hobbies,
      summary,
      webDesc,
      skills,
    });
    handleNext(4);
  };

  const backToStep2 = async () => {
    handleNext(2);
  };

  const handleChangeMultiple = (name) => (event) => {
    const {
      target: { value },
    } = event;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: typeof value === "string" ? value.split(",") : value,
    }));
  };
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {},
      }}
      noValidate
      autoComplete="off"
    >
      <Div sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
        <FormControl
          sx={{
            width: "100%",
            mt: 1,
          }}
        >
          <TextField
            id="outlined-multiline-static"
            label={t("teacherForm.diploma")}
            value={values.teachingDiploma}
            name="comments"
            onChange={handleChange("teachingDiploma")}
          />
        </FormControl>

        <FormControl
          sx={{
            width: "100%",
            mt: 2,
          }}
        >
          <Typography variant={"h4"}>{t("teacherForm.diploma")}</Typography>
          <FileBase64
            ref={ref}
            maxSize={1000000}
            multiple={false}
            onDone={async (file) => {
              console.log(file, ref);
              if (file.file.size > 1000000) {
                setErrMsg(t("common.sizeErr"));
                setTimeout(() => {
                  setErrMsg("");
                }, 3000);
                ref.current.value = "";
                setValues({ ...values, base64: undefined });
              } else {
                setValues({ ...values, base64: file.base64 });
              }
            }}
          />
          {values.base64 && <DDFile base64={values.base64} />}
          {!values.base64 && values?.teachingDiplomaFiles?.length > 0 && (
            <DDFile path={values?.teachingDiplomaFiles[0]} />
          )}
        </FormControl>

        <FormControl
          sx={{ width: { xs: "100%", sm: "100%", xl: "100%" }, mt: 1.5 }}
        >
          <Autocomplete
            style={{ margin: "10px 0" }}
            multiple
            id="tags-outlined"
            options={skills}
            value={values.skills ? values.skills : []}
            freeSolo
            autoSelect
            onChange={(e, newValues) => {
              const val = e.target.value;
              console.log(newValues, val);

              if (e.target.value) {
                let { skills: cats } = values;

                const index = cats.findIndex(
                  (ii) => ii.trim().toLowerCase() === val.trim().toLowerCase(),
                );
                if (index === -1) {
                  cats = [...cats, val.trim().toLowerCase()];
                  setValues({ ...values, skills: cats });
                }
              } else {
                setValues({ ...values, skills: newValues });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("common.skills")}
                placeholder={t("common.skills")}
                value={values.skills}
              />
            )}
          />
        </FormControl>

        <FormControl
          sx={{
            width: "100%",
            mt: 3,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            {t("teacherForm.speciality")}
          </InputLabel>
          <Select
            multiple
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.teachingSpecialities}
            onChange={handleChange("teachingSpecialities")}
            label={t("teacherForm.speciality")}
          >
            <MenuItem value="conversation">Conversation practice</MenuItem>
            <MenuItem value="vocabulary">Vocabulary building</MenuItem>
            <MenuItem value="writing">Writing skills</MenuItem>
            <MenuItem value="reading">Reading comprehension</MenuItem>
            <MenuItem value="listening">Listening comprehension</MenuItem>
            <MenuItem value="business">Business English</MenuItem>
            <MenuItem value="academic">Academic English</MenuItem>
            <MenuItem value="purpose">
              English for specific purposes (e.g., tourism, hospitality,
              healthcare)
            </MenuItem>
            <MenuItem value="awareness">
              Cultural awareness and intercultural communication
            </MenuItem>
            <MenuItem value="exam">
              Exam preparation (e.g., TOEFL, IELTS, Cambridge exams)
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: "100%",
            mt: 3,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            {t("teacherForm.ageGroup")}
          </InputLabel>
          <Select
            multiple
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.ageGroupPreferences}
            onChange={handleChange("ageGroupPreferences")}
            label={t("teacherForm.ageGroup")}
          >
            <MenuItem value="3-5-years-old">3-5 years old</MenuItem>
            <MenuItem value="6-8-years-old">6-8 years old</MenuItem>
            <MenuItem value="9-11-years-old">9-11 years old</MenuItem>
            <MenuItem value="12-15-years-old">12-15 years old</MenuItem>
            <MenuItem value="16-18-years-old">16-18 years old</MenuItem>
            <MenuItem value="18-plus-years-old">+ 18ans</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: { xs: "100%", sm: "100%", xl: "100%" },
            mt: 3,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            {t("appointmentForm.learningStyle")}
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            value={values.learningStyles}
            label={t("appointmentForm.learningStyle")}
            onChange={handleChangeMultiple("learningStyles")}
            multiple
            input={<OutlinedInput label="Learning Style" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {learningStyle.map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox
                  checked={values.learningStyles.indexOf(item) > -1}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    handleChangeMultiple("learningStyle")({
                      target: {
                        value: checked
                          ? [...values.learningStyles, item]
                          : values.learningStyles.filter(
                              (value) => value !== item,
                            ),
                      },
                    });
                  }}
                />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: { xs: "100%", sm: "100%", xl: "100%" },
            mt: 3,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            {t("teacherForm.hobby")}
          </InputLabel>
          <Select
            sx={{ width: "100%", minWidth: "50%" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values?.hobbies}
            onChange={handleChange("hobbies")}
            label={t("teacherForm.hobby")}
            multiple
          >
            {hobbies.map((hobby) => (
              <MenuItem key={hobby.key} value={hobby.key}>
                {t(`hobbyList.${hobby.key}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: { xs: "100%", sm: "100%", xl: "100%" },
            mt: 3,
          }}
        >
          <TextField
            label={t("teacherForm.summary")}
            value={values.summary}
            onChange={handleChange("summary")}
          />
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", sm: "100%", xl: "100%" },
            mt: 3,
          }}
        >
          <TextField
            label={t("teacherForm.webDesc")}
            value={values.webDesc}
            onChange={handleChange("webDesc")}
          />
        </FormControl>
      </Div>

      <Stack sx={{ width: "100%" }} spacing={2}>
        {errMsg && <Alert severity="error">{errMsg}</Alert>}
      </Stack>
      <Div sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          onClick={() => {
            hide();
          }}
          sx={{ mr: 1 }}
        >
          {t("common.closeBtn")}
        </Button>
        <Button onClick={backToStep2} sx={{ mr: 1 }}>
          {t("common.backBtn")}
        </Button>
        <Div sx={{ flex: "1 1 auto" }} />
        <Button variant={"contained"} onClick={submitStep3}>
          {t("common.nextBtn")}
        </Button>
      </Div>
    </Box>
  );
};

export default Step3;
