import React from 'react';
import {Avatar, Box, Button, Container, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const PaymentStatus = ({}) => {
    const {t} = useTranslation();
    const params = useParams();
    const [status, setStatus] = React.useState();

    React.useEffect(() => {
        if (!status) {
            setStatus(params.status);
        }
    }, [status])


    const handleClose = () => {
        window.location = '/dashboard';
    }


    return (
        <Container
            maxWidth="md"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                width: "100%"
            }}
        >
            <Box
                sx={{
                    width: {xs: '90%', sm: '80%', md: '70%', lg: '60%'},
                    padding: 8,
                    textAlign: 'center',
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: status === 'success' ? '#a4c639' : '#ff6347',
                    backgroundColor: status === 'success' ? '#f0f9e8' : '#ffe5e5',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {status === 'success' && (
                    <>
                        <Avatar sx={{bgcolor: '#a4c639', mb: 3, width: 56, height: 56}}>
                            &#10004;
                        </Avatar>
                        <Typography variant="h3">{t("parent-subscription.title.success")}</Typography>
                        <Typography variant="h6"
                                    sx={{mt: 2}}>{t("parent-subscription.title.paymentSuccess")}</Typography>
                        <Button
                            variant="contained"
                            sx={{mt: 3, bgcolor: '#a4c639', px: 6, py: 1.5, fontSize: '14px'}}
                            onClick={handleClose}
                        >
                            {t("parent-subscription.title.continueBtn")}
                        </Button>
                    </>
                )}

                {status === 'error' && (
                    <>
                        <Avatar sx={{bgcolor: '#ff6347', mb: 3, width: 56, height: 56}}>
                            &#10060;
                        </Avatar>
                        <Typography variant="h3">{t("parent-subscription.title.error")}</Typography>
                        <Typography variant="h6" sx={{mt: 2}}>{t("parent-subscription.title.paymentError")}</Typography>
                        <Button
                            variant="contained"
                            sx={{mt: 3, bgcolor: '#ff6347', px: 6, py: 1.5, fontSize: '14px'}}
                            onClick={handleClose}
                        >
                            {t("parent-subscription.title.tryAgain")}
                        </Button>
                    </>
                )}

                {!status && (
                    <Typography variant="h6">Processing your payment...</Typography>
                )}
            </Box>
        </Container>
    );
};

export default PaymentStatus;
