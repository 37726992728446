import React from "react";
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import authServices from "../../../services/auth-services";
import { useSelector } from "react-redux";
import DDImage from "../../../component/DDImage";
import { Link } from "react-router-dom";

const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const { setAuthToken } = useJumboAuth();
  const { authUser } = useSelector((state) => state.auth);
  const isParent = authServices.isParent();
  const onLogout = async () => {
    const isSuperAdmin = authServices.isSuperAdmin();
    const isParent = authServices.isParent();
    const isStudent = authServices.isStudent();
    const isTeacher = authServices.isTeacher();
    authServices.logout();
    setAuthToken(null);
    if (isSuperAdmin) {
      // navigate("/admin/login");
      window.location = "/admin/login";
    } else if (isParent) {
      // navigate("/parent/login");
      window.location = "/parent/login";
    } else if (isStudent) {
      // navigate("/parent/login");
      window.location = "/student/login";
    } else if (isTeacher) {
      // navigate("/parent/login");
      window.location = "/teacher/login";
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <DDImage
            path={authUser?.images?.length > 0 ? authUser.images[0] : ""}
            sizes={"small"}
            sx={{ boxShadow: 25, cursor: "pointer" }}
          />
        }
      >
        {}
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            p: (theme) => theme.spacing(2.5),
          }}
        >
          {authUser?.images?.length > 0 ? (
            <DDImage
              path={authUser.images[0]}
              alt={authUser.firstName + " " + authUser.lastName}
              sx={{ width: 60, height: 60, mb: 2 }}
            />
          ) : (
            <img src="/images/logos/profile-icon.png" alt="Logo" width="80" />
          )}

          <Typography variant={"h5"} sx={{ mt: 1 }}>
            {authUser?.firstName} {authUser?.lastName}
          </Typography>
          <Typography variant={"body1"} color="text.secondary">
            {authUser?.email}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <PersonOutlineIcon />
              </ListItemIcon>
              <ListItemText
                onClick={() => {
                  navigate("/dashboard/profile");
                }}
                primary="Profile"
                sx={{ my: 0 }}
              />
            </ListItemButton>

            {/*    {isParent && (
                        <ListItemButton component="a" href="https://hipekids.com/contact/" target="_blank" rel="noopener noreferrer">
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <HelpOutlineOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Support" sx={{ my: 0 }} />
                        </ListItemButton>
                    )} */}
            {/**
                         <ListItemButton>
                         <ListItemIcon sx={{minWidth: 36}}>
                         <RepeatOutlinedIcon/>
                         </ListItemIcon>
                         <ListItemText onClick={() => navigate("/samples/content-layout")} primary="Switch User"
                         sx={{my: 0}}/>
                         </ListItemButton>
                         **/}
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
