import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CardHeader from "@mui/material/CardHeader";
import {
  alpha,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import BadgeIcon from "@mui/icons-material/Badge";
import EmailIcon from "@mui/icons-material/Email";
import Divider from "@mui/material/Divider";
import PaymentsIcon from "@mui/icons-material/Payments";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import List from "@mui/material/List";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Stack from "@mui/material/Stack";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import Div from "@jumbo/shared/Div";
import { useTranslation } from "react-i18next";
import DDImage from "../../../../component/DDImage";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Chip from "@mui/material/Chip";
import { PictureAsPdf } from "@mui/icons-material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { useAppMutation } from "../../../../services";
import adminServices from "../../../../services/admin-services";
import studentServices from "../../../../services/student-services";

const ContactDetail = ({ program, onClose }) => {
  console.log("P----------", program);
  const { t } = useTranslation();
  const [evaluationType, setEvaluationType] = useState(null);

  const {
    mutate: evaluateFirstQuizPDF,
    isError: isErrorEvaluationFirstPDF,
    data: evaluationFirstPDF,
    isLoading: isLoadingEvaluationFirstPDF,
    isSuccess: isSuccessEvaluationFirstPDF,
  } = useAppMutation(adminServices.evaluateFirstQuizPDF);

  useEffect(() => {
    if (isSuccessEvaluationFirstPDF) {
      // Decode Base64 string and create a Blob URL
      const byteCharacters = atob(evaluationFirstPDF.base64); // Decode Base64 string
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);

      // Log the Blob URL for debugging
      console.log("Blob URL:", blobUrl);

      const fileName =
        evaluationType === "start"
          ? `First Evaluation Result (${program.course?.name}).pdf`
          : `Final Evaluation Result (${program.course?.name}).pdf`;

      // Create a link element and click it to trigger the download
      const link = document.createElement("a");
      link.href = blobUrl;
      //link.download = 'First Evaluation Result.pdf'; // Specify the filename
      //link.download = `First Evaluation Result (${program.course?.name}).pdf`;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (isErrorEvaluationFirstPDF) {
      console.error("Error evaluating PDF:", isErrorEvaluationFirstPDF);
    }
  }, [
    isSuccessEvaluationFirstPDF,
    isErrorEvaluationFirstPDF,
    evaluationFirstPDF,
  ]);

  const {
    mutate: bookingPDF,
    isError: isErrorBookingPDF,
    data: BookingPDFData,
    isLoading: isLoadingBookingPDF,
    isSuccess: isSuccessBookingPDF,
  } = useAppMutation(adminServices.bookingPDF);

  useEffect(() => {
    if (isSuccessBookingPDF) {
      // Decode Base64 string and create a Blob URL
      const byteCharacters = atob(BookingPDFData.base64); // Decode Base64 string
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);

      // Log the Blob URL for debugging
      console.log("Blob URL:", blobUrl);

      // Create a link element and click it to trigger the download
      const link = document.createElement("a");
      link.href = blobUrl;
      //link.download = 'First Evaluation Result.pdf'; // Specify the filename
      link.download = `Bookings (${program.course?.name}).pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (isErrorBookingPDF) {
      console.error("Error evaluating PDF:", isErrorBookingPDF);
    }
  }, [isSuccessBookingPDF, isErrorBookingPDF, BookingPDFData]);

  const handleStartPDF = () => {
    setEvaluationType("start");
    evaluateFirstQuizPDF({
      transactionId: program.transactionId,
      type: program.evaluations[0].type,
    });
  };

  const handleBookingPDF = () => {
    bookingPDF({
      transactionId: program.transactionId,
    });
  };

  const handleFinalPDF = () => {
    setEvaluationType("end");
    evaluateFirstQuizPDF({
      transactionId: program.transactionId,
      type: program.evaluations[1].type,
    });
  };

  return (
    <Div sx={{ m: (theme) => theme.spacing(-2.5, -3), mb: 2 }}>
      <CardHeader
        title={program?.course?.name}
        subheader=<Chip
          color="warning"
          size="small"
          label={program.paid ? "Paid Course" : "Requested"}
          sx={{ mr: 1 }}
        />
        avatar={
          program?.course?.images?.length > 0 ? (
            <DDImage path={program.course?.images[0]} alt={program?.title} />
          ) : (
            <Avatar alt={program?.title}>
              <InsertDriveFileIcon />
            </Avatar>
          )
        }
        action={
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <List disablePadding>
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            {program.student?.images?.length > 0 ? (
              <DDImage path={program.student?.images[0]} alt={program.title} />
            ) : (
              <Avatar
                variant="rounded"
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
                }}
              >
                <BadgeIcon sx={{ color: "primary.light" }} />
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                {t("admin-transactions.title.studentName")}
              </Typography>
            }
            secondary={
              <Typography
                variant={"h5"}
                mb={0}
              >{`${program.student?.firstName} ${program.student?.lastName}`}</Typography>
            }
          />
        </ListItem>
        <Divider component={"li"} />
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
              }}
            >
              <EmailIcon sx={{ color: "primary.light" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                {t("admin-transactions.title.email")}
              </Typography>
            }
            secondary={
              <Typography variant={"h5"} mb={0}>
                {program?.student?.email}
              </Typography>
            }
          />
        </ListItem>

        <Divider component={"li"} />

        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
              }}
            >
              <ConfirmationNumberIcon sx={{ color: "primary.light" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
                {t("admin-transactions.title.amount")}
              </Typography>
            }
            secondary={
              <Typography variant={"h5"} mb={0}>
                {program?.amount}
              </Typography>
            }
          />
        </ListItem>

        <Divider component={"li"} />

        <ListItem sx={{ px: 4 }}>
          <Grid
            container
            spacing={1}
            sx={{ mt: 1, mb: 1, justifyContent: "flex-start" }}
          >
            {program.evaluations && program.evaluations[0].type === "start" && (
              <Grid
                item
                xs="auto"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Chip
                  color={"success"}
                  size={"small"}
                  label={"Start Evaluation Result"}
                  onClick={handleStartPDF}
                  icon={<PictureAsPdf />}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    px: 1, // Padding on the x-axis
                    py: 0.5, // Padding on the y-axis
                    "& .MuiChip-icon": {
                      marginRight: -0.5, // Margin to the right of the icon
                    },
                  }}
                />
              </Grid>
            )}

            {program.bookingIds && program.bookingIds.length > 0 && (
              <Grid
                item
                xs="auto"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Chip
                  color={"success"}
                  size={"small"}
                  label={"Booking Result"}
                  onClick={handleBookingPDF}
                  icon={<PictureAsPdf />}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    px: 1, // Padding on the x-axis
                    py: 0.5, // Padding on the y-axis
                    "& .MuiChip-icon": {
                      marginRight: -0.5, // Margin to the right of the icon
                    },
                  }}
                />
              </Grid>
            )}

            {program.evaluations && program.evaluations[1]?.type === "end" && (
              <Grid
                item
                xs="auto"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Chip
                  color={"success"}
                  size={"small"}
                  label={"Final Result"}
                  onClick={handleFinalPDF}
                  icon={<PictureAsPdf />}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    px: 1, // Padding on the x-axis
                    py: 0.5, // Padding on the y-axis
                    "& .MuiChip-icon": {
                      marginRight: -0.5, // Margin to the right of the icon
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </ListItem>

        {(program.evaluations?.[0]?.type === "start" ||
          (program.bookingIds && program.bookingIds.length > 0) ||
          program.evaluations?.[1]?.type === "end") && (
          <Divider component={"li"} />
        )}
      </List>

      {/*<Stack spacing={1} direction={"row"} sx={{px: 4, py: 2}}>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: theme => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <ForumOutlinedIcon fontSize={'medium'} color={"inherit"}/>
                </IconButton>
                <IconButton
                    size={'large'}
                    sx={{
                        backgroundColor: theme => theme.palette.grey[400],
                        color: 'common.white',

                        '&:hover': {
                            backgroundColor: 'primary.main'
                        }
                    }}
                >
                    <LocalPhoneIcon fontSize={'medium'} color={"inherit"}/>
                </IconButton>
            </Stack>*/}
    </Div>
  );
};

export default ContactDetail;
