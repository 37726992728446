import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
    Alert,
    Autocomplete,
    Box, Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Link from "@mui/material/Link";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Span from "@jumbo/shared/Span";
import Div from "@jumbo/shared/Div";
import LoadingButton from "@mui/lab/LoadingButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import Stack from "@mui/material/Stack";
import Popover from '@mui/material/Popover';
import SaveIcon from '@mui/icons-material/Save';
import {useProfileContext} from "../context";
import {useAppMutation} from "../../../../services";
import teacherServices from "../../../../services/teacher-services";
import {
    fullNumberRegex,
    countryFormattingRules,
    PHONE_COUNTRIES,
    fullNumberRegex1
} from "../../../../utils/constants/appActions";
import {useTranslation} from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';



const AboutForm = ({hide, location}) => {

    const {globalState, setGlobalState} = useProfileContext();
    const [errMsg, setErrMsg] = React.useState('');
    const [errPhone, setErrPhone] = React.useState(false);
    const [countryRefresh, setCountryRefresh] = React.useState(false);
    const {t} = useTranslation();

    const [values, setValues] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
        phone: {},
    });
    const {
        mutate: updateInfo,
        isSuccess,
        data,
        isError
    } = useAppMutation(teacherServices.updateProfileContactInfo);

    React.useEffect(() => {
        if (globalState.profile) {
            const v = {...globalState.profile};
            setValues(v)
        }
    }, [globalState])


    React.useEffect(() => {
        if (isSuccess) {
            setGlobalState({...globalState, refreshList: true})
            hide(false);
        }
    }, [isError, data, isSuccess])





    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };


    const [loading, setLoading] = React.useState(false);

    function handleClick11() {
        if (values.firstName && values.lastName && values.phone?.code && values.phone?.number) {
            const phone = values.phone;
            const fullNumber = `+${phone.code}${phone.number}`;
            phone.fullNumber = fullNumber;
            updateInfo({firstName: values.firstName, lastName: values.lastName, phone});
        }
        else{
            setErrMsg(t('common.inputError'))
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }
    }

    const handleClick1 = async () => {
        setErrMsg('');

        const { firstName, lastName, title, phone, } = values;
        const fullPhoneNumber = `+${phone.code}${phone.number}`;
        console.log("fullPhoneNumber",fullPhoneNumber);
        const fullPhoneNumberWithoutSpaces = fullPhoneNumber.replace(/\s/g, '');

        if (values.firstName && values.lastName && values.phone?.code && values.phone?.number) {
            if (!fullNumberRegex1.test(fullPhoneNumberWithoutSpaces)) {
                setErrMsg("Invalid Full Phone Number");
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
                return;
            }
            const validatedFullPhoneNumber = fullPhoneNumberWithoutSpaces;
            const obj = {
                ...values,
                phone: {
                    fullNumber: fullPhoneNumberWithoutSpaces,
                    code: values.phone.code,
                    number: values.phone.number
                },
            };
            console.log("Step2------------", obj);
            updateInfo(obj)
        } else {
            setErrMsg('Fields not filled.');
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }
    };

    const handleCountryChange0 = (event, newValue) => {
        const phone = values.phone;
        phone.code = newValue.code;
        setValues({...values, phone});
    };

    const handleCountryChange = (event, newValue) => {
        const phone = values.phone;
        phone.code = newValue.code;

        // Format phone number based on the selected country code
        const countryFormattingRule = countryFormattingRules.find(rule => rule.code === newValue.code);
        if (countryFormattingRule) {
            const formattedNumber = phone.number.replace(countryFormattingRule.pattern, countryFormattingRule.format);
            phone.number = formattedNumber;
        }

        setValues({ ...values, phone });
    };

    const handleChangePhone = (phone, country) => {

        console.log(country, phone);
        const dialCode = country.dialCode;
        const newPhone = {
            code: dialCode,
            fullNumber: phone,
            number: phone.slice(dialCode.length)
        }
        /*const countryCode = country.dialCode; // Get the country code
        const localNumber = phone.slice(countryCode.length); // Extract the local number by removing the country code
        const formattedPhone = `+${countryCode}${phone.replace(countryCode, '')}`;
        const label = country.name;

        console.log('Country Code:', countryCode);
        console.log('Local Number:', localNumber);
        console.log('Full Number:', phone);
        console.log('Formatted Phone Number:', formattedPhone);
        console.log('label:', label);

        setPhoneDetails({countryCode, localNumber});*/
        setValues({...values, phone: newPhone});
    };

    return <Div><FormControl noValidate autoComplete="off">
        <Div
            sx={{
                '& .MuiTextField-root': {
                    mb: 3,
                    mt: 1
                },
            }}
        >

            <TextField
                id="input-with-icon-textfield"
                fullWidth
                size="small"
                name="email"
                label={t('common.email')}
                value={values.email}
                onChange={handleChange('email')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EmailIcon/>
                        </InputAdornment>
                    ),
                    readOnly: true,
                }}
                disabled
                variant="outlined"
            />

            <TextField
                id="input-with-icon-textfield"
                fullWidth
                size="small"
                name="firstName"
                label={t('common.firstName')}
                value={values.firstName}
                onChange={handleChange('firstName')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PersonIcon/>
                        </InputAdornment>
                    ),
                }}
                variant="outlined"
            />

            <TextField
                id="input-with-icon-textfield"
                fullWidth
                size="small"
                name="lastName"
                label={t('common.lastName')}
                value={values.lastName}
                onChange={handleChange('lastName')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PersonIcon/>
                        </InputAdornment>
                    ),
                }}
                variant="outlined"
            />

            <PhoneInput
                country={'fr'} // Default country code
                value={values.phone?.fullNumber}
                onChange={handleChangePhone}
                inputStyle={{width: '100%'}} // Ensures full width styling
                required
                enableSearch
            />

            {/*<Div sx={{mt: 1, mb: 2, display: 'flex', alignItems: 'center'}}>
                <FormControl variant="outlined" sx={{flex: '0 0 130px', marginRight: 2}}>
                    {values.phone?.code && <Autocomplete
                        defaultValue={values.phone}
                        options={PHONE_COUNTRIES}
                        autoHighlight
                        onChange={handleCountryChange}
                        getOptionLabel={(option) => {
                            return ' +' + option.code;
                        }}
                        disableClearable={true}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.countryCode?.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.countryCode?.toLowerCase()}.png 2x`}
                                    alt=""
                                />
                                {option.countryCode}(+{option.code})
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('teacher-profile.title.code')}
                                error={errPhone}
                                required
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <React.Fragment>
                                            {values.phone && values.phone.countryCode && (
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${values.phone.countryCode?.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${values.phone.countryCode?.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                            )}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />}
                </FormControl>

                <FormControl variant="outlined" sx={{ flex: 1 }}>
                    <TextField
                        required
                        id="phone"
                        label={t('teacher-profile.title.phone')}
                        error={errPhone}
                        fullWidth
                        value={values.phone?.number}
                        onChange={(event) => {
                            let inputNumber = event.target.value;

                            // Remove leading zeros
                            const sanitizedNumber = inputNumber.replace(/\D/g, '').replace(/^0+/, '');

                            let phone = values.phone;
                            phone.number = sanitizedNumber;

                            // Get selected country code
                            const selectedCountryCode = phone.code;

                            // Format phone number based on the selected country code
                            const countryFormattingRule = countryFormattingRules.find(rule => rule.code === selectedCountryCode);

                            // Enforce maximum digits restriction based on country code
                            if (countryFormattingRule) {
                                const maxDigits = countryFormattingRule.maxDigits;

                                // If the input length exceeds the maximum allowed digits, truncate the input
                                if (phone.number.length > maxDigits) {
                                    phone.number = phone.number.slice(0, maxDigits);
                                }

                                // If the input length equals the maximum allowed digits, prevent further input
                                if (phone.number.length === maxDigits) {
                                    event.preventDefault();
                                }
                            }

                            setValues({ ...values, phone });
                        }}
                    />
                </FormControl>
            </Div>*/}

            <Stack
                sx={{
                    mb: 2,
                    width: '90%',
                    mx: 'auto',
                    display: 'block',
                    position: 'relative', // Add position relative to enable absolute positioning of loader
                }}
                spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>

            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                    sx={{mr: 1,width:"30%"}}
                    onClick={() => hide(false)}
                >
                    {t('common.closeBtn')}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>


                <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={loading}
                    onClick={handleClick1}
                    sx={{width:"30%"}}
                >
                    {t('common.saveBtn')}
                </LoadingButton>
            </Div>



        </Div>
    </FormControl></Div>
}

const Contacts = () => {

    const {globalState, setGlobalState} = useProfileContext();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const [values, setValues] = React.useState('1');

    React.useEffect(() => {
        if (globalState.profile) {
            console.log('----------------------->',globalState.profile)
            setValues({...globalState.profile})
        }
    }, [globalState])

    const handleEmailIconClick = () => {
        setOpenDialog(true);
    };




    return (
        <JumboCardQuick
            title={
                <Typography variant={"h4"} mb={0}>{t('teacher-profile.title.contact')}
                    <Span sx={{color: 'text.secondary', fontSize: 13}}>
                        <EditOutlinedIcon onClick={handleEmailIconClick} sx={{fontSize: 13, ml: 1}}/>
                    </Span>
                </Typography>
            }
            headerSx={{
                borderBottom: 1, borderColor: 'divider'
            }}
            noWrapper>
            <List disablePadding sx={{mb: 2}}>
                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                        <EmailOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">{t('common.email')}</Typography>}
                        secondary={<Link variant="body1" href="#"
                                         underline="none">{values.email}</Link>}
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                        <InsertLinkOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">{t('teacher-profile.title.name')}</Typography>}
                        secondary={<Link variant="body1" href="#"
                                         underline="none">{values.firstName + ' ' + values.lastName}</Link>}
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                    <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                        <LocalPhoneOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">{t('teacher-profile.title.phone')}</Typography>}
                        secondary={<Typography variant="body1"
                                               color="text.primary">
                            +{values.phone?.code} {values.phone?.number}
                    </Typography>}
                    />
                </ListItem>
            </List>


            <Div>
                <Dialog open={openDialog}>
                    <DialogTitle>{t('teacher-profile.title.updateAddress')}</DialogTitle>
                    <DialogContent>
                        <AboutForm hide={setOpenDialog} location={globalState?.profile?.location}/>
                    </DialogContent>
                </Dialog>
            </Div>


        </JumboCardQuick>
    );
};

export default Contacts;
