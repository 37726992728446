import React, {useEffect} from 'react';
import {Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Switch, TextField,} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTranslation} from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import DDImageField from "../../../../component/DDImageField";
import {Formik} from "formik";
import commonServices from "../../../../services/common-services";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";


const Step1 = ({hideDialog, stepsData, handleNext, setStepsData}) => {
    const [isLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        name: '',
        title: '',
        duration:'',
        visible: false,
        active: false,
    });
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();


    const handleChange = (prop) => (event) => {

        if (prop === 'category') {
            setValues({...values, [prop]: event.target.value, subCategory: undefined});
        } else {
            setValues({...values, [prop]: event.target.value});
        }
    };

    const submitStep1 = async () => {
        setErrMsg('');

        const {name, title, duration,
        } = values;

        if (name && title && duration) {


            const data = {...stepsData, ...values};
            console.log("Values-------123", data);
            setStepsData(data);
            handleNext(2);


        } else {
            setErrMsg(t('errMsg.notFill'));
        }
    };


    useEffect(() => {


        if (stepsData?.name) {


            setValues({...stepsData});


        }


    }, [stepsData])

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},
            }}
            noValidate
            autoComplete="off"
        >
            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'},mt:1}}>
                <TextField
                    required
                    label={t("admin-quiz.title.planName")}
                    value={values.name}
                    onChange={handleChange('name')}
                />
            </FormControl>





            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}}}>
                <TextField
                    required
                    label={t("admin-quiz.title.quizTitle")}
                    value={values.title}
                    onChange={handleChange('title')}
                />
            </FormControl>

            <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}}}>
                <TextField
                    required
                    label={t("admin-quiz.title.duration")}
                    value={values.duration}
                    onChange={handleChange('duration')}
                />
            </FormControl>

            <FormControlLabel
                label={t("admin-quiz.title.visible")}
                labelPlacement="top"
                sx={{
                    display: "flex",
                    alignItems:'start',
                    width: { xs: '100%', sm: '100%', xl: '100%' },
                    ml:1,
                    mt: 2,
                }}
                control={
                    <Switch
                        edge="end"
                        checked={values.visible}
                        onChange={(event) => {
                            setValues({...values, visible: event.target.checked})
                        }}
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-bluetooth',
                        }}
                    />
                }
            />


            <FormControlLabel
                label={t("admin-quiz.title.active")}
                labelPlacement="top"
                sx={{
                    display: "flex",
                    alignItems:'start',
                    width: { xs: '100%', sm: '100%', xl: '100%' },
                    ml:1,
                    mt: 2,
                }}
                control={
                    <Switch
                        edge="end"
                        checked={values.active}
                        onChange={(event) => {
                            setValues({...values, active: event.target.checked})
                        }}
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-bluetooth',
                        }}
                    />
                }
            />




            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>

            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                    onClick={hideDialog}
                    sx={{mr: 1}}
                >
                    {t("common.cancelBtn")}
                </Button>
                <Box sx={{flex: '1 1 auto'}}/>
                <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep1}>
                    {t("common.nextBtn")}
                </LoadingButton>
            </Box>
        </Box>
    );
};

export default Step1;
