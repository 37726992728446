import React, {useEffect} from 'react';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,Box
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from '@mui/icons-material/Star';
import IconButton from "@mui/material/IconButton";
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import styled from "@emotion/styled";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Span from "@jumbo/shared/Span";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ContactDetail from "./ContactDetail";
import JumboGridItem from "@jumbo/components/JumboList/components/JumboGridItem";
import {useAppMutation} from "../../../services";
import adminServices from "../../../services/admin-services";
import DDImage from "../../../component/DDImage";
import {useQuizContext} from "./context";
import {useTranslation} from "react-i18next";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';


const Item = styled(Span)(({theme}) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1),
}));

const ProgramItem = ({program, view}) => {
    const {showDialog, hideDialog} = useJumboDialog();
    const {globalState, setGlobalState} = useQuizContext();
    const {mutate: deleteQuiz, isError, isSuccess, isLoading} = useAppMutation(adminServices.deleteQuiz);
    const {t} = useTranslation();

    useEffect(() => {

        if (isSuccess) {
            setGlobalState({...globalState, refreshQuizList: true});
            hideDialog(true);
        }

    }, [isSuccess])


    const showContactDetail = React.useCallback(() => {
        showDialog({
            content: <ContactDetail program={program} onClose={hideDialog}/>
        })
    }, [showDialog, program]);


    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'edit':
                const openAddEditProgram = true;
                const quizId = program.quizId;
                setGlobalState({...globalState, quizId, openAddEditProgram});

                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: t("admin-quiz.title.deleteTitle"),
                    content: t("admin-quiz.title.deleteContent"),
                    onYes: () => deleteQuiz(program.quizId),
                    onNo: hideDialog
                })
        }
    };

    const handleEditHorizClick = () => {
        handleItemAction({ action: 'edit' }); // This will open the menu or perform the default action.
    };

    const handleDeleteHorizClick = () => {
        handleItemAction({ action: 'delete' }); // This will open the menu or perform the default action.
    };

    if (view === "grid") {
        return (
            <JumboGridItem xs={12} lg={6}>
                <Card variant="outlined" elevation={0}>
                    <CardHeader
                        avatar={
                            program.images?.length > 0 ? (
                                    <DDImage path={program.images[0]} alt={program.name}/>) :
                                (<Avatar alt={program.name}/>)

                        }
                        action={
                            <React.Fragment>
                                <DeleteSweepIcon onClick={handleDeleteHorizClick} sx={{color: 'warning.main', verticalAlign: 'middle', mr: 1}}/>
                                <IconButton onClick={handleEditHorizClick}>
                                    <EditIcon/>
                                </IconButton>
                            </React.Fragment>
                        }
                        title={
                            <Typography
                                variant={"h6"}
                                color={"text.primary"}
                                mb={.25}
                            >
                                {program.name}
                            </Typography>}
                        subheader={
                            <Typography
                                variant={"body1"}
                                color={"text.secondary"}
                            >
                                {program.designation}
                            </Typography>}
                    />
                    <CardContent sx={{pt: 0}}>
                        <Divider sx={{mb: 2}}/>
                        <List disablePadding>
                            <ListItem sx={{p: theme => theme.spacing(.75, 1.5)}}>
                                <ListItemIcon sx={{minWidth: 50}}>
                                    <ViewStreamIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={program.title}
                                />
                            </ListItem>
                            <ListItem sx={{px: 1.5}}>
                                <ListItemIcon sx={{minWidth: 50}}>
                                    <AccessTimeIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={t("admin-quiz.title.duration")}
                                    secondary={program.duration}
                                />
                            </ListItem>
                            <ListItem sx={{px: 1.5}}>
                                <ListItemIcon sx={{minWidth: 50}}>

                                    {program.active ? (
                                        <>
                                            <CheckCircleIcon sx={{ color: 'green' }} />
                                        </>
                                    ) : (
                                        <>
                                            <CancelIcon sx={{ color: 'red' }} />
                                        </>
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                            <Typography variant="body2" color={program.active ? 'green' : 'red'}>
                                                {program.active ? 'Active' : 'Deactive'}
                                            </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </JumboGridItem>
        )
    }
    return (
        <React.Fragment>
            <JumboListItem
                componentElement={"div"}
                itemData={program}
                secondaryAction={
                    <JumboDdMenu
                        icon={<MoreHorizIcon/>}
                        menuItems={[
                            {icon: <EditIcon/>, title: t("admin-quiz.title.edit"), action: "edit"},
                            {icon: <DeleteIcon/>, title: t("admin-quiz.title.delete"), action: "delete"}
                        ]}
                        onClickCallback={handleItemAction}
                    />
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    }
                }}
            >

                <ListItemAvatar onClick={showContactDetail}>
                    {program.images?.length > 0 ? (
                            <DDImage path={program.images[0]} alt={program.name}/>) :
                        (<Avatar alt={program.name}/>)}
                </ListItemAvatar>
                <ListItemText
                    onClick={showContactDetail}
                    primary={
                        <Typography variant={"body1"} component={"div"}>
                            <Stack direction={"row"} alignItems={"center"} sx={{minWidth: 0}}>
                                <Item
                                    sx={{
                                        flexBasis: {xs: '100%', sm: '50%', md: '25%'}
                                    }}
                                >
                                    <Typography variant={"h5"} fontSize={14} lineHeight={1.25} mb={0}
                                                noWrap>{program.title}</Typography>
                                    <Typography
                                        variant={"body1"}
                                        noWrap
                                        color={'text.secondary'}
                                        sx={{
                                            display: {sm: 'none'}
                                        }}
                                    >
                                        {program.duration}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: {sm: '50%', md: '28%'},
                                        display: {xs: 'none', sm: 'block'}
                                    }}
                                >
                                    <Typography variant={"body1"} noWrap>{""}</Typography>
                                    <Typography variant={"body1"} noWrap>{""}</Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: {sm: '50%', md: '28%'},
                                        display: {xs: 'none', sm: 'block'}
                                    }}
                                >
                                    <Typography variant={"body1"} noWrap>{"Duration"}</Typography>
                                    <Typography variant={"body1"} noWrap>{program?.duration}</Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { md: '25%' },
                                        display: { xs: 'none', md: 'block' }
                                    }}
                                >{program.active ? (
                                        <>
                                            <Typography variant={"body1"} noWrap>{"Active"}</Typography>
                                        </>
                                ) : (
                                    <>
                                        <Typography variant={"body1"} noWrap>{"Deactive"}</Typography>
                                    </>
                                )}
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {program.active ? (
                                            <>
                                                <CheckCircleIcon sx={{ color: 'green', fontSize: 20, mr: 1 }} />
                                            </>
                                        ) : (
                                            <>
                                                <CancelIcon sx={{ color: 'red', fontSize: 20, mr: 1 }} />
                                            </>
                                        )}
                                    </Box>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: {md: '22%'},
                                        display: {xs: 'none', md: 'block'}
                                    }}
                                >
                                    <JumboChipsGroup
                                        chips={program.labels}
                                        mapKeys={{label: "name"}}
                                        spacing={1}
                                        size={"small"}
                                        max={1}
                                    />
                                </Item>
                            </Stack>
                        </Typography>
                    }
                />
            </JumboListItem>
        </React.Fragment>
    );
};
/* Todo program, view prop define */
export default ProgramItem;
