import React from "react";
import { Typography, Divider, Link } from "@mui/material";
import Div from "@jumbo/shared/Div";

const CGU = () => {
  return (
    <div
      style={{
        width: "100%", // Increased width to occupy more space
        maxWidth: "100%", // Maximum width of the content container
        margin: "0 auto", // Center the content horizontally
        padding: "20px",
        textAlign: "left", // Ensure the text is aligned to the left
        lineHeight: 1.6,
      }}
    >
      <InnerCGU />
    </div>
  );
};

const InnerCGU = () => {
  return (
    <div>
      <Div
        sx={{
          mb: 2, // Adjust margin as needed
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src="/images/logos/logo.png" alt="Logo" width="150" />
      </Div>
      {/* Title */}
      <Typography variant="h4" gutterBottom textAlign="center">
        Conditions générales d'utilisation
      </Typography>
      <Typography variant="h4" gutterBottom textAlign="center">
        du site DELIVERY Digital
      </Typography>

      {/* Preamble */}
      <Typography variant="h6" gutterBottom sx={{ mt: 3, fontWeight: "bold" }}>
        Préambule
      </Typography>
      <Typography paragraph>
        Les présentes conditions générales d'utilisation sont conclues entre :
      </Typography>
      <ul>
        <li>le gérant du site internet, ci-après désigné « l’Éditeur »,</li>
        <li>
          toute personne souhaitant accéder au site et à ses services, ci-après
          appelé « l’Utilisateur ».
        </li>
      </ul>

      {/* Article 1 */}
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Article 1 - Principes
      </Typography>
      <Typography paragraph>
        Les présentes conditions générales d'utilisation ont pour objet
        l'encadrement juridique de l'utilisation du site DELIVERY Digital et de
        ses services.
      </Typography>
      <Typography paragraph>
        Le site Internet{" "}
        <Link href="https://deliverydigital.fr">
          https://deliverydigital.fr
        </Link>{" "}
        est un service de :
      </Typography>
      <ul>
        <li>La société DELIVERY Digital Nice</li>
        <li>située 470 promenade des anglais Nice, France</li>
        <li>
          adresse URL du site :{" "}
          <Link href="https://deliverydigital.fr">
            https://deliverydigital.fr
          </Link>
        </li>
        <li>e-mail : Contact@deliverydigital.fr</li>
      </ul>
      <Typography paragraph>
        Les conditions générales d'utilisation doivent être acceptées par tout
        Utilisateur, et son accès au site vaut acceptation de ces conditions.
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      {/* Article 2 */}
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Article 2 - Evolution et durée des CGU
      </Typography>
      <Typography paragraph>
        Les présentes conditions générales d'utilisation sont conclues pour une
        durée indéterminée. Le contrat produit ses effets à l'égard de
        l'Utilisateur à compter du début de l'utilisation du service.
      </Typography>
      <Typography paragraph>
        Le site DELIVERY Digital se réserve le droit de modifier les clauses de
        ces conditions générales d’utilisation à tout moment et sans
        justification.
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      {/* Article 3 */}
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Article 3 - Accès au site
      </Typography>
      <Typography paragraph>
        Tout Utilisateur ayant accès à internet peut accéder gratuitement et
        depuis n’importe où au site DELIVERY Digital. Les frais supportés par
        l’Utilisateur pour y accéder (connexion internet, matériel informatique,
        etc.) ne sont pas à la charge de l’Éditeur.
      </Typography>
      <Typography paragraph>
        Le site et ses différents services peuvent être interrompus ou suspendus
        par l’Éditeur, notamment à l’occasion d’une maintenance, sans obligation
        de préavis ou de justification.
      </Typography>
      <Typography paragraph>
        L'Utilisateur du site a accès aux services suivants : Programmation
        informatique, Formation pour adultes, jeunes et étudiants dans le
        domaine du digital de la programmation informatique. Coaching et toute
        autre formation, vente de matériels éducatifs liés aux formations.
      </Typography>
      <Typography paragraph>
        Le site comprend un espace membre payant réservé aux utilisateurs
        inscrits. Ces utilisateurs pourront y accéder en utilisant leurs
        identifiants de connexion.
      </Typography>
      <Typography paragraph>
        Les services réservés aux membres sont les suivants : Formation
        continue.
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      {/* Article 4 */}
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Article 4 - Responsabilités
      </Typography>
      <Typography paragraph>
        La responsabilité de l'Éditeur ne peut être engagée en cas de
        défaillance, panne, difficulté ou interruption de fonctionnement,
        empêchant l’accès au site ou à une de ses fonctionnalités.
      </Typography>
      <Typography paragraph>
        Le matériel de connexion au site utilisé est sous l'entière
        responsabilité de l'Utilisateur qui doit prendre toutes les mesures
        appropriées pour protéger le matériel et les données notamment
        d'attaques virales par Internet. L'utilisateur est par ailleurs le seul
        responsable des sites et données qu'il consulte.
      </Typography>
      <Typography paragraph>
        L'Éditeur ne pourra être tenu responsable en cas de poursuites
        judiciaires à l'encontre de l'Utilisateur :
        <ul>
          <li>
            du fait de l'usage du site ou de tout service accessible via
            Internet ;
          </li>
          <li>
            du fait du non-respect par l'Utilisateur des présentes conditions
            générales.
          </li>
        </ul>
      </Typography>
      <Typography paragraph>
        L'Éditeur n'est pas responsable des dommages causés à l'Utilisateur, à
        des tiers et/ou à l'équipement de l'Utilisateur du fait de sa connexion
        ou de son utilisation du site et l'Utilisateur renonce à toute action
        contre l'Éditeur de ce fait.
      </Typography>
      <Typography paragraph>
        Si l'Éditeur venait à faire l'objet d'une procédure amiable ou
        judiciaire à raison de l'utilisation du site par l'Utilisateur, il
        pourra retourner contre lui pour obtenir indemnisation de tous les
        préjudices, sommes, condamnations et frais qui pourraient découler de
        cette procédure.
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      {/* Article 5 */}
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Article 5 - Propriété intellectuelle
      </Typography>
      <Typography paragraph>
        Tous les documents techniques, produits, photographies, textes, logos,
        dessins, vidéos, etc., sont soumis à des droits d'auteur et sont
        protégés par le Code de la propriété intellectuelle. Lorsqu'ils sont
        remis ou communiqués aux clients, ils demeurent la propriété exclusive
        de DELIVERY Digital Nice, seul titulaire des droits de propriété
        intellectuelle sur ces documents, qui doivent lui être rendus à sa
        demande.
      </Typography>
      <Typography paragraph>
        Nos clients s'engagent à ne faire aucun usage de ces documents,
        susceptible de porter atteinte aux droits de propriété industrielle ou
        intellectuelle du fournisseur et s'engagent à ne les divulguer à aucun
        tiers, en dehors d'une autorisation expresse et préalable donnée par
        l'Éditeur.
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      {/* Article 6 */}
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Article 6 - Liens hypertextes
      </Typography>
      <Typography paragraph>
        La mise en place par l’Utilisateur de tous liens hypertextes vers tout
        ou partie du site est strictement interdite, sauf autorisation préalable
        et écrite de l’Éditeur, sollicitée par courriel à l’adresse suivante :
        Contact@deliverydigital.fr.
      </Typography>
      <Typography paragraph>
        L’Éditeur est libre de refuser cette autorisation sans avoir à justifier
        de quelque manière que ce soit sa décision. Dans le cas où l’Éditeur
        accorderait son autorisation, celle-ci n’est dans tous les cas que
        temporaire et pourra être retirée à tout moment, sans obligation de
        justification à la charge de l’Éditeur.
      </Typography>
      <Typography paragraph>
        Dans tous les cas, tout lien devra être retiré sur simple demande de
        l’Éditeur.
      </Typography>
      <Typography paragraph>
        Toute information accessible via un lien vers d’autres sites n’est pas
        sous le contrôle de l’Éditeur qui décline toute responsabilité quant à
        leur contenu.
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      {/* Article 7 */}
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Article 7 - Protection des données personnelles
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", textDecorationLine: "underline" }}
      >
        Données collectées.
      </Typography>
      <Typography paragraph>
        Les données à caractère personnel qui sont collectées sur ce site sont
        les suivantes :
      </Typography>
      <ul>
        <li>
          Ouverture de compte : lors de la création du compte de l’utilisateur :
          nom, prénom, adresse électronique, n° de téléphone, adresse postale,
          date de naissance ;
        </li>
        <li>
          Connexion : lors de la connexion de l’utilisateur au site web,
          celui-ci enregistre, notamment, ses nom, prénom, données de connexion,
          d’utilisation, de localisation et ses données relatives au paiement ;
        </li>
        <li>
          Profil : l’utilisation des prestations prévues sur le site web permet
          de renseigner un profil, pouvant comprendre une adresse et un numéro
          de téléphone ;
        </li>
        <li>
          Paiement : dans le cadre du paiement des produits et prestations
          proposés sur le site web, celui-ci enregistre des données financières
          relatives au compte bancaire ou à la carte de crédit de l’utilisateur
          ;
        </li>
        <li>
          Cookies : les cookies sont utilisés, dans le cadre de l’utilisation du
          site. L’utilisateur a la possibilité de désactiver les cookies à
          partir des paramètres de son navigateur.
        </li>
      </ul>

      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", textDecorationLine: "underline" }}
      >
        Utilisation des données personnelles.
      </Typography>
      <Typography paragraph>
        Les données personnelles collectées auprès des utilisateurs ont pour
        objectif la mise à disposition des services du site web, leur
        amélioration et le maintien d’un environnement sécurisé. Plus
        précisément, les utilisations sont les suivantes :
      </Typography>
      <ul>
        <li>Accès et utilisation du site web par l’utilisateur ;</li>
        <li>Gestion du fonctionnement et optimisation du site web ;</li>
        <li>
          Organisation des conditions d’utilisation des services de paiement ;
        </li>
        <li>
          Vérification, identification et authentification des données
          transmises par l’utilisateur ;
        </li>
        <li>
          Proposition à l’utilisateur de la possibilité de communiquer avec
          d’autres utilisateurs du site web ;
        </li>
        <li>Mise en œuvre d’une assistance utilisateurs ;</li>
        <li>
          Personnalisation des services en affichant des publicités en fonction
          de l’historique de navigation de l’utilisateur, selon ses préférences
          ;
        </li>
        <li>
          Prévention et détection des fraudes, malwares (malicious software ou
          logiciels malveillants) et gestion des incidents de sécurité ;
        </li>
        <li>Gestion des éventuels litiges avec les utilisateurs ;</li>
        <li>
          Envoi d’informations commerciales et publicitaires, en fonction des
          préférences de l’utilisateur.
        </li>
      </ul>

      <Divider sx={{ marginY: 2 }} />

      {/* Partage des données section */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", textDecorationLine: "underline" }}
      >
        Partage des données personnelles avec des tiers
      </Typography>
      <Typography paragraph>
        Les données personnelles peuvent être partagées avec des sociétés
        tierces, dans les cas suivants :
      </Typography>
      <ul>
        <li>
          Lorsque l'Utilisateur utilise les services de paiement, pour la mise
          en œuvre de ces services, le site web est en relation avec des
          sociétés bancaires et financières tierces avec lesquelles elle a passé
          des contrats ;
        </li>
        <li>
          Lorsque l'Utilisateur publie, dans les zones de commentaires libres du
          site web, des informations accessibles au public ;
        </li>
        <li>
          Lorsque l'Utilisateur autorise le site web d'un tiers à accéder à ses
          données ;
        </li>
        <li>
          Lorsque le site web recourt aux services de prestataires pour fournir
          l'assistance utilisateurs, la publicité et les services de paiement.
          Ces prestataires disposent d'un accès limité aux données de
          l'utilisateur, dans le cadre de l'exécution de ces prestations, et ont
          une obligation contractuelle de les utiliser en conformité avec les
          dispositions de la réglementation applicable en matière de protection
          des données à caractère personnel ;
        </li>
        <li>
          Si la loi l'exige, le site web peut effectuer la transmission de
          données pour donner suite aux réclamations présentées contre le site
          web et se conformer aux procédures administratives et judiciaires ;
        </li>
        <li>
          Si le site web est impliqué dans une opération de fusion, acquisition,
          cession d'actifs ou procédure de redressement judiciaire, il pourra
          être amené à céder ou partager tout ou partie de ses actifs, y compris
          les données à caractère personnel. Dans ce cas, les utilisateurs
          seraient informés, avant que les données à caractère personnel ne
          soient transférées à une tierce partie.
        </li>
      </ul>

      <Divider sx={{ marginY: 2 }} />

      {/* Sécurité et confidentialité */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", textDecorationLine: "underline" }}
      >
        Sécurité et confidentialité
      </Typography>
      <Typography paragraph>
        Le site web met en œuvre des mesures organisationnelles, techniques,
        logicielles et physiques en matière de sécurité du numérique pour
        protéger les données personnelles contre les altérations, destructions
        et accès non autorisés. Toutefois, il est à signaler qu'internet n'est
        pas un environnement complètement sécurisé et le site web ne peut pas
        garantir la sécurité de la transmission ou du stockage des informations
        sur internet.
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      {/* Droits des utilisateurs */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", textDecorationLine: "underline" }}
      >
        Mise en œuvre des droits des utilisateurs
      </Typography>
      <Typography paragraph>
        En application de la réglementation applicable aux données à caractère
        personnel, les utilisateurs disposent des droits suivants, qu'ils
        peuvent exercer en faisant leur demande à l'adresse suivante :
        Contact@deliverydigital.fr.
      </Typography>
      <ul>
        <li>
          Le droit d’accès : Ils peuvent exercer leur droit d'accès, pour
          connaître les données personnelles les concernant. Dans ce cas, avant
          la mise en œuvre de ce droit, le site web peut demander une preuve de
          l'identité de l'utilisateur afin d'en vérifier l'exactitude.
        </li>
        <li>
          Le droit de rectification : Si les données à caractère personnel
          détenues par le site web sont inexactes, ils peuvent demander la mise
          à jour des informations.
        </li>
        <li>
          Le droit de suppression des données : Les utilisateurs peuvent
          demander la suppression de leurs données à caractère personnel,
          conformément aux lois applicables en matière de protection des
          données.
        </li>
        <li>
          Le droit à la limitation du traitement : Les utilisateurs peuvent
          demander au site web de limiter le traitement des données personnelles
          conformément aux hypothèses prévues par le RGPD.
        </li>
        <li>
          Le droit de s’opposer au traitement des données : Les utilisateurs
          peuvent s’opposer à ce que leurs données soient traitées conformément
          aux hypothèses prévues par le RGPD.
        </li>
        <li>
          Le droit à la portabilité : Ils peuvent réclamer que le site web leur
          remette les données personnelles qui lui sont fournies pour les
          transmettre à un nouveau site web.
        </li>
      </ul>

      <Divider sx={{ marginY: 2 }} />

      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", textDecorationLine: "underline" }}
      >
        Evolution de la présente clause
      </Typography>
      <Typography paragraph>
        Le site web se réserve le droit d'apporter toute modification à la
        présente clause relative à la protection des données à caractère
        personnel à tout moment. Si une modification est apportée à la présente
        clause de protection des données à caractère personnel, le site web
        s'engage à publier la nouvelle version sur son site. Le site web
        informera également les utilisateurs de la modification par messagerie
        électronique, dans un délai minimum de 15 jours avant la date d'effet.
        Si l'utilisateur n'est pas d'accord avec les termes de la nouvelle
        rédaction de la clause de protection des données à caractère personnel,
        il a la possibilité de supprimer son compte.
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      {/* Article 8: Cookies */}
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Article 8 - Cookies
      </Typography>
      <Typography paragraph>
        Le site DELIVERY Digital peut collecter automatiquement des informations
        standards. Toutes les informations collectées indirectement ne seront
        utilisées que pour suivre le volume, le type et la configuration du
        trafic utilisant ce site, pour en développer la conception et
        l'agencement et à d'autres fins administratives et de planification et
        plus généralement pour améliorer le service que nous vous offrons.
      </Typography>

      <Divider sx={{ marginY: 3 }} />

      {/* Article 9: Loi applicable */}
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Article 9 - Loi applicable
      </Typography>
      <Typography paragraph>
        Les présentes conditions générales d'utilisation sont soumises à
        l'application du droit français. Si les parties n'arrivent pas à
        résoudre un litige à l'amiable, le litige sera soumis à la compétence
        des tribunaux français.
      </Typography>
    </div>
  );
};

export default CGU;
