import React from 'react';
import FoldersList from "./FoldersList";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import {Button} from "@mui/material";
import {useQuizContext} from "./context";
import {useTranslation} from "react-i18next";

const ContactsAppSidebar = ({title, subheader}) => {
    const {globalState, setGlobalState} = useQuizContext();
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <Button
                fullWidth
                disableElevation
                variant={"contained"}
                startIcon={<PersonAddAltIcon/>}
                sx={{
                    mb: 2,
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.5rem'
                    }
                }}
                onClick={() => {
                    const openAddEditProgram = true;
                    setGlobalState({...globalState, openAddEditProgram});
                }}
            >{t("admin-quiz.title.addPlans")}</Button>
            { /* <FoldersList/> */}
        </React.Fragment>
    )

};
export default React.memo(ContactsAppSidebar);
