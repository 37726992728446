import React, { useEffect, useState } from "react";
import ProjectItem from "./ProjectItem";
import {
  Accordion,
  AccordionDetails,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  Skeleton,
} from "@mui/material";
import { useAppMutation } from "../../../../../../services";
import studentServices from "../../../../../../services/student-services";
import { useTranslation } from "react-i18next";
import AccordionSummary from "@mui/material/AccordionSummary";
import Div from "@jumbo/shared/Div";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DDImage from "../../../../../../component/DDImage";
import Avatar from "@mui/material/Avatar";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Chip from "@mui/material/Chip";
import { PictureAsPdf } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";

const ProjectsList = () => {
  const {
    mutate: loadCourses,
    isError,
    data,
    isSuccess,
    isLoading,
  } = useAppMutation(studentServices.loadPaidCourses);

  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    if (!loaded) {
      loadCourses({ size: 100 });
      setLoaded(true);
    }
  }, [loaded]);

  useEffect(() => {
    if (isSuccess) {
      setCourses(data);
    }
  }, [isSuccess, data]);

  const handleEnrollButton = () => {
    navigate("/dashboard/courses");
  };

  return (
    <React.Fragment>
      <Typography variant={"h2"} mb={3}>
        {t("student.title.myCourses")}
      </Typography>

      {isLoading ? (
        // Skeleton loader while data is loading
        <>
          <Skeleton
            variant="rectangular"
            height={80}
            sx={{ mb: 2, borderRadius: 4 }}
          />
          <Skeleton
            variant="rectangular"
            height={80}
            sx={{ mb: 2, borderRadius: 4 }}
          />
          <Skeleton
            variant="rectangular"
            height={80}
            sx={{ mb: 2, borderRadius: 4 }}
          />
          <Skeleton
            variant="rectangular"
            height={80}
            sx={{ mb: 2, borderRadius: 4 }}
          />
          <Skeleton
            variant="rectangular"
            height={80}
            sx={{ mb: 2, borderRadius: 4 }}
          />
        </>
      ) : courses && courses.length > 0 ? (
        courses.map((course, index) => (
          <ProjectItem key={index} item={course} data={data} />
        ))
      ) : (
        <Card sx={{ mb: 1 }}>
          <Accordion square sx={{ borderRadius: 2 }}>
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                px: 3,
                flexDirection: "row-reverse",

                "& .MuiAccordionSummary-content": {
                  alignItems: "center",

                  "&.Mui-expanded": {
                    margin: "12px 0",
                  },
                },
                ".MuiAccordionSummary-expandIconWrapper": {
                  borderRadius: 1,
                  border: 1,
                  color: "text.secondary",
                  borderColor: "divider",
                  transform: "none",
                  height: 28,
                  width: 28,
                  alignItems: "center",
                  justifyContent: "center",
                  mr: 1,

                  "&.Mui-expanded": {
                    transform: "none",
                    color: "primary.main",
                    borderColor: "primary.main",
                  },

                  "& svg": {
                    fontSize: "1.25rem",
                  },
                },
              }}
            >
              <Div
                sx={{
                  width: { xs: "auto", lg: "26%" },
                  flexShrink: 0,
                  px: 1,
                  flex: { xs: "1", lg: "0 1 auto" },
                }}
              >
                <Typography variant={"h5"} mb={0.5} mt={1} fontSize={14}>
                  {t("student.title.noCourse")}
                </Typography>
              </Div>

              <Div
                sx={{
                  flex: { xs: "0 1 auto", lg: 1 },
                  flexShrink: 0,
                  px: 1,
                  textAlign: "center",
                }}
              >
                <JumboChipsGroup
                  chips={[{ label: t("student.title.enrollNowBtn") }]}
                  spacing={1}
                  size={"small"}
                  defaultColor={"#146ae4"}
                  onClick={handleEnrollButton}
                />
              </Div>
            </AccordionSummary>
          </Accordion>
        </Card>
      )}
    </React.Fragment>
  );
};

export default ProjectsList;
