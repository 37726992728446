import {createContext, useContext, useState} from 'react';

const TransactionsContext = createContext({});

export const useTransactionsContext = () => {
    return useContext(TransactionsContext);
}

export const TransactionsProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({ refreshTransactionsList : false});

    return <TransactionsContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </TransactionsContext.Provider>
}
