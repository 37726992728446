import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PricePlan from "./PricePlan";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import {useAppMutation} from "../../../../services";
import parentServices from "../../../../services/parent-services";
import Index from "./Payment";
import Div from "@jumbo/shared/Div";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Box} from "@mui/material";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Div sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Div>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PricingPlan = () => {
    const {t} = useTranslation();
    const [selectedPlan, setSelectedPlan] = useState({});
    const [planType, setPlanType] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [packs, setPacks] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const {mutate: loadPlans, isError, data, isSuccess, isLoading} = useAppMutation(parentServices.loadPlans);


    React.useEffect(() => {

        if (!loaded) {
            loadPlans({size: 100});
            setLoaded(true)
        }

    }, [loaded])

    React.useEffect(() => {

        if (isSuccess) {
            const packs = data.plans.filter(item => item.category === 'packs');
            const subscriptions = data.plans.filter(item => item.category === 'subscription');
            setPacks(packs);
            setSubscriptions(subscriptions)
        }

    }, [isSuccess, data])


    const hidePayment = (cancel) => {
        setSelectedPlan({});
    }

    const showPayment = ({plan}) => {
        console.log(plan);
        setSelectedPlan(plan)
    }

    const pricingPlans = {
        commitment: {
            basic: {
                title: "$16",
                subheader: 'Basic Pack',
                headerSx: {
                    textAlign: 'center',
                    backgroundColor: 'black',
                }
            },
            smart: {
                title: "$289",
                subheader: 'Smart Pack',
            },
            essential: {
                title: "$399",
                subheader: 'Essential Pack',
                headerSx: {
                    textAlign: 'center',
                    bgcolor: '#454545',
                }
            }
        },

        noCommitment: {
            basic: {
                title: "$189",
                subheader: 'Basic Pack',
                headerSx: {
                    textAlign: 'center',
                    bgcolor: 'primary.main',
                }
            },
            smart: {
                title: "$300",
                subheader: 'Smart Pack',

            },
            essential: {
                title: "$450",
                subheader: 'Essential Pack',
                headerSx: {
                    textAlign: 'center',
                    bgcolor: '#454545',
                }
            }
        }
    };

    return (
        <React.Fragment>

            <Div sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={planType} onChange={(event, newValue) => {
                    setPlanType(newValue);
                }}>
                    <Tab label={t("parent-subscription.title.sub")} {...a11yProps(0)} />
                    <Tab label={t("parent-subscription.title.noSub")} {...a11yProps(1)} />
                </Tabs>
            </Div>
            <TabPanel value={planType} index={0}>

                <Box sx={{margin: {
                        xs: -4,
                        sm: -3,
                        md: -2,
                        lg: -2,
                        xl: -1
                    }, boxSizing: 'border-box'}}>
                <Grid container spacing={2.8} sx={{marginTop: -1, marginBottom: 2, paddingX: -3}}>
                    {subscriptions && subscriptions.map((item, index) => {
                        // const isMiddleItem = index === Math.floor(subscriptions.length / 2);
                        const isMiddleItem = index === 1;
                        const middleItemStyle = {
                            position: 'relative',
                            zIndex: 1,
                            transition: 'transform 0.6s',
                            transform: 'scale(1)',
                        };
                        return <Grid key={'key-commit-' + index} item xs={12} md={6} lg={4} style={middleItemStyle}>

                            <PricePlan
                                recommended={isMiddleItem}
                                plan={item}
                                headerSx={pricingPlans['commitment'].essential.headerSx}
                            >
                                <div dangerouslySetInnerHTML={{__html: item.descriptionHTML}}/>
                                <Button onClick={() => {
                                    showPayment({plan: item})
                                }} variant={"contained"} disableElevation
                                        sx={{
                                            mb: 2,
                                            color: "black",
                                            fontWeight: '32px'
                                        }}>{t("parent-subscription.title.buyBtn")}</Button>
                            </PricePlan>
                        </Grid>
                    })}
                </Grid>
                </Box>
            </TabPanel>
            <TabPanel value={planType} index={1}>
                <Box sx={{margin: {
                        xs: -4,
                        sm: -3,
                        md: -2,
                        lg: -2,
                        xl: -1
                    }, boxSizing: 'border-box'}}>
                <Grid container spacing={2.8} sx={{marginTop: -1, marginBottom: 2, paddingX: -3}}>
                    {packs && packs.map((item, index) => {
                        // const isMiddleItem = index === Math.floor(packs.length / 2);
                        const isMiddleItem = index === 1;
                        const middleItemStyle = {
                            position: 'relative',
                            zIndex: 1,
                            transition: 'transform 0.6s',
                            transform: 'scale(1)',
                        };
                        return <Grid key={'key-no-sub-' + index} item xs={12} md={6} lg={4} style={middleItemStyle}>

                            <PricePlan
                                plan={item}
                                recommended={isMiddleItem}
                                headerSx={pricingPlans['commitment'].essential.headerSx}
                            >
                                <div dangerouslySetInnerHTML={{__html: item.descriptionHTML}}/>
                                <Button onClick={() => {
                                    showPayment({plan: item})
                                }} variant={"contained"} disableElevation
                                        sx={{
                                            mb: 2,
                                            color: "black",
                                            fontWeight: '32px'
                                        }}>{t("parent-subscription.title.buyBtn")}</Button> </PricePlan>
                        </Grid>
                    })}
                </Grid>
                </Box>
            </TabPanel>
            {selectedPlan && <Index plan={selectedPlan} hide={hidePayment}/>}
        </React.Fragment>
    )
        ;
};

export default PricingPlan;
