import React from "react";
import Page from "@jumbo/shared/Page";
import Children from "../pages/student/Children";
import Programs from "../pages/student/Programs";
import Dashboard from "../pages/student/Dashboard";
import Subscription from "../pages/student/Subscription";
import Profile from "../pages/student/Profile"
import Appointments from "../pages/student/Appointments";
import Bookings from "../pages/student/Bookings";
import Courses from "../pages/student/Courses/ProjectsList";


const studentroutes = [
    {
        path: ["/dashboard", "/dashboard/:status/:message"],
        element: <Page component={Dashboard}/>,
    },
    {
        path: [
            "/dashboard/children",
            "/dashboard/children/:category",
            "/dashboard/children/:category/:id",
        ],
        element: <Page component={Children}/>,
    },
    {
        path: [
            "/dashboard/appointments",
            "/dashboard/appointments/:category",
            "/dashboard/appointments/:category/:id",
        ],
        element: <Page component={Appointments}/>,
    },
    {
        path: [
            "/dashboard/bookings"
        ],
        element: <Page component={Bookings}/>,
    },
    {
        path: "/dashboard/programs",
        element: <Page component={Programs}/>,
    },
    {
        path: "/dashboard/courses",
        element: <Page component={Courses}/>,
    },
    {
        path: ["/dashboard/subscription",
            "/dashboard/subscription/:category"],
        element: <Page component={Subscription}/>,
    },
    {
        path: "/dashboard/profile",
        element: <Page component={Profile}/>,
    },
];

export default studentroutes;
