import React, {useState} from 'react';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import styled from "@emotion/styled";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Span from "@jumbo/shared/Span";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import TeacherDetail from "./TeacherDetail";
import JumboGridItem from "@jumbo/components/JumboList/components/JumboGridItem";
import DDImage from "../../../component/DDImage";
import {useAppMutation} from "../../../services";
import services from "../../../services/admin-services";
import PhoneIcon from "@mui/icons-material/Phone";
import {useTeacherContext} from "./context";
import {useTranslation} from "react-i18next";
import moment from "moment";

const Item = styled(Span)(({theme}) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1),
}));

const ContactItem = ({teacher, view}) => {
    const {showDialog, hideDialog} = useJumboDialog();
    const {globalState, setGlobalState} = useTeacherContext();
    const [contactListRefresh, setContactsListRefresh] = useState();
    const [favorite, setFavorite] = React.useState(teacher?.starred);
    const {t} = useTranslation();
    const {
        mutate: setTeacherEvaluation,
        isSuccess: isSuccess,
        data: result
    } = useAppMutation(services.setTeacherEvaluation);

    const deleteContactMutation = async () => {
        return null
    }

    React.useEffect(() => {

        if (isSuccess) {
            setContactsListRefresh(true);
        }

    }, [isSuccess, result])

    const hideDialogAndRefreshContactsList = React.useCallback(() => {
        hideDialog();
        setContactsListRefresh(true);
    }, [hideDialog, setContactsListRefresh]);


    const showContactDetail = React.useCallback(() => {
        showDialog({
            content: <TeacherDetail teacher={teacher} onClose={hideDialog}/>
        })
    }, [showDialog, teacher]);


    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'edit':

                setGlobalState({...globalState, teacher, openAddEdit: true})

                break;
            {/*case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: 'Are you sure about deleting this contact?',
                    content: "You won't be able to recover this contact later",
                    onYes: () => deleteContactMutation.mutate(contact),
                    onNo: hideDialog
                })*/
            }
        }
    };

    if (view === "grid") {
        return (
            <JumboGridItem xs={12} lg={6}>
                <Card variant="outlined" elevation={0}>
                    <CardHeader
                        avatar={teacher.images?.length > 0 ? (
                                <DDImage path={teacher.images[0]} alt={teacher.firstName + ' ' + teacher.lastName}/>) :
                            (<Avatar alt={teacher.firstName + ' ' + teacher.lastName}/>)
                        }
                        action={
                            <React.Fragment>
                                <JumboDdMenu
                                    icon={<MoreHorizIcon/>}
                                    menuItems={[
                                        {icon: <EditIcon/>, title: "Edit", action: "edit"}
                                    ]}
                                    onClickCallback={handleItemAction}
                                />
                            </React.Fragment>
                        }
                        title={
                            <Typography
                                variant={"h6"}
                                color={"text.primary"}
                                mb={.25}
                            >
                                {teacher.firstName} {teacher.lastName}
                            </Typography>}
                        subheader={
                            <Typography
                                variant={"body1"}
                                color={"text.secondary"}
                            >
                                {teacher.designation}
                            </Typography>}
                    />
                    <CardContent sx={{pt: 0}}>
                        <Divider sx={{mb: 2}}/>
                        <List disablePadding>

                            <ListItem sx={{px: 1.5}}>
                                <ListItemIcon sx={{minWidth: 50}}>
                                    <AlternateEmailIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={teacher.email}
                                />
                            </ListItem>
                            <ListItem sx={{px: 1.5}}>
                                <ListItemIcon sx={{minWidth: 50}}>
                                    <PhoneIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={teacher?.phone?.fullNumber}
                                />
                            </ListItem>


                        </List>
                        <Divider sx={{my: 2}}/>

                        <Stack direction={"row"} alignItems={"center"} sx={{minWidth: 0}}>
                            <Item>
                                {teacher?.approved ? (
                                    <JumboChipsGroup
                                        chips={[{label: t('calendar.approved')}]}
                                        mapKeys={{label: "name"}}
                                        spacing={1}
                                        size={"small"}
                                        defaultColor={"#008000"}
                                    />
                                ) : (
                                    <JumboChipsGroup
                                        chips={[{label: t('calendar.notApproved')}]}
                                        mapKeys={{label: "name"}}
                                        spacing={1}
                                        size={"small"}
                                        defaultColor={"#FF0000"}
                                    />
                                )
                                }
                            </Item>


                            <Item>
                                {teacher?.evaluator &&
                                    <JumboChipsGroup
                                        chips={[{label: t('calendar.evaluator')}]}
                                        mapKeys={{label: "name"}}
                                        spacing={1}
                                        size={"small"}
                                        defaultColor={"#146ae4"}
                                    />
                                }
                            </Item>


                        </Stack>
                    </CardContent>
                </Card>
            </JumboGridItem>
        )
    }
    return (
        <React.Fragment>
            <JumboListItem
                componentElement={"div"}
                itemData={teacher}
                secondaryAction={
                    <JumboDdMenu
                        icon={<MoreHorizIcon/>}
                        menuItems={[
                            {icon: <EditIcon/>, title: t("common.edit"), action: "edit"},
                            {/*{icon: <DeleteIcon/>, title: "Delete", action: "delete"}*/}
                        ]}
                        onClickCallback={handleItemAction}
                    />
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    }
                }}
            >

                <ListItemAvatar onClick={showContactDetail}>
                    {teacher.images?.length > 0 ? (
                            <DDImage path={teacher.images[0]} alt={teacher.firstName + ' ' + teacher.lastName}/>) :
                        (<Avatar alt={teacher.firstName + ' ' + teacher.lastName}/>)}
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography variant={"body1"} component={"div"}>
                            <Stack direction={"row"} alignItems={"center"} sx={{minWidth: 0}}>
                                <Item
                                    sx={{
                                        flexBasis: {xs: '100%', sm: '50%', md: '25%'}
                                    }}
                                >
                                    <Typography variant={"h5"} fontSize={14} lineHeight={1.25} mb={0}
                                                noWrap>{teacher.firstName} {teacher.lastName}</Typography>
                                    <Typography
                                        variant={"body1"}
                                        noWrap
                                        color={'text.secondary'}

                                    >
                                        {moment(teacher.createdAt).format('DD/MM/YYYY, hh:mm A')}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: {sm: '50%', md: '28%'},
                                        display: {xs: 'none', sm: 'block'}
                                    }}
                                >
                                    <Typography variant={"body1"} noWrap>{teacher.email}</Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: {md: '25%'},
                                        display: {xs: 'none', md: 'block'}
                                    }}
                                >
                                    <Typography variant={"body1"} noWrap>{teacher?.phone?.fullNumber}</Typography>
                                </Item>

                                <Item
                                    sx={{
                                        flexBasis: {md: '12%'},
                                        display: {xs: 'none', md: 'block'}
                                    }}
                                >
                                    {teacher?.approved ? (
                                        <JumboChipsGroup
                                            chips={[{label: t('calendar.approved')}]}
                                            mapKeys={{label: "name"}}
                                            spacing={1}
                                            size={"small"}
                                            defaultColor={"#008000"}
                                        />
                                    ) : (
                                        <JumboChipsGroup
                                            chips={[{label: t('calendar.notApproved')}]}
                                            mapKeys={{label: "name"}}
                                            spacing={1}
                                            size={"small"}
                                            defaultColor={"#FF0000"}
                                        />
                                    )
                                    }
                                </Item>


                                <Item
                                    sx={{
                                        flexBasis: {md: '15%'},
                                        display: {xs: 'none', md: 'block'}
                                    }}
                                >
                                    {teacher?.evaluator &&
                                        <JumboChipsGroup
                                            chips={[{label: t('calendar.evaluator')}]}
                                            mapKeys={{label: "name"}}
                                            spacing={1}
                                            size={"small"}
                                            defaultColor={"#146ae4"}
                                        />
                                    }
                                </Item>


                            </Stack>
                        </Typography>
                    }
                />
            </JumboListItem>
        </React.Fragment>
    );
};
/* Todo contact, view prop define */
export default ContactItem;
