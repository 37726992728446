import React, {useState} from 'react';
import {Avatar,} from "@mui/material";
import commonServices from "../../services/common-services";
import {useSelector} from "react-redux";


const DDImage = (props) => {
        const {
            isParent,
            isSuperAdmin,
            isTeacher,
            isStudent,
            authUser
        } = useSelector(state => state.auth);

        const [base64, setBase64] = useState('');


        React.useEffect(async () => {


                if (props.path) {
                    let fullUrl = '';

                    if (isParent) {
                        fullUrl = 'parent' + props.path;
                    } else if (isTeacher) {
                        fullUrl = 'teacher' + props.path;

                    } else if (isStudent) {
                        fullUrl = 'student' + props.path;
                        console.log("url---",fullUrl)

                    } else if (isSuperAdmin) {
                        fullUrl = 'admin' + props.path;

                    }


                    const result = await commonServices.loadImage(fullUrl);
                    setBase64(result.base64)
                }


            }, [props]
        )


        return <Avatar src={base64} {...props}/>
    }
;
/* Todo child, view prop define */
export default DDImage;
