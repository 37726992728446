import React from "react";
import { Navigate } from "react-router-dom";
import adminRoutes from "./adminroutes";
import studentRoutes from "./studentroutes";
import teacherRoutes from "./teacherroutes";
import AdminLogin from "../pages/admin/AdminLogin";
import Page from "@jumbo/shared/Page";
import authServices from "../services/auth-services";
import ParentLogin from "../pages/parent/ParentLogin";
import ParentSignup from "../pages/parent/ParentSignup";
import ParentForgotPass from "../pages/parent/ParentForgotPass";
import TeacherLogin from "../pages/teacher/TeacherLogin";
import TeacherSignup from "../pages/teacher/TeacherSignup";
import TeacherForgotPass from "../pages/teacher/TeacherForgotPass";
import AdminForgotPass from "../pages/admin/AdminForgotPass";
import StudentLogin from "../pages/student/StudentLogin";
import StudentSignup from "../pages/student/StudentSignup";
import CGU from "../pages/student/StudentSignup/CGU";
import Policy from "../pages/student/StudentSignup/Policy";
import StudentForgotPass from "../pages/student/StudentForgotPass";
import ParentCompletePayment from "../pages/student/Courses/ParentCompletePayment";
import PaymentStatus from "../pages/student/Courses/PaymentStatus";

const isSuperAdmin = authServices.isSuperAdmin();
const isParent = authServices.isParent();
const isStudent = authServices.isStudent();
const isTeacher = authServices.isTeacher();

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [];

/**
 routes only accessible to authenticated users
 **/
let routesForAuthenticatedOnly = [
  {
    path: "/",
    element: <Navigate to={"/student/login"} />,
  },
];

if (isSuperAdmin) {
  routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <Navigate to={"/dashboard/calendar"} />,
    },
    ...adminRoutes,
  ];
} else if (isStudent) {
  routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <Navigate to={"/dashboard"} />,
    },
    ...studentRoutes,
  ];
} else if (isTeacher) {
  routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <Navigate to={"/dashboard/calendar"} />,
    },
    ...teacherRoutes,
  ];
}

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  {
    path: "/admin/login",
    element: (
      <Page component={AdminLogin} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
  {
    path: "/parent/login",
    element: (
      <Page
        component={ParentLogin}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "/student/login",
    element: (
      <Page
        component={StudentLogin}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "/teacher/login",
    element: (
      <Page
        component={TeacherLogin}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "/parent/signup",
    element: (
      <Page
        component={ParentSignup}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "/parent/signup/success",
    element: (
      <Page
        component={ParentSignup}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "/student/signup",
    element: (
      <Page
        component={StudentSignup}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "/cgu",
    element: (
      <Page component={CGU} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
  {
    path: "/policy",
    element: (
      <Page component={Policy} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
  {
    path: "/student/signup/success",
    element: (
      <Page
        component={StudentSignup}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "/teacher/signup",
    element: (
      <Page
        component={TeacherSignup}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "/parent/forgotPassword",
    element: (
      <Page
        component={ParentForgotPass}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "/student/forgotPassword",
    element: (
      <Page
        component={StudentForgotPass}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "/teacher/forgotPassword",
    element: (
      <Page
        component={TeacherForgotPass}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "/admin/forgotPassword",
    element: (
      <Page
        component={AdminForgotPass}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "/student/complete/payment",
    element: (
      <Page
        component={ParentCompletePayment}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: ["/student/payment/:status"],
    element: (
      <Page
        component={PaymentStatus}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
