import React, {useEffect} from 'react';
import {
    Alert, Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl, IconButton, InputAdornment, InputLabel,
    ListItem,
    ListItemIcon,
    ListItemText, OutlinedInput, TextField,
    Typography
} from "@mui/material";
import {Switch} from "@mui/material";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import styled from "@mui/material/styles/styled";
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import List from "@mui/material/List";
import Div from "@jumbo/shared/Div";
import LoadingButton from "@mui/lab/LoadingButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import {useAppMutation} from "../../../../services";
import parentServices from "../../../../services/parent-services";
import studentServices from "../../../../services/student-services";
import {useTranslation} from "react-i18next";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {mainTheme as mainThemeDark} from "../../../../themes/main/dark";
import {headerTheme as headerThemeDark} from "../../../../themes/header/dark";
import {footerTheme as footerThemeDark} from "../../../../themes/footer/dark";
import {sidebarTheme as sidebarThemeDark} from "../../../../themes/sidebar/dark";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import useJumboFooterTheme from "@jumbo/hooks/useJumboFooterTheme";
import {mainTheme as mainThemeDefault} from "../../../../themes/main/default";
import {headerTheme as headerThemeDefault} from "../../../../themes/header/default";
import {footerTheme as footerThemeDefault} from "../../../../themes/footer/default";
import {sidebarTheme as sidebarThemeDefault} from "../../../../themes/sidebar/default";
import NewsLetter from "../../../parent/Profile/components/NewsLetter";
import {useProfileContext} from "../context";
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment-timezone';

const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));

const Settings = () => {

    const [openDialog, setOpenDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    const [successMsg, setSucessMsg] = React.useState('');
    const {mutate: resetPassword, error, isError, isLoading, data: result, isSuccess} = useAppMutation(studentServices.resetPassword);
    const {t} = useTranslation();
    const {globalState, setGlobalState} = useProfileContext();

    const {theme, setTheme} = useJumboTheme();
    const {setSidebarTheme} = useJumboSidebarTheme();
    const {setHeaderTheme} = useJumboHeaderTheme();
    const {setFooterTheme} = useJumboFooterTheme();

    const handleModeChange = React.useCallback(async (event) => {
        if(event.target.checked) {
            await setTheme({mode: "dark", ...mainThemeDark});
            setHeaderTheme({...theme, ...headerThemeDark});
            setSidebarTheme({...theme, ...sidebarThemeDark});
            setFooterTheme({...theme, ...footerThemeDark});
            return;
        }
        await setTheme({mode: "light", ...mainThemeDefault});
        setHeaderTheme({...theme, ...headerThemeDefault});
        setSidebarTheme({...theme, ...sidebarThemeDefault});
        setFooterTheme({...theme, ...footerThemeDefault});
    }, []);

    useEffect(() => {
        if (result && isSuccess) {
            console.log("Reset----",result, isSuccess)
        }
        if (isError && error) {
            setErrMsg(t('common.signInErrorMsg'))
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        }
        if(isSuccess)
        {
            setSucessMsg(t('parent-profile.title.passwordReset'))
            setTimeout(() => {
                setSucessMsg("");
                setOpenDialog(false);
            }, 3000);
        }
        console.log('Reset-----------------------', isError, error, isSuccess, result)
    }, [result, isSuccess, isError])

    React.useEffect(() => {
        if (globalState.profile) {
            setValues({...globalState.profile})
            console.log("Settings-Profile----------",globalState);
        }
    }, [globalState])

    const handleEmailIconClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [values, setValues] = React.useState({
        password: '',
        newPassword: '',
        showPassword: false,
        showNewPassword: false,

    });

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleResetPassword = () => {
        if (!values.password){
            setErrMsg(t('parent-profile.title.currentPasswordMsg'))
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else if (!values.newPassword) {
            setErrMsg(t('parent-profile.title.newPasswordMsg'))
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else if (values.password === values.newPassword) {
            setErrMsg(t('parent-profile.title.differentPasswordMsg'))
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else if (values.newPassword.length < 8) {
            setErrMsg(t('parent-profile.title.validPasswordMsg'))
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else if (!/(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])/.test(values.newPassword)) {
            setErrMsg(t('parent-profile.title.validationMsg'))
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
        } else {
            console.log("Reset Password------------", values);
            resetPassword(values);
            setValues({
                password: '',
                newPassword: '',
                showPassword: false,
                showNewPassword: false,
            });
        }
    };


    const [checked, setChecked] = React.useState(['wifi']);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleClickShowNewPassword = () => {
        setValues({
            ...values,
            showNewPassword: !values.showNewPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <JumboCardQuick
            title={
                <Typography variant={"h4"} mb={0}>{(t('parent-profile.title.settings'))}</Typography>
            }
            wrapperSx={{pt: 0}}
        >

            <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, -2),
                }}
            >
                <ListItem>
                    <ListItemIcon>
                        <LockResetOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-wifi" primary={(t('common.resetPassword'))}/>
                    <Typography
                        variant="body1"
                        href="#"
                        onClick={handleEmailIconClick}
                        sx={{
                            fontSize: 15,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        underline="none"
                    >{(t('parent-profile.title.reset'))}
                    </Typography>
                </ListItem>

                {false && <ListItem>
                    <ListItemIcon>
                        <FeedOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-bluetooth" primary={(t('parent-profile.title.newsLetter'))}/>
                    <NewsLetter/>
                </ListItem>}

                {false && <ListItem sx={{display: 'flex', alignItems: 'center'}}>
                    <div style={{marginRight: '10px'}}>
                        <AccessTimeFilledOutlinedIcon/>
                    </div>
                    <Autocomplete
                        id="combo-box-demo"
                        getOptionLabel={(option) => option.title}
                        // options={allTimeZones}
                        sx={{width: '100%'}}
                        renderInput={(params) => <TextField {...params} label={t('common.timeZone')}/>}
                        disabled={true}
                    />
                </ListItem>}
            </List>

            <Div>
                <Dialog fullWidth open={openDialog}>
                    <DialogTitle>{(t('common.resetPassword'))}</DialogTitle>
                    <DialogContent>

                        <FormControl fullWidth noValidate autoComplete="off">
                            <Div
                            >
                                <Div sx={{mt: 1, mb: 2}}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel
                                            htmlFor="outlined-adornment-password">{(t('common.password'))}</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={handleChange('password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={(t('common.password'))}
                                            required
                                        />
                                    </FormControl>
                                </Div>

                                <Div sx={{mt: 1, mb: 2, width:"100%"}}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password">{(t('parent-profile.title.newPassword'))}</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showNewPassword ? 'text' : 'password'}
                                            value={values.newPassword}
                                            onChange={handleChange('newPassword')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showNewPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label={(t('parent-profile.title.newPassword'))}
                                            placeholder="Example@123"
                                            required
                                        />
                                    </FormControl>
                                </Div>

                                <Stack
                                    sx={{
                                        mb: 2,
                                        width: '90%',
                                        mx: 'auto',
                                        display: 'block',
                                        position: 'relative', // Add position relative to enable absolute positioning of loader
                                    }}
                                    spacing={2}>
                                    {errMsg && <Alert severity="error">{errMsg}</Alert>}
                                    {successMsg && <Alert severity="success">{successMsg}</Alert>}
                                </Stack>


                                <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                    <Button
                                        sx={{mr: 1,width:"30%"}}
                                        onClick={() => setOpenDialog(false)}
                                    >
                                        {(t('common.closeBtn'))}
                                    </Button>
                                    <Div sx={{flex: '1 1 auto'}}/>


                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        loading={isLoading}
                                        onClick={handleResetPassword}
                                        sx={{width:"30%"}}
                                    >
                                        {(t('parent-profile.title.reset'))}
                                    </LoadingButton>
                                </Div>


                            </Div>
                        </FormControl>
                    </DialogContent>
                </Dialog>
            </Div>

        </JumboCardQuick>
    );
};

export default Settings;
