import Div from "@jumbo/shared/Div";
import {
  Alert,
  Avatar,
  Button,
  ButtonGroup,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemAvatar,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import Select from "@mui/material/Select";
import DDImage from "../../../../component/DDImage";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment/moment";
import React, { useState } from "react";
import { useAppMutation } from "../../../../services";
import studentServices from "../../../../services/student-services";
import { timings } from "../../../../utils/constants/appActions";
import { useBookingContext } from "../context";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";

const EditBookingForm = ({ setShowTeacherFeedBack }) => {
  const [type] = React.useState("courses");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation();
  const [pastDate, setPastDate] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const { globalState, setGlobalState } = useBookingContext();
  const [children, setChildren] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [availableDates, setAvailableDates] = useState([]);
  const [sTimings, setSTimings] = useState([]);
  const [transaction, setTransaction] = React.useState({});
  const [transactions, setTransactions] = React.useState([]);
  const [cancellationConfirmed, setCancellationConfirmed] = useState(false);
  const [error, setError] = useState("");
  const [values, setValues] = React.useState({
    startDate: "",
    startTime: "",
    title: "Student Booking",
    description: "",
    transactionId: "",
    teacherId: "",
    childId: "",
    studentid: "",
    meetingLink: "",
  });

  const {
    mutate: loadAvailableDates,
    isSuccess: x,
    isLoading: isLDLoading,
    isError: isLDError,
    data: ldResult,
  } = useAppMutation(studentServices.loadAvailableDates);

  const {
    mutate: loadActiveTransactions,
    isSuccess: isLASSuccess,
    data: lasData,
  } = useAppMutation(studentServices.loadCoursesForBooking);

  const {
    mutate: cancelBooking,
    isSuccess: isCBSuccess,
    isLoading: isCBLoading,
    isError: isCBError,
    data: cbResult,
  } = useAppMutation(studentServices.cancelBooking);

  const {
    mutate: loadAvailableTimes,
    isSuccess: isLTSuccess,
    isLoading: isLTLoading,
    isError: isLTError,
    data: ltResult,
  } = useAppMutation(studentServices.loadAvailableTimes);

  const {
    mutate: saveBooking,
    isSuccess: isBSuccess,
    data: bResult,
    isLoading: isBLoading,
    isError: isBError,
  } = useAppMutation(studentServices.saveBooking);

  const {
    mutate: loadBooking,
    isSuccess: isLBSuccess,
    data: lbResult,
    isLoading: isLBLoading,
    isError: isLBError,
  } = useAppMutation(studentServices.loadBooking);

  const {
    mutate: loadAvailableTeachers,
    isESuccess: isESuccess,
    data: evaluators,
  } = useAppMutation(studentServices.loadAvailableTeachers);

  React.useEffect(() => {
    if (globalState.booking) {
      const booking = { ...values, ...globalState.booking };
      setValues(booking);

      const min = globalState.minDate;
      const max = globalState.maxDate;
      setMinDate(min);
      setMaxDate(max);
      setEditable(false);
      if (globalState.booking.bookingId) {
        setEditable(false);
        loadBooking(globalState.booking.bookingId);
        // loadEvaluators({childId: booking.childId, startDate: booking.startDate, startTime: booking.startTime})
      }
    }
  }, [globalState]);

  React.useEffect(() => {
    if (isCBSuccess) {
      setGlobalState({
        ...globalState,
        booking: undefined,
        addEditBooking: false,
        refreshList: true,
      });
    }
  }, [isCBSuccess]);

  React.useEffect(() => {
    if (isLBSuccess) {
      const booking = { ...values, ...lbResult.booking };
      console.log("Booking object:", booking);
      const date = new Date(booking.startDate);
      booking.sstartDate = date;
      const startDate = moment(booking.sstartDate).format("YYYY-MM-DD"); // laResult.startDate.slice(0, 10);
      const startTime = moment(booking.sstartDate).format("HH:mm");
      booking.startDate = startDate;
      booking.startTime = startTime;

      console.log("Updated booking with meetingLink:", booking.meetingLink);
      setValues(booking);

      loadActiveTransactions({
        transactionId: booking.transactionId,
        date,
      });

      loadAvailableTimes({
        date: booking.sstartDate,
        childId: booking.childId,
        bookingId: booking.bookingId,
        type,
      });
    }
  }, [isLBSuccess]);

  React.useEffect(() => {
    console.log("isLASSuccess", isLASSuccess, lasData);
    if (isLASSuccess && lasData) {
      const { transaction, transactions } = lasData;

      console.log("Transactions.......", transaction, transactions);
      setTransactions([...transactions]);
      setTransaction({ ...transaction });
      if (!values.transactionId) {
        setValues({
          ...values,
          transactionId: lasData.transaction?.transactionId,
        });
      }
    }
  }, [isLASSuccess, lasData]);

  React.useEffect(() => {
    setAvailableDates(ldResult?.availableDates);
    setValues({ ...values, sstartDate: new Date(values.startDate) });
  }, []);

  React.useEffect(() => {
    console.log(isLTSuccess, isLTSuccess, ltResult);

    if (isLTSuccess && ltResult) {
      const ls = [];
      for (const t of ltResult?.timings) {
        let date = new Date(`${values.startDate}T${t}`);
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        date = new Date(date.getTime() - userTimezoneOffset);
        if (date > new Date()) {
          const v = timings.find(
            (item) => item.value === moment(date).format("HH:mm"),
          );

          if (v) {
            ls.push(v);
          }
        }
      }
      console.log("timings...........", ls);
      setSTimings(ls);
    } else {
      setSTimings([]);
    }
  }, [isLTError, isLTSuccess, ltResult]);

  React.useEffect(() => {
    if (isBSuccess) {
      setGlobalState({
        ...globalState,
        booking: undefined,
        addEditBooking: false,
        refreshList: true,
      });
    } else if (isBError) {
      setErrMsg(t("errMsg.saving"));
    }
  }, [isBError, isBSuccess]);

  const [errMsg, setErrMsg] = React.useState("");

  const loadEvaluators = ({ childId, startDate, startTime }) => {
    const sDate = startDate ? "" + startDate : "" + values.startDate;
    const sTime = startTime ? "" + startTime : "" + values.startTime;
    const c = childId ? childId : values.childId;
    const selectedStartDateTime = new Date(`${sDate}T${sTime}`);
    const selectedEndDateTime = new Date(`${sDate}T${sTime}`);

    selectedEndDateTime.setMinutes(selectedEndDateTime.getMinutes() + 29);

    const obj = {
      type,
      childId: c,
      startDate: selectedStartDateTime,
      endDate: selectedEndDateTime,
    };
    loadAvailableTeachers(obj);
  };

  const handleCancelBooking = () => {
    const { reason, bookingId } = values;

    setErrMsg("");

    if (!reason || !reason.trim()) {
      setErrMsg("Please provide a reason for cancellation.");
    } else {
      // Proceed with cancellation action
      // Your cancellation logic here
      const obj = {
        bookingId,
        reason,
      };
      cancelBooking(obj);
    }
  };

  const [showWarning, setShowWarning] = useState(false);
  const handleSaveBooking = () => {
    if (values.startTime && values.startDate && values.childId) {
      const selectedStartDateTime = new Date(
        `${values.startDate}T${values.startTime}`,
      );
      const currentTime = new Date();
      const timeDifference =
        selectedStartDateTime.getTime() - currentTime.getTime();
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60)); // Convert milliseconds to hours

      if (hoursDifference < 24) {
        setShowWarning(true);
        return; // Exit the function to show the warning message
      }
      const selectedEndDateTime = new Date(
        `${values.startDate}T${values.startTime}`,
      );
      selectedEndDateTime.setMinutes(selectedEndDateTime.getMinutes() + 30);

      const booking = {
        description: values.description,
        startDate: selectedStartDateTime,
        endDate: selectedEndDateTime,
        studentId: values.childId,
        bookingId: values.bookingId,
        teacherId: values.teacherId,
        transactionId: values.transactionId,
      };

      saveBooking(booking);
    } else {
      setErrMsg(t("common.inputError"));
      setTimeout(() => {
        setErrMsg("");
      }, 5000);
    }
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === "startTime") {
      loadEvaluators({ startTime: event.target.value });
    } else if (prop === "startDate") {
      loadEvaluators({ startDate: event.target.value });
    } else if (prop === "childId") {
      loadActiveTransactions({
        studentId: event.target.value,
        transactionId: values.transactionId,
        date: values.sstartDate,
      });

      if (!sTimings || sTimings.length === 0) {
        if (values.sstartDate) {
          loadAvailableTimes({
            date: values.sstartDate,
            childId: event.target.value,
            bookingId: values.bookingId,
            type,
          });
        }
      }
    }
  };

  const renderTransactionAlert = () => {
    if (!transactions || transactions?.length == 0) {
      return (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="warning" onClose={() => {}}>
            {t("calendar.subscribe")}
          </Alert>
        </Stack>
      );
    }

    return null;
  };

  const isDisableSave = () => {
    if (!transactions || transactions?.length == 0) {
      return true;
    }

    return false;
  };
  const handleCancelConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCancelNo = () => {
    setShowConfirmation(false);
  };
  const isCancelDisabled = () => {
    if (!values.startTime || !values.startDate) {
      // Disable cancel button if start time or start date is not selected
      return true;
    }

    const selectedStartDateTime = new Date(
      `${values.startDate}T${values.startTime}`,
    );
    const currentTime = new Date();
    const timeDifference =
      selectedStartDateTime.getTime() - currentTime.getTime();
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60)); // Convert milliseconds to hours

    // Disable cancel button if less than 24 hours until start time
    return hoursDifference < 24;
  };

  const handleLinkButtonClick = () => {
    if (values.teacher && values.teacher.meetingLink) {
      window.open(values.teacher.meetingLink, "_blank");
    }
  };

  return (
    <FormControl noValidate autoComplete="off">
      <Div
        sx={{
          "& .MuiTextField-root": {
            mb: 3,
            mt: 0.5,
          },
        }}
      >
        {renderTransactionAlert()}

        {showWarning && (
          <Snackbar
            open={showWarning}
            autoHideDuration={6000}
            onClose={() => setShowWarning(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{ width: "100%", marginTop: 2 }}
            spacing={2}
          >
            <Alert onClose={() => setShowWarning(false)} severity="warning">
              {t("calendar.notChange")}
            </Alert>
          </Snackbar>
        )}

        <FormControl fullWidth sx={{ mb: 3, mt: 1 }}>
          <InputLabel id="demo-multiple-checkbox-label">
            {t("common.training")}
          </InputLabel>
          <Select
            disabled={pastDate || !isEditable}
            value={values.transactionId}
            label={t("common.training")}
            onChange={handleChange("transactionId")}
            renderValue={(selected) => {
              // Find the selected child based on the studentId
              const selectedChild = transactions?.find(
                (child) => child.transactionId === selected,
              );

              // Display only the selected child's name
              return (
                <Div sx={{ display: "flex", alignItems: "center" }}>
                  {selectedChild?.course?.name}
                </Div>
              );
            }}
          >
            {transactions.map((item) => (
              <MenuItem key={item.transactionId} value={item.transactionId}>
                <div>
                  {item.course.name}
                  <br />
                  {t("common.expired")}{" "}
                  {moment(item.expiredDate).format("YYYY-MM-DD")}
                  <br />
                  {t("common.bookingUtilize")} {item.noOfUsedBooking}/
                  {item.noOfBooking}
                </div>
                {item.transactionId === transaction?.transactionId && (
                  <div>
                    <Chip
                      label={t("common.recommended")}
                      color={"success"}
                      size={"small"}
                    />
                  </div>
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                disabled={pastDate || !isEditable}
                open={open}
                onOpen={(event) => {
                  setOpen(true);
                }}
                onClose={(event) => {
                  setOpen(false);
                }}
                shouldDisableDate={(day) => {
                  console.log("shouldDisableDate", day);
                  if (!isEditable) {
                    return false;
                  } else if (pastDate) {
                    return false;
                  } else if (
                    availableDates &&
                    availableDates.indexOf(moment(day).format("YYYY-MM-DD")) >
                      -1
                  ) {
                    const currentDate = new Date();
                    if (
                      moment(currentDate).format("YYYY-MM-DD") ===
                      moment(day).format("YYYY-MM-DD")
                    ) {
                      return true;
                    }
                    return false;
                  } else {
                    return true;
                  }
                }}
                minDate={pastDate ? null : minDate}
                maxDate={pastDate ? null : maxDate}
                loading={false}
                label={t("common.date")}
                format="dd/MM/yyyy"
                value={values.sstartDate}
                onChange={(event, newValue) => {
                  const startDate = moment(event).format("YYYY-MM-DD");
                  const sstartDate = event;
                  setValues({ ...values, startDate, sstartDate });

                  loadAvailableTimes({
                    date: startDate,
                    childId: values.childId,
                    bookingId: values.bookingId,
                    type,
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </FormControl>

        {/*  {values.startTime} */}
        <FormControl fullWidth sx={{ mb: 2, mt: 1 }}>
          {!isEditable && (
            <TextField
              label={t("parent.title.time")}
              disabled={!isEditable}
              value={values.startTime}
            />
          )}
        </FormControl>

        <FormControl fullWidth sx={{ mb: 3, mt: 1 }}>
          {!isEditable && (
            <>
              <InputLabel id="demo-multiple-checkbox-label">
                {t("common.trainer")}
              </InputLabel>
              <Select
                disabled={true}
                value={values.teacher?.teacherId}
                label={t("common.trainer")}
                onChange={handleChange("teacherId")}
                renderValue={(selected) => {
                  if (values.teacher) {
                    return (
                      <Div sx={{ display: "flex", alignItems: "center" }}>
                        <ListItemAvatar>
                          {values.teacher?.images?.length > 0 ? (
                            <DDImage
                              path={values.teacher.images[0]}
                              alt={
                                values.teacher.firstName +
                                " " +
                                values.teacher.lastName
                              }
                            />
                          ) : (
                            <Avatar
                              alt={
                                values.teacher?.firstName +
                                " " +
                                values.teacher?.lastName
                              }
                            />
                          )}
                        </ListItemAvatar>
                        {values.teacher?.firstName} {values.teacher?.lastName}
                      </Div>
                    );
                  }
                  return null;
                }}
              >
                {values.teacher && (
                  <MenuItem
                    key={"key-teacher-" + values.teacher.teacherId}
                    value={values.teacher.teacherId}
                  >
                    <ListItemAvatar>
                      {values.teacher.images?.length > 0 ? (
                        <DDImage
                          path={values.teacher.images[0]}
                          alt={
                            values.teacher.firstName +
                            " " +
                            values.teacher.lastName
                          }
                        />
                      ) : (
                        <Avatar
                          alt={
                            values.teacher.firstName +
                            " " +
                            values.teacher.lastName
                          }
                        />
                      )}
                    </ListItemAvatar>
                    {values.teacher.firstName} {values.teacher.lastName}
                  </MenuItem>
                )}
              </Select>
            </>
          )}
        </FormControl>

        {values.bookingId && (
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-static"
              label={t("common.trainerLink")}
              value={values.teacher?.meetingLink || ""}
              name="meetingLink"
              onChange={handleChange("meetingLink")}
              disabled={pastDate || !isEditable}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleLinkButtonClick}
                      style={{
                        pointerEvents: "auto",
                      }}
                    >
                      {t("parent.title.link")}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        )}

        <FormControl fullWidth>
          <TextField
            disabled={pastDate || !isEditable}
            id="outlined-multiline-static"
            label={t("common.desc")}
            multiline
            rows={2}
            value={values.description}
            name="description"
            onChange={handleChange("description")}
          />
        </FormControl>
        {t("parent.title.txt")}
        <Stack
          sx={{
            mb: 2,
            width: "90%",
            mx: "auto",
            display: "block",
            position: "relative", // Add position relative to enable absolute positioning of loader
          }}
          spacing={2}
        >
          {errMsg && <Alert severity="error">{errMsg}</Alert>}
        </Stack>
        {!showConfirmation && (
          <ButtonGroup
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "center",
            }}
            disableElevation
            variant="contained"
            color={"warning"}
          >
            <Button
              type="submit"
              variant="contained"
              size="medium"
              onClick={() => {
                setGlobalState({
                  ...globalState,
                  booking: undefined,
                  addEditBooking: false,
                });
              }}
              sx={{}}
            >
              {t("common.closeBtn")}
            </Button>

            {isEditable && (
              <LoadingButton
                type="submit"
                variant="contained"
                size="medium"
                disabled={isDisableSave()}
                onClick={handleSaveBooking}
                sx={{
                  ml: 0.5,
                  mr: 0.5,
                }}
                loading={isBLoading}
              >
                {values.cancelled ? (
                  <>Undo</>
                ) : values.bookingId ? (
                  t("common.updateBtn")
                ) : (
                  t("common.saveBtn")
                )}
              </LoadingButton>
            )}
            {values.attended}
            {values.attended !== "not-attended" &&
              !pastDate &&
              values.teacherFeedback?.ratings?.length === 0 &&
              !values.cancelled &&
              values.bookingId &&
              !isCancelDisabled() && (
                <Button
                  type="button"
                  variant="contained"
                  size="medium"
                  onClick={handleCancelConfirmation}
                  sx={{
                    ml: 0.5,
                  }}
                >
                  {t("common.cancelBtn")}
                </Button>
              )}
            {values.teacherFeedback?.ratings?.length > 0 && (
              <Button
                type="button"
                variant="contained"
                size="medium"
                onClick={() => {
                  setShowTeacherFeedBack({
                    show: true,
                    teacherFeedback: values.teacherFeedback,
                  });
                }}
                sx={{
                  ml: 0.5,
                }}
              >
                {t("common.feedbackBtn")}
              </Button>
            )}
          </ButtonGroup>
        )}
        {showConfirmation && (
          <>
            {/* Text field before the alert */}
            <TextField
              id="reason"
              label="Reason for cancellation"
              variant="outlined"
              size="small"
              margin="dense"
              multiline
              rows={2}
              onChange={handleChange("reason")}
              sx={{ width: "100%" }}
            />
            {/* Alert component */}
            <Alert
              severity="warning"
              sx={{ mb: 1, display: "flex", alignItems: "center", mt: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <span style={{ marginRight: "15px" }}>
                  {t("common.cancelTxt")}
                </span>
                <Stack direction="row" spacing={2}>
                  <LoadingButton
                    loading={isCBLoading}
                    onClick={handleCancelBooking}
                  >
                    {t("common.yesBtn")}
                  </LoadingButton>
                  <Button onClick={handleCancelNo}>{t("common.noBtn")}</Button>
                </Stack>
              </div>
            </Alert>
          </>
        )}
        {/* {cancellationConfirmed && (
                <TextField
                    id="cancelledReason"
                    label="Reason for cancellation"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    value={cancelReason}
                    disabled
                    sx={{ width: '100%' }}
                />
            )} */}
      </Div>
    </FormControl>
  );
};

export default EditBookingForm;
