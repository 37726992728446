import React, {useEffect} from 'react';
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import JumboListToolbar from "@jumbo/components/JumboList/components/JumboListToolbar";
import ButtonGroup from "@mui/material/ButtonGroup";
import {Button, Card, Dialog, DialogContent, DialogTitle, useMediaQuery} from "@mui/material";
import {useParams} from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import {USE_IMAGE_PLACEHOLDERS} from "../../../utils/constants/paths";
import Item from "./Item";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import Header from "./header";
import Sidebar from "./sidebar";
import {ChildrenProvider, useChildrenContext} from "./context";
import {useJumboTheme} from "@jumbo/hooks";
import Stack from "@mui/material/Stack";
import parentServices from "../../../services/parent-services";
import ChildForm from "./ChildForm";
import FolderDropdown from "./FolderDropdown";
import ContactFab from "./ChildFab";
import {useTranslation} from "react-i18next";

export const ASSET_IMAGES = process.env.REACT_APP_IMAGES_PATH;

export const ASSET_AVATARS = ASSET_IMAGES + "/avatar";


export const getAssetPath = (url, size) => {
    if (USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }
    return url;
};

const BulkActions = () => {
    return <>Test</>
}

const Children = () => {
    return <ChildrenProvider><InnerChildren/></ChildrenProvider>
}

const InnerChildren = () => {

    const {globalState, setGlobalState} = useChildrenContext();
    const params = useParams();
    const {t} = useTranslation();

    const {theme} = useJumboTheme();

    const lg = useMediaQuery(theme.breakpoints.down('lg'));

    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const listRef = React.useRef();


    const [view, setView] = React.useState("list");

    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: "children",
        queryParams: {category: params.category, id: params.id},
        countKey: "count",
        dataKey: "children",
    });

    React.useEffect(() => {

        if (params?.category === 'add') {
            setGlobalState({...globalState, openAddEditChild: true})
        }

    }, [params]);

    useEffect(() => {
        if (globalState?.refreshChildrenList) {
            listRef.current.refresh();
            setGlobalState({...globalState, refreshChildrenList: false})
        }
    }, [globalState]);


    const loadChildren = async () => {

        const result = await parentServices.loadChildren()

        console.log(result);

        return {children: result, count: result.length};

    }

    const renderChild = React.useCallback((child) => {
        return (<Item child={child} view={view}/>)
    }, [view]);


    const handleOnChange = React.useCallback((keywords) => {
        setQueryOptions(state => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                keywords: keywords,
            }
        }))
    }, []);

    return (

        <JumboContentLayout
            layoutOptions={layoutOptions}
            sidebar={<Sidebar title={(t('parent-children.title.children'))}
                              subheader={(t('parent-children.title.childrenSub'))}/>}
            header={
                <Header title={(t('parent-children.title.children'))}
                        subheader={(t('parent-children.title.childrenSub'))}/>
            }

        >
            {
                lg && (
                    <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                        {/*<FolderDropdown/>*/}
                        <ContactFab/>
                    </Stack>
                )
            }
            <JumboRqList
                ref={listRef}
                wrapperComponent={Card}
                primaryKey={"studentId"}
                queryOptions={queryOptions}
                renderItem={renderChild}
                service={loadChildren}
                componentElement={"div"}
                sx={view === 'grid' && {p: theme => theme.spacing(1, 3, 3)}}
                wrapperSx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
                toolbar={
                    <JumboListToolbar
                        hideItemsPerPage={true}
                        bulkActions={false}
                        actionTail={
                            <ButtonGroup
                                variant="outlined"
                                disableElevation
                                sx={{
                                    '& .MuiButton-root': {
                                        px: 1,
                                    }
                                }}
                            >
                                <Button variant={view === "list" ? "contained" : "outlined"}
                                        onClick={() => setView("list")}><ListIcon/></Button>
                                <Button variant={view === "grid" ? "contained" : "outlined"}
                                        onClick={() => setView("grid")}><ViewComfyIcon/></Button>
                            </ButtonGroup>
                        }
                    >

                    </JumboListToolbar>
                }
                view={view}
            />

            <Dialog open={globalState.openAddEditChild}>
                <DialogTitle>{(t('parent-children.title.childEnroll'))}</DialogTitle>
                <DialogContent sx={{width: '100%'}}><ChildForm/></DialogContent>
            </Dialog>
        </JumboContentLayout>

    );
};

export default Children;
