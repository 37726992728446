import React, {useEffect, useState} from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import CalendarWrapper from "./CalendarWrapper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Alert, IconButton,} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Stack from "@mui/material/Stack";
import {useAppMutation} from "../../../../services";
import Snackbar from '@mui/material/Snackbar';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Typography from "@mui/material/Typography";
import teacherServices from "../../../../services/teacher-services";
import DeleteIcon from "@mui/icons-material/Delete";
import {useCalendarContext} from "../context";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import 'moment/locale/en-au';
import {useNavigate, useParams} from "react-router-dom";

const StyledCircle = styled('div')(({theme, color}) => ({
    backgroundColor: color,
    width: '10px',
    height: '10px',
    borderRadius: '50%',
}));

// const today = new Date();
// const currentYear = today.getFullYear();
const localizer = momentLocalizer(moment);


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const CalendarBasic = () => {
    moment.locale("en-au");

    const params = useParams();
    const navigate = useNavigate();
    const {globalState, setGlobalState} = useCalendarContext();
    const {t} = useTranslation();
    const [calenderView, setCalenderView] = useState('month');
    const {
        mutate: deleteAvailabilityByDayAndHour,
        isSuccess: isABDNHSuccess,
    } = useAppMutation(teacherServices.deleteAvailabilityByDayAndHour);

    const {
        mutate: loadEvents,
        isSuccess: isLESuccess,
        data: leResult
    } = useAppMutation(teacherServices.loadEvents);

    const {
        mutate: deleteAvailabilityByDay,
        isSuccess: isABDSuccess,
        data: abdResult
    } = useAppMutation(teacherServices.deleteAvailabilityByDay);


    React.useEffect(() => {

        if (isABDNHSuccess) {
            setGlobalState({...globalState, refreshList: true})

        }

    }, [isABDNHSuccess])

    const deleteDayHours = ({date, event}) => {

        console.log(event.scheduleId);

        const dStartDate = new Date(date);
        const dEndDate = new Date(date);
        dEndDate.setMinutes(dEndDate.getMinutes() + 30);
        console.log(date.getTime(), event.start.getTime())
        const obj = {scheduleId: event.scheduleId, date, availability: []};

        const es = events.filter(item => item.scheduleId !== event.scheduleId);

        if (dStartDate.getTime() === event.start.getTime() &&
            dEndDate.getTime() === event.end.getTime()) {
            // alert(3);
        } else if (dStartDate.getTime() === event.start.getTime()) {
            const startDate = new Date(event.start);
            startDate.setMinutes(startDate.getMinutes() + 30);
            const endDate = new Date(event.end)
            const e = {...event, startDate, endDate}
            delete e.scheduleId;
            obj.availability.push(e);
        } else if (dEndDate.getTime() === event.end.getTime()) {
            const startDate = new Date(event.start);
            const endDate = new Date(event.end)
            endDate.setMinutes(endDate.getMinutes() - 30);
            const e = {...event, startDate, endDate}
            delete e.scheduleId;
            obj.availability.push(e);
        } else {

            let startDate = new Date(event.start);
            let endDate = new Date(dStartDate);
            let e = {...event, startDate: startDate, endDate}
            delete e.scheduleId;
            obj.availability.push(e);


            startDate = new Date(dEndDate);
            endDate = new Date(event.end);


            e = {
                ...event,
                startDate,
                endDate,
            }
            delete e.scheduleId;
            obj.availability.push(e);

        }

        deleteAvailabilityByDayAndHour(obj);

    }

    const getTimeLable = (value) => {

        const startDate = new Date(value);
        const endDate = new Date(value);
        endDate.setMinutes(endDate.getMinutes() + 30);
        const str1 = moment(startDate).format('H:mm')
        const str2 = moment(endDate).format('H:mm')

        return <div style={{fontSize: 5}}>{str1 + '-' + str2}</div>

    }

    const TimeSlotWrapper = ({value, children}) => {

        if (calenderView === 'day') {

            if (children?.props.children) {


                if (events && events.length > 0) {

                    const es = events.find(item => item.scheduleId && value >= item.start && value < item.end);

                    // console.log(es);

                    if (es) {
                        return <div>{getTimeLable(value)}<DeleteIcon
                            onClick={(event) => {
                                deleteDayHours({date: value, event: es});
                            }}/></div>
                    }

                    return <div>{getTimeLable(value)}</div>


                } else {
                    return children
                }


            }
        }

        // console.log(ReactDOM.findDOMNode(children.props));
        return children;
    }

    const TimeGutterHeader = ({date, localizer}) => {
        return <></>;
    }
    const EventComponent = ({event, index}) => {

        console.log('EventComponent--------------------', index, event);


        if (event.bookingId) {

            let formattedStartTime = moment(event.start).format('HH:mm');
            let formattedEndTime = moment(event.end).format('HH:mm');

            return (
                <Div sx={{display: 'flex', alignItems: 'center', height: 20}} key={'calendar-' + index}>
                    <Typography sx={{fontSize: 10}} variant="caption" gutterBottom component="div">
                        {event.student?.firstName + ' ' + event.student?.lastName} {formattedStartTime} to {formattedEndTime}
                    </Typography>
                    {event.attended === 'not-attended' && <Div sx={{ml: 1}}><StyledCircle color="#D22B2B"/></Div>}
                </Div>
            );

        } else if (event.appointmentId) {


            let formattedStartTime = moment(event.start).format('HH:mm');
            let formattedEndTime = moment(event.end).format('HH:mm');

            return (
                <Div sx={{display: 'flex', alignItems: 'center', height: 20}} key={'calendar-' + index}>
                    <Typography sx={{fontSize: 10}} variant="caption" gutterBottom component="div">
                        {event.student?.firstName + ' ' + event.student?.lastName} {formattedStartTime} to {formattedEndTime}
                    </Typography>
                    {event.attended === 'not-attended' && <Div sx={{ml: 1}}><StyledCircle color="#D22B2B"/></Div>}
                </Div>
            );
        } else if (event.scheduleId) {


            let formattedStartTime = undefined;
            let formattedEndTime = undefined;

            if (moment(event.start).format('YYYY-MM-DD') === moment(event.end).format('YYYY-MM-DD')) {
                formattedStartTime = moment(event.start).format('HH:mm');
                formattedEndTime = moment(event.end).format('HH:mm');
            } else {
                formattedStartTime = moment(event.start).format('DD-HH:mm');
                formattedEndTime = moment(event.end).format('DD-HH:mm');
            }


            return <Div sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: 20, width: '100%'}}
                        key={'calendar-' + index}>
                <Typography sx={{fontSize: 10, mt: .5}} variant="caption" gutterBottom component="div">
                    {formattedStartTime} to {formattedEndTime}
                </Typography>
                {event.repeated !== 'no-repeat' &&
                    <DeleteIcon onClick={(e) => {
                        handleDeleteAvailability(e, event)
                    }} sx={{fontSize: 'medium'}}
                    />}
            </Div>;
        }

        return null;
    }


    const DayEventComponent = ({event, index}) => {
        return null
    }

    const AgendaEventComponent = ({event, index}) => {
        const formattedStartTime = new Date(event.start).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
        let color = '';

        if (event.scheduleId) {
            color = '#8C52FF';
        } else if (event.appointmentId) {
            color = event.evaluation ? '#454545' : '#FFB733'
            color = event.cancelled ? '#D22B2B' : color;
        } else if (event.bookingId) {
            color = event.teacherFeedback?.ratings?.length > 0 ? '#1D6ADE' : '#59B2FF';
            color = event.cancelled ? '#D22B2B' : color;

        }


        if (event.bookingId) {

            return <Div sx={{backgroundColor: color, display: 'flex', alignItems: 'center', height: 20}}
                        key={'calendar-' + index}>
                <Typography variant="h6" gutterBottom component="div">
                    {t('calendar.booking')}
                </Typography>
                {event.attended === 'not-attended' && <Div sx={{ml: 1}}><StyledCircle color="#D22B2B"/></Div>}

            </Div>

        } else if (event.appointmentId) {


            return (
                <Div sx={{backgroundColor: color, display: 'flex', alignItems: 'center'}}
                     key={'calendar-' + index}>
                    <Typography variant="h6" gutterBottom component="div">
                        {t('teacher-appointment.title.appointment')}
                    </Typography>
                    {event.attended === 'not-attended' && <Div sx={{ml: 1}}><StyledCircle color="#D22B2B"/></Div>}

                </Div>
            )
                ;
        } else if (event.scheduleId) {
            return <Div sx={{
                display: 'grid',
                backgroundColor: color,
                alignItems: 'center',
                width: '100%'
            }}
                        key={'calendar-' + index}>
                <Typography variant="h6" gutterBottom component="div">
                    {t('common.available')}
                </Typography>
            </Div>;
        }
    }


    const [selectedStartDate, setSelectedStartDate] = React.useState(null);
    const [selectedEndDate, setSelectedEndDate] = React.useState(null);
    const [errMsg, setErrMsg] = React.useState('');
    const [events, setEvents] = useState([]);

    // const [currentDate, setCurrentDate] = useState(new Date());

    const [searchStartDate, setSearchStartDate] = useState();


    {/*React.useEffect(() => {

        if (isCSuccess && cResult) {
            console.log('Children-----------------------', cResult)
            setChildren(cResult);
        }
        if (!cResult) {
            loadChildren()
        }

    }, [cResult, isCSuccess])*/
    }

    useEffect(() => {


        if (isLESuccess && leResult) {

            const es = []
            for (const e of leResult) {

                es.push({...e, start: new Date(e.startDate), end: new Date(e.endDate)})
            }

            setEvents(es)
        }


    }, [isLESuccess, leResult])


    React.useEffect(() => {

        if (searchStartDate) {
            loadEvents({
                startDate: searchStartDate,
                showBookings: globalState.showBookings,
                showAppointments: globalState.showAppointments,
                showAvailabilities: globalState.showAvailabilities,
            })
        } else {
            const date = new Date();
            date.setDate(1);
            setSearchStartDate(date);
        }

    }, [searchStartDate])


    React.useEffect(() => {

        if (abdResult && isABDSuccess) {
            setGlobalState({...globalState, refreshList: true})

        }

    }, [abdResult, isABDSuccess])


    const isPastDate = (date) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        return date < currentDate;
    };


    const handleSelectSlot = (slotInfo) => {


        if (true) {

            let selectedStartDate = new Date();
            let selectedEndDate = new Date();

            if (slotInfo.slots.length === 1) {
                selectedStartDate = new Date(slotInfo.slots[0]);
                selectedEndDate = new Date(slotInfo.slots[0]);
            } else if (slotInfo.slots.length === 2) {
                selectedStartDate = new Date(slotInfo.slots[0]);
                selectedEndDate = new Date(slotInfo.slots[1]);
            } else {
                return;
            }

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            // Check if the selected date is in the past
            if (selectedStartDate < currentDate) {
                setErrMsg(t("errMsg.pastDateMsg"));
                setSnackbarOpen(true);
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
                return;
            }


            const maxDate = new Date();
            // maxDate.setm(maxDate.getUTCMonth() + 6);
            // maxDate.setUTCHours(0, 0, 0, 0);
            maxDate.setMonth(maxDate.getMonth() + 6)
            maxDate.setHours(0, 0, 0, 0);
            // Check if the selected date is within the next month
            if (selectedStartDate < currentDate || selectedEndDate >= maxDate) {
                setErrMsg(t('teacher-appointment.title.monthMsg'));
                setSnackbarOpen(true);
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
                return;
            }

            if (isPastDate(selectedStartDate)) {
                setErrMsg(t('errMsg.pastDateMsg'));
                setSnackbarOpen(true);
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
                return;
            }

            const event1 = {
                startDate: moment(selectedStartDate).format('YYYY-MM-DD'),
                endDate: moment(selectedEndDate).format('YYYY-MM-DD'),
                endTime: "17:00",
                startTime: "09:00",
            };

            console.log('Event-----------------------------:', event1);

            setGlobalState({...globalState, openUnavailabilityForm: true, event: event1});
        }

        return;

        console.log("slotInfo----**----", slotInfo);
        // const selectedStartDate = new Date(Date.UTC(slotInfo.start.getFullYear(), slotInfo.start.getMonth(), slotInfo.start.getDate(), 0, 0, 0, 0));
        // const selectedEndDate = new Date(Date.UTC(slotInfo.end.getFullYear(), slotInfo.end.getMonth(), slotInfo.end.getDate(), 0, 0, 0, 0));

        const selectedStartDate = new Date(slotInfo.start);
        console.log(selectedStartDate)
        const selectedEndDate = new Date(Date.UTC(slotInfo.end.getFullYear(), slotInfo.end.getMonth(), slotInfo.end.getDate(), 0, 0, 0, 0));

        const dayName = new Intl.DateTimeFormat('en-US', {weekday: 'long'}).format(selectedStartDate);

        console.log('Selected Day Name:', dayName);

        // Set the time of the current date to midnight
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);


        // Check if the selected date is in the past
        if (selectedStartDate < currentDate) {
            setErrMsg(t("errMsg.pastDateMsg"));
            setSnackbarOpen(true);
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        const maxDate = new Date();
        // maxDate.setm(maxDate.getUTCMonth() + 6);
        // maxDate.setUTCHours(0, 0, 0, 0);
        maxDate.setMonth(maxDate.getMonth() + 6)
        maxDate.setHours(0, 0, 0, 0);
        // Check if the selected date is within the next month
        if (slotInfo.start.getTime() < currentDate || slotInfo.end >= maxDate) {
            setErrMsg(t('teacher-appointment.title.monthMsg'));
            setSnackbarOpen(true);
            alert(2)
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        if (isPastDate(selectedStartDate)) {
            setErrMsg(t('errMsg.pastDateMsg'));
            setSnackbarOpen(true);
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        selectedStartDate.setUTCHours(0, 0, 0, 0); // Set time to midnight
        selectedEndDate.setDate(selectedEndDate.getUTCDate() - 1);
        selectedEndDate.setUTCHours(0, 0, 0, 0);


        console.log('Adjusted Dates:', selectedStartDate, selectedEndDate);

        setSelectedStartDate(selectedStartDate);
        setSelectedEndDate(selectedEndDate)

        // Adjust time zone offset when formatting the date
        const adjustedStartDate = new Date(selectedStartDate.getTime() - selectedStartDate.getTimezoneOffset() * 60000);
        const adjustedEndDate = new Date(selectedEndDate.getTime() - selectedEndDate.getTimezoneOffset() * 60000);


        const event1 = {
            startDate: moment(slotInfo.start).format('YYYY-MM-DD'),
            endDate: moment(slotInfo.end).format('YYYY-MM-DD'),
            endTime: "17:00",
            startTime: "09:00",
        };

        console.log('Event-----------------------------:', event1);

        setGlobalState({...globalState, openUnavailabilityForm: true, event: event1});
    };

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setErrMsg('');
    };

    const handleEventClick = (event) => {

        console.log("Unavailability----**----", event);


        if (event.bookingId) {

            const startDateTime = new Date(event.start);
            const formattedStartTime = moment(startDateTime).format('HH:mm');
            // const formattedEndTime = moment(endDateTime).format('HH:mm');
            // const formattedStartTime = `${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`;
            console.log("data--------", event);
            const booking = {
                title: event.title,
                studentId: event.student?.studentId,
                bookingId: event.bookingId,
                description: event.description,
                startDate: moment(startDateTime).format('YYYY-MM-DD'),
                startTime: formattedStartTime,
                firstName: event.student?.firstName,
                lastName: event.student?.lastName,
                student: event.student,
                teacherFeedback: event.teacherFeedback,
                attended: event.attended,
                cancelled: event.cancelled,
                parent: event.parent
            };
            setGlobalState({...globalState, openBookingAddEdit: true, booking});

        } else if (event.appointmentId) {
            const startDateTime = new Date(event.start);
            const formattedStartTime = `${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`;
            console.log("data--------", event);
            const appointment = {
                title: event.title,
                studentId: event.student?.studentId,
                student: event.student,
                appointmentId: event.appointmentId,
                description: event.description,
                startDate: moment(event.start).format('YYYY-MM-DD'),
                startTime: formattedStartTime,
                firstName: event.student?.firstName,
                lastName: event.student?.lastName,
                evaluation: event.evaluation,
                attended: event.attended,
                cancelled: event.cancelled,
                parent: event.parent
            };
            setGlobalState({...globalState, openAppointmentAddEdit: true, appointment});
        } else if (event.scheduleId) {
            console.log("Unavailability----**23----", event);
            const startDateTime = new Date(event.dates.startDate);
            const endDateTime = new Date(event.dates.endDate);


            const formattedStartTime = moment(startDateTime).format('HH:mm');
            const formattedEndTime = moment(endDateTime).format('HH:mm');

            const event1 = {
                comments: event.comments,
                startDate: moment(startDateTime).format('YYYY-MM-DD'),
                startTime: formattedStartTime,
                endDate: moment(endDateTime).format('YYYY-MM-DD'),
                endTime: formattedEndTime,
                scheduleId: event.scheduleId,
                types: event.types,
                repeated: event.repeated,
            };

            setGlobalState({...globalState, openUnavailabilityForm: true, event: event1});
        }


    };


    React.useEffect(() => {



        if(params.message === 'evaluated-by-teacher'){
            setTimeout(()=>{
                navigate('/dashboard/calendar');
            },1000)
        }

        if (globalState.refreshList) {
            setGlobalState({...globalState, refreshList: false});
            loadEvents({
                startDate: searchStartDate,
                showBookings: globalState.showBookings,
                showAppointments: globalState.showAppointments,
                showAvailabilities: globalState.showAvailabilities,
            })
        }
    }, [globalState])


    const handleNavigate = (newDate, view, action) => {
        if (newDate.getMonth() != searchStartDate.getMonth()) {
            setSearchStartDate(newDate);
        }
    };

    const handleDeleteAvailability = (e, event) => {
        e.stopPropagation();
        deleteAvailabilityByDay({scheduleId: event.scheduleId, date: event.startDate});
    }


    return (
        <React.Fragment>
            {/*<Typography variant={"h1"} mb={3}>{t('pages.title.basicCalendar')}</Typography>*/}
            <Stack

                spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>


            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                open={snackbarOpen}
                autoHideDuration={2500}
                startAccessor="startDate"
                endAccessor="endDate"
                onClose={handleSnackbarClose}
                message={errMsg}
                action={
                    <IconButton color={"primary"} size={"small"}>
                        <WarningAmberOutlinedIcon/>
                    </IconButton>
                }
            />

            <Card>
                <CardContent>
                    <CalendarWrapper>
                        <Calendar
                            onView={(view) => {
                                setCalenderView(view)
                            }}
                            localizer={localizer}
                            events={events}
                            step={15}
                            defaultDate={new Date()}
                            onNavigate={handleNavigate}
                            style={{height: 600, overflowX: 'auto'}}
                            selectable={true} // Enable user selection
                            onSelectSlot={handleSelectSlot}
                            startAccessor="start"
                            endAccessor="end"
                            defaultView={calenderView}
                            onSelectEvent={(event) => {
                                handleEventClick(event)
                            }}
                            eventPropGetter={(event, start, end, isSelected) => {

                                let color = '';

                                if (event.scheduleId) {
                                    color = '#8C52FF';
                                } else if (event.appointmentId) {
                                    color = event.evaluation ? '#454545' : '#FFB733'
                                    color = event.cancelled ? '#D22B2B' : color;

                                } else if (event.bookingId) {
                                    color = event.teacherFeedback?.ratings?.length > 0 ? '#1D6ADE' : '#59B2FF';
                                    color = event.cancelled ? '#D22B2B' : color;
                                }

                                if (calenderView !== 'agenda') {
                                    return {
                                        style: {
                                            backgroundColor: color,
                                            color: isSelected ? '#FFF' : "#FFF",
                                        },
                                    };
                                }
                                return null;
                            }}

                            components={{
                                event: EventComponent,
                                timeSlotWrapper: TimeSlotWrapper,
                                timeGutterHeader: TimeGutterHeader,
                                agenda: {
                                    event: AgendaEventComponent,
                                },
                            }}
                        />
                    </CalendarWrapper>
                </CardContent>
            </Card>
        </React.Fragment>

    );
};

export default CalendarBasic;
