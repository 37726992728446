import React, {useEffect,useState} from 'react';
import Div from "@jumbo/shared/Div";
import {Alert, Box, Button, FormControl, IconButton , TextField,} from "@mui/material";
import {Table, TableBody, TableCell, TableHead, TableRow,Checkbox, FormControlLabel,InputAdornment} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import {Form, Formik} from "formik";
import {useQuizContext} from "../context";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';
import commonServices from "../../../../services/common-services";

const Step4 = ({hideDialog, stepsData, handleNext, setStepsData}) => {

    // const [values, setValues] = React.useState({});
    const [errMsg, setErrMsg] = React.useState('');
    const {globalState, setGlobalState} = useQuizContext();
    const {
        mutate: savePlan,
        isError,
        isLoading: isProgramSaving,
        isSuccess,
        data: program,
        error
    } = useAppMutation(adminServices.saveQuiz);

    const [values, setValues] = React.useState({
        amount: '',
        validity: '',
        compareAmount:'',
        questions: []
    });

    const [question, setQuestion] = useState('');
    const defaultChoices = [
        { choiceText: '', isCorrect: false },
        { choiceText: '', isCorrect: false },
    ];

    // State for choices, initially set to the default choices
    const [choices, setChoices] = useState(defaultChoices);
    //const [questions, setQuestions] = useState([]);

    const handleChoiceChange0 = (index, field, value) => {
        const updatedChoices = choices.map((choice, i) =>
            i === index ? { ...choice, [field]: value } : choice
        );
        setChoices(updatedChoices);
    };

    const handleChoiceChange = (index, field, value) => {
        if (field === 'isCorrect' && value) {
            // Update all choices to be incorrect, then set the selected choice as correct
            const updatedChoices = choices.map((choice, i) => ({
                ...choice,
                isCorrect: i === index ? true : false,
            }));
            setChoices(updatedChoices);
        } else {
            // Update choice text or other properties without changing correctness
            const updatedChoices = choices.map((choice, i) =>
                i === index ? { ...choice, [field]: value } : choice
            );
            setChoices(updatedChoices);
        }
    };

    const addChoice = () => {
        // Add a new choice with default values
        setChoices([...choices, { choiceText: '', isCorrect: false }]);
    };

    const handleDeleteChoice = (index) => {
        const updatedChoices = choices.filter((_, i) => i !== index);
        setChoices(updatedChoices);
    };

    const addQuestion = () => {

        if (!question || choices.some(choice => choice.choiceText === '')) {
            setErrMsg('Please fill out the question and all choices.');
            return;
        }

        if (!choices.some(choice => choice.isCorrect)) {
            setErrMsg('Select Correct Choice');
            return;
        }

        if (question && choices.every(choice => choice.choiceText)) {
            const newQuestion = {
                questionText: question,
                choices
            };

            // Update the values state to include the new question
            setValues(prevValues => ({
                ...prevValues,
                questions: [...prevValues.questions, newQuestion]
            }));

            // Clear the input fields and reset error message
            setQuestion('');
            setChoices([
                { choiceText: '', isCorrect: false },
                { choiceText: '', isCorrect: false },
                //{ choiceText: '', isCorrect: false },
            ]);
            setErrMsg('');

            // Log the updated values to the console
            console.log("Updated Values:", {
                ...values,
                questions: [...values.questions, newQuestion]
            });

        } else {
            setErrMsg('Please fill out the question and all choices.');
        }
    };

    const handleDeleteQuestion = (index) => {
        const updatedQuestions = values.questions.filter((_, i) => i !== index);
        setValues({ ...values, questions: updatedQuestions });
    };



    useEffect(() => {

        if (stepsData.name) {
            setValues({...stepsData,questions: stepsData.questions || []});
        }
    }, [stepsData])

    useEffect(() => {

        if (isSuccess && program) {
            handleNext(5);
        }

        if (isError && error) {
            console.log('Error-----', isError, error);
        }

    }, [isSuccess, program, isError, error])


    const submitStep = async () => {
        setErrMsg('');
        //const {amount, compareAmount} = values;
        const data = {...stepsData, ...values};
        setStepsData(data);
        savePlan(data);
    };
    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const {t} = useTranslation();
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
        >
            <FormControl sx={{ width: '100%',mt:2 }}>
                <TextField
                    required
                    label="Question"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                />
            </FormControl>
            {choices.map((choice, index) => (
                <FormControl key={index} sx={{ width: '100%', mt: 1.5 }}>
                    <TextField
                        required
                        label={`Choice ${index + 1}`}
                        value={choice.choiceText}
                        onChange={(e) => handleChoiceChange(index, 'choiceText', e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Checkbox
                                        checked={choice.isCorrect}
                                        onChange={(e) => handleChoiceChange(index, 'isCorrect', e.target.checked)}
                                        color="primary"
                                        sx={{ padding: 0 }}
                                    />
                                    {index >= defaultChoices.length && (
                                        <IconButton
                                            onClick={() => handleDeleteChoice(index)}
                                            color="error"
                                            sx={{ padding: 0 }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            ))}
            <Div sx={{ mt: 2, ml: 1 }}>
                <Button onClick={addChoice} variant="outlined" size="small">
                    Add Choice
                </Button>
            </Div>
            <Div sx={{ mt:2,ml:1}}>
                <Button onClick={addQuestion}>
                    Add Question
                </Button>
            </Div>
                <Div sx={{ mt: 2,textAlign:'center' }}>
                   {errMsg && <p style={{ color: 'red' }}>{errMsg}</p>}
                </Div>
            <Table sx={{ mt: 3 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Question</TableCell>
                        <TableCell>Choices</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {values.questions.map((q, index) => (
                        <TableRow key={index}>
                            <TableCell>{q.questionText}</TableCell>
                            <TableCell>
                                {q.choices.map((choice, i) => (
                                    <div key={i}>
                                        {choice.choiceText} {choice.isCorrect && "(Correct)"}
                                    </div>
                                ))}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={() => handleDeleteQuestion(index)}
                                    color="error"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Div sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    onClick={() => {
                        handleNext(2);
                    }}
                    sx={{ mr: 1 }}
                >
                    {t("common.backBtn")}
                </Button>
                <Button
                    onClick={() => {
                        hideDialog();
                    }}
                    sx={{ mr: 1 }}
                >
                    {t("common.cancelBtn")}
                </Button>
                <Div sx={{ flex: '1 1 auto' }} />
                <LoadingButton
                    loading={isProgramSaving}
                    variant={"contained"}
                    onClick={submitStep}
                >
                    {values.quizId ? t("common.updateBtn") : t("admin-plans.title.createButton")}
                </LoadingButton>
            </Div>
        </Box>);

};

export default Step4;
