import React, { useEffect } from "react";
import {
  Alert,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import authServices from "../../../services/auth-services";
import { useNavigate } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useDispatch, useSelector } from "react-redux";
import { useAppMutation } from "../../../services";
import { onSuccessLogin } from "../../../redux/actions";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";

const Login = ({ disableSmLogin }) => {
  const { mutate, error, isError, data, isSuccess, isLoading } = useAppMutation(
    authServices.adminSignIn,
  );
  const { setAuthToken } = useJumboAuth();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = React.useState("");
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (data) {
      setAuthToken(data.token);
      dispatch(onSuccessLogin(data));
      // navigate('/dashboard');
      window.location = "/dashboard/calendar";
    }

    if (isError && error) {
      setErrMsg(t("common.signInErrorMsg"));
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
    }
  }, [data, isSuccess, isError]);

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email(t("common.emailErrorMsg"))
      .required(t("common.emailRequired")),
    password: yup
      .string("Enter your password")
      .required(t("admin-signIn.title.passwordRequired")),
  });

  const onSignIn = async (email, password) => {
    /*authServices.signIn({email, password})
            .then((data) => {
                setAuthToken(data?.token);
                navigate("/dashboard");
            });*/

    mutate({ email, password });
  };

  return (
    <Div
      sx={{
        width: 720,
        maxWidth: "100%",
        margin: "auto",
        p: 4,
      }}
    >
      <Card
        sx={{
          display: "flex",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent
          sx={{
            flex: "0 1 300px",
            position: "relative",
            background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/teacher.jpg`, "640x428")}) no-repeat center`,
            backgroundSize: "cover",

            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              backgroundColor: alpha("#0267a0", 0.65),
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              color: "common.white",
              position: "relative",
              zIndex: 1,
              height: "100%",
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={"h3"}
                color={"inherit"}
                fontWeight={500}
                mb={3}
              >
                {t("admin-signIn.title.signInHeading")}
              </Typography>
              <Typography variant={"body1"} mb={2}>
                {t("admin-signIn.title.signInSubHeading")}
              </Typography>
              <Typography variant={"body1"}>
                <Link
                  href="/admin/forgotPassword"
                  color={"inherit"}
                  underline={"none"}
                >
                  {t("common.passwordForgot")}
                </Link>
              </Typography>
            </Div>

            <Stack direction="column" spacing={2}>
              {/**/}
              <Button
                variant="outlined"
                size="small"
                startIcon={<SupervisorAccountOutlinedIcon />}
                sx={{
                  width: "200px",
                  backgroundColor: "transparent",
                  borderColor: "#454545",
                }}
                href="/student/login"
              >
                {t("admin-signIn.title.loginAsStudent")}
              </Button>
              <Button
                variant="outlined"
                size="small"
                startIcon={<RecentActorsOutlinedIcon />}
                sx={{
                  width: "200px",
                  backgroundColor: "transparent",
                  borderColor: "#454545",
                }}
                href="/teacher/login"
              >
                {t("admin-signIn.title.loginAsTeacher")}
              </Button>
            </Stack>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          <Div
            sx={{
              mb: 1.5, // Adjust margin as needed
              display: "flex",
              justifyContent: "center",
              mt: -2,
            }}
          >
            <Link href="/" underline="none" sx={{ display: "inline-flex" }}>
              <img
                src={`${ASSET_IMAGES}/logo.png`}
                alt="Jumbo React"
                width="150"
              />
            </Link>
          </Div>

          <Div
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant={"h6"} mb={1.5}>
              {t("sidebar.menuItem.administrator")}
            </Typography>
          </Div>

          <Formik
            validateOnChange={true}
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              onSignIn(data.email, data.password);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboTextField
                    fullWidth
                    name="email"
                    label={t("common.email")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Div>
                <Div sx={{ mt: 1, mb: 2 }}>
                  <JumboTextField
                    fullWidth
                    name="password"
                    label={t("common.password")}
                    type="password"
                    InputLabelProps={{ shrink: true }}
                  />
                </Div>
                {/*  <Div sx={{mb: 2}}>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            sx={{
                                                color: 'gray',
                                                '&.Mui-checked': {
                                                    color: '#454545',
                                                },
                                            }}
                                        />
                                    }
                                        label={t('admin-signIn.title.rememberMe')}
                                    />
                                </Div> */}

                <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
                  {errMsg && <Alert severity="error">{errMsg}</Alert>}
                </Stack>

                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isLoading}
                >
                  {t("common.signInBtn")}
                </LoadingButton>

                {!disableSmLogin && (
                  <React.Fragment>
                    <Typography variant={"body1"} mb={2}>
                      {t("admin-signIn.title.signInWith")}
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      mb={1}
                    >
                      <IconButton
                        sx={{
                          bgcolor: "#385196",
                          color: "common.white",
                          p: (theme) => theme.spacing(1.25),

                          "&:hover": {
                            backgroundColor: "#385196",
                          },
                        }}
                        aria-label="Facebook"
                      >
                        <Facebook fontSize="small" />
                      </IconButton>
                      <IconButton
                        sx={{
                          bgcolor: "#00a8ff",
                          color: "common.white",
                          p: (theme) => theme.spacing(1.25),

                          "&:hover": {
                            backgroundColor: "#00a8ff",
                          },
                        }}
                        aria-label="Twitter"
                      >
                        <Twitter fontSize="small" />
                      </IconButton>
                      <IconButton
                        sx={{
                          bgcolor: "#23272b",
                          color: "common.white",
                          p: (theme) => theme.spacing(1.25),

                          "&:hover": {
                            backgroundColor: "#23272b",
                          },
                        }}
                        aria-label="Twitter"
                      >
                        <Google fontSize="small" />
                      </IconButton>
                    </Stack>
                  </React.Fragment>
                )}
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
      <Div
        sx={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          py: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant={"body1"}
          color={"text.primary"}
          style={{ fontSize: "0.7rem" }}
        >
          Copyright DELIVERY Digital © 2024 Powered By{" "}
          <a href="https://deliverydigital.fr/" target="_blank">
            DELIVERY Digital
          </a>
        </Typography>
      </Div>
    </Div>
  );
};

export default Login;
