import React from "react";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import Header from "./header";
import { BookingProvider, useBookingContext } from "./context";
import CalendarBasic from "./calendars/CalendarBasic";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import BookingForm from "./BookingForm";
import { useTranslation } from "react-i18next";

const Children = () => {
  return (
    <BookingProvider>
      <InnerChildren />
    </BookingProvider>
  );
};

const InnerChildren = () => {
  const { globalState, setGlobalState } = useBookingContext();
  const { t } = useTranslation();

  return (
    <JumboContentLayout
      layoutOptions={{}}
      //sidebar={<Sidebar/>}
      header={
        <Header
          title={t("parent.title.bookingTitle")}
          //subheader={t("parent.title.bookingSubheader")}
        />
      }
    >
      <CalendarBasic />

      <Dialog open={globalState.addEditBooking}>
        <DialogTitle>{t("calendar.bookingForm")}</DialogTitle>
        <DialogContent>
          <BookingForm />
        </DialogContent>
      </Dialog>
    </JumboContentLayout>
  );
};

export default Children;
