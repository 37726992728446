import React, {Suspense} from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import Header from "../shared/headers/Header";
import Sidebar from "../shared/sidebars/Sidebar";
import Footer from "../shared/footers/Footer";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import JumboCustomizer from "../../shared/JumboCustomizer/JumboCustomizer";
import JumboCustomizerButton from "../../shared/JumboCustomizer/JumboCustomizerButton";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {SIDEBAR_STYLES} from "@jumbo/utils/constants";
import {headerTheme as theme4} from "../../themes/header/theme4";
import {headerTheme as defaultTheme} from "../../themes/header/default";
import useApp from "../../hooks/useApp";
import layoutConfig from "./layoutConfig";
import {useSelector} from "react-redux";
import ParentProfileSteps from "../../pages/parent/ParentProfileSteps";
import StudentProfileSteps from "../../pages/student/StudentProfileSteps";
import Div from "@jumbo/shared/Div";
import {CircularProgress} from "@mui/material";
import TeacherProfileSteps from "../../pages/teacher/TeacherProfileSteps";

const VerticalDefault = ({children}) => {
    console.log('children', children)
    const {setJumboLayoutOptions} = useJumboLayout();
    const {headerTheme, setHeaderTheme} = useJumboHeaderTheme();
    const {theme} = useJumboTheme();
    const appBarBgColor = headerTheme.components?.MuiAppBar?.styleOverrides?.root?.background;
    const {sidebarOptions} = useJumboLayoutSidebar();
    const appData = useApp();
    const {setSidebarOptions} = useJumboLayoutSidebar();
    const {
        isStudent,
        isSuperAdmin,
        isTeacher,
        authUser
    } = useSelector(state => state.auth);


    React.useEffect(() => {
        setJumboLayoutOptions(layoutConfig)
        setSidebarOptions({hideSideMenu: false})
    }, [children]);


    React.useEffect(() => {

        if (appBarBgColor === "#F5F7FA" && sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER) {
            setHeaderTheme({...theme, ...theme4});
            appData.setAppState({
                prevHeaderBgColor: theme?.mode === "dark" ? "#222D45" : "#F5F7FA",
            });
        } else if (appData.prevHeaderBgColor && appData.prevHeaderBgColor === "#F5F7FA") {
            setHeaderTheme({...theme, ...defaultTheme});
        }

    }, [sidebarOptions.style]);


    return (
        <JumboLayout
            header={<Header/>}
            sidebar={<Sidebar/>}
            footer={<Footer/>}
            headerSx={{
                height: 80,
            }}
        >
            {authUser && isStudent && authUser?.completedStep1 && authUser?.completedStep2 && authUser?.completedStep3 && (children)}
            {authUser && isStudent && (!authUser?.completedStep1 || !authUser?.completedStep2 || !authUser?.completedStep3) && (
                <Suspense
                    fallback={
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                alignContent: 'center',
                                height: '100%',
                            }}
                        >
                            <CircularProgress sx={{m: '-40px auto 0'}}/>
                        </Div>
                    }
                ><StudentProfileSteps/></Suspense>)}

            {authUser && isTeacher && authUser?.completedStep1 && authUser?.completedStep2 && authUser?.completedStep3 && authUser?.completedStep4 && (children)}
            {authUser && isTeacher && (!authUser?.completedStep1 || !authUser?.completedStep2 || !authUser?.completedStep3 || !authUser?.completedStep4) && (<Suspense
                fallback={
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            alignItems: 'center',
                            alignContent: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress sx={{m: '-40px auto 0'}}/>
                    </Div>
                }
            ><TeacherProfileSteps/></Suspense>)}


            {authUser && isSuperAdmin && (children)}
            {!authUser && (children)}

            <JumboCustomizer/>
            <JumboCustomizerButton/>
        </JumboLayout>
    );
};

export default VerticalDefault;
