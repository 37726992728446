import {createContext, useContext, useState} from 'react';

const RequestsContext = createContext({});

export const useRequestsContext = () => {
    return useContext(RequestsContext);
}

export const RequestsProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({ refreshRequestsList : false});

    return <RequestsContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </RequestsContext.Provider>
}
