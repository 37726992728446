import {createContext, useContext, useState} from 'react';

const QuizContext = createContext({});

export const useQuizContext = () => {
    return useContext(QuizContext);
}

export const QuizProvider = ({children}) => {
    const [globalState, setGlobalState] = useState({ refreshQuizList : false});

    return <QuizContext.Provider value={{globalState, setGlobalState}}>
        {children}
    </QuizContext.Provider>
}
