import Div from "@jumbo/shared/Div";
import React from "react";
import {Button, FormControl, Rating, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTranslation} from "react-i18next";
import {useAppMutation} from "../../../../../services";
import adminServices from "../../../../../services/admin-services";


const Step2 = ({bookingId, hide, teacherFeedback, handleNext, showBookingForm}) => {


    const [ratings, setRatings] = React.useState(teacherFeedback?.ratings);
    const {t} = useTranslation();

    const {
        mutate,
        isSuccess,
        isLoading,
        isError,
        data
    } = useAppMutation(adminServices.saveBookingFeedback);


    React.useEffect(() => {

        if (isSuccess) {
            handleNext(4)
        }

    }, [isSuccess, isError])
    const handleComplete = () => {
        mutate({bookingId, teacherFeedback: {...teacherFeedback, ratings}})
    }


    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Div sx={{mt: 3}}>
            <FormControl noValidate autoComplete="off" fullWidth>
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 1.4,
                            mt: 0.5
                        },
                    }}
                >
                    <FormControl fullWidth sx={{mt: 0.5,}}>
                        <Div fullWidth sx={{'& > legend': {mt: 2}}}
                        > {ratings && ratings.map((item, index) => {
                            return <><Typography component="legend">{t('feedback.ratingLabel' + item.key)}</Typography>
                                <Rating
                                    name="simple-controlled"
                                    value={item.value}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            item.value = newValue;
                                            setRatings([...ratings]);
                                        }
                                    }}
                                /></>
                        })}
                        </Div>


                    </FormControl>

                    <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                        <Button
                            onClick={() => {
                                hide()
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.closeBtn")}
                        </Button>
                        <Button
                            onClick={() => {
                                handleNext(2)
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.backBtn")}
                        </Button>
                        <Div sx={{flex: '1 1 auto'}}/>
                        <LoadingButton loading={isLoading} variant={"variant"} onClick={handleComplete}>
                            {t("common.saveBtn")}
                        </LoadingButton>
                    </Div>

                </Div>
            </FormControl>
        </Div>
    </Div>;

}

export default Step2;
