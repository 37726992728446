import appAxios from "./config";

const studentServices = {};

studentServices.loadProfileForSteps = async (obj) => {
    const {data} = await appAxios.get('v1/student/profile/steps');
    return data;
};


studentServices.signUp = async (obj) => {
    const {data} = await appAxios.post('v1/student/signup', obj);
    return data;
};

studentServices.forgotPassword = async (obj) => {
    const {data} = await appAxios.post('v1/student/forgot-password', obj);
    return data;
};


studentServices.resetForgotPassword = async (obj) => {
    const {data} = await appAxios.post('v1/student/reset-forgot-password', obj);
    return data;
};

studentServices.resetPassword = async (obj) => {
    const {data} = await appAxios.post('v1/student/reset-password', obj);
    return data;
};


studentServices.saveChild = async (obj) => {
    const {data} = await appAxios.post('v1/student/child', obj);
    return data;
};


studentServices.loadChildren = async () => {
    const {data} = await appAxios.post('v1/student/children', {});
    return data;
};


studentServices.loadEvaluatedChildren = async () => {
    let {data} = await appAxios.post('v1/student/children', {});
    data = data.filter(item => item.evaluated);
    return data;
};


studentServices.loadAvailableTeachers = async (obj) => {
    const {data} = await appAxios.post('v1/student/available-teachers', obj);
    return data;
};



studentServices.loadAvailableTeachersBySearch = async (obj) => {
    const {data} = await appAxios.post('v1/student/load-available-teachers-by-search', obj);
    return data;
};


studentServices.loadAvailableDates = async (obj) => {
    const {data} = await appAxios.post('v1/student/load-available-dates', obj);
    return data;
};


studentServices.loadAvailableDatesByTeacher = async (obj) => {
    const {data} = await appAxios.post('v1/student/load-available-dates-by-teacher', obj);
    return data;
};


studentServices.cancelBooking = async (obj) => {
    const {data} = await appAxios.post('v1/student/booking/cancel', obj);
    return data;
};


studentServices.loadAvailableTimes = async (obj) => {
    const {data} = await appAxios.post('v1/student/load-available-times', obj);
    return data;
};



studentServices.loadAvailableTimesByTeacher = async (obj) => {
    const {data} = await appAxios.post('v1/student/load-available-times-by-teacher', obj);
    return data;
};


studentServices.saveAppointment = async (obj) => {
    const {data} = await appAxios.post('v1/student/appointment', obj);
    return data;
};


studentServices.cancelAppointment = async (obj) => {
    const {data} = await appAxios.post('v1/student/appointment/cancel', obj);
    return data;
};


studentServices.saveBooking = async (obj) => {
    const {data} = await appAxios.post('v1/student/booking', obj);
    return data;
};


studentServices.deleteAppointment = async (appointmentId) => {
    const {data} = await appAxios.delete('v1/student/appointment/' + appointmentId);
    return data;
};


studentServices.loadAppointments = async (obj) => {
    const {data} = await appAxios.post('v1/student/appointments', obj);
    return data;
};

studentServices.loadBookings = async (obj) => {
    const {data} = await appAxios.post('v1/student/bookings', obj);
    return data;
};


studentServices.loadActiveSubscriptions = async (obj) => {
    const {data} = await appAxios.post('v1/student/active-subscriptions', obj);
    return data;
};


studentServices.loadBooking = async (bookingId) => {
    const {data} = await appAxios.get('v1/student/booking/' + bookingId);
    return data;
};


studentServices.loadAppointment = async (appointmentId) => {
    const {data} = await appAxios.get('v1/student/appointment/' + appointmentId);
    return data;
};

studentServices.loadChild = async (childrenId) => {
    const {data} = await appAxios.get('v1/student/child/' + childrenId);
    return data;
};


studentServices.deleteChild = async (childrenId) => {
    const {data} = await appAxios.delete('v1/student/child/' + childrenId);
    return data;
};


studentServices.saveProfileImage = async (base64) => {
    const {data} = await appAxios.post('v1/student/profile/image', {base64});
    return data;
};

studentServices.completeStep1 = async (obj) => {
    const {data} = await appAxios.post('v1/student/completestep1', obj);
    return data;
};


studentServices.completeStep2 = async (obj) => {
    const {data} = await appAxios.post('v1/student/completestep2', obj);
    return data;
};


studentServices.completeStep3 = async (obj) => {
    const {data} = await appAxios.post('v1/student/completestep3', obj);
    return data;
};


studentServices.loadProgram = async (programId) => {
    const {data} = await appAxios.get('v1/student/program/' + programId);
    return data;
};


studentServices.loadPlans = async (obj) => {
    const {data} = await appAxios.post('v1/student/plans', obj);
    return data;
};



studentServices.isEligibleForDiscount = async (obj) => {
    const {data} = await appAxios.post('v1/student/eligible-for-discount', obj);
    return data;
};


studentServices.completePayment = async ({paymentIntent, clientSecret}) => {
    const {data} = await appAxios.get('v1/student/complete/payment/' + paymentIntent + '/' + clientSecret);
    return data;
};


studentServices.loadSubscriptions = async (obj) => {
    const {data} = await appAxios.post('v1/student/subscriptions', obj);
    return data;
};


studentServices.loadSecret = async (obj) => {
    const {data} = await appAxios.post('v1/student/secret', obj);
    return data;
};

studentServices.loadEnrollmentInfo = async () => {
    const {data} = await appAxios.get('v1/student/children/enrollmentinfo');
    return data;
};

studentServices.loadProfile = async () => {
    const {data} = await appAxios.get('v1/student/profile');
    return data;
};

studentServices.updateProfileAddressDetails = async (obj) => {
    const {data} = await appAxios.post('v1/student/profile/address-details', obj);
    return data;
};

studentServices.updateProfileContactInfo = async (obj) => {
    const {data} = await appAxios.post('v1/student/profile/contact-info', obj);
    return data;
};

studentServices.updateProfileNewsLetterSubscription = async (obj) => {
    const {data} = await appAxios.post('v1/student/profile/news-letter-subscription', obj);
    return data;
};

studentServices.cancelSubscription = async (obj) => {
    const {data} = await  appAxios.post('v1/student/cancel-subscription',obj);
    return data;
}
studentServices.showDiscountCode = async (obj) => {
    const {data} = await  appAxios.post('v1/student/show-discount-code',obj);
    return data;
}
export default studentServices;
