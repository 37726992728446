import React, {useEffect} from 'react';
import {useAppMutation} from "../../../services";
import parentServices from "../../../services/parent-services";
import {useNavigate} from "react-router-dom";

const ParentCompletePayment = () => {

    const navigate = useNavigate();
    const {mutate, isError, data, isSuccess, isLoading} = useAppMutation(parentServices.completePayment);

    React.useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        console.log(params.get('payment_intent'),
            params.get('payment_intent_client_secret'),
            params.get('redirect_status')
        );
        if (params.get('redirect_status') === 'succeeded') {
            mutate({
                paymentIntent: params.get('payment_intent'),
                clientSecret: params.get('payment_intent_client_secret')
            });
        } else {
            window.location = '/dashboard/courses';
        }

    }, [])


    useEffect(() => {
        console.log(isSuccess, data, isError);

        if (isSuccess) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'ParentPurchased',
                ...data
            });
            navigate('/student/payment/success');

        } else if (isError) {
            navigate('/student/payment/error');
        }
    }, [isSuccess, isError]);

    return null;
};

export default ParentCompletePayment;
