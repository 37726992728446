import Div from "@jumbo/shared/Div";
import React from "react";
import {Alert, Button, Divider, FormControl, TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import {useAppMutation} from "../../../../services";
import teacherServices from "../../../../services/teacher-services";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from '@mui/icons-material/Save';
import {useTranslation} from "react-i18next";


const steps = ['Basic Info', 'Complete', 'Finish'];

const Step1 = ({appointment,    handleNext, hide, setAppointment}) => {

    const [values, setValues] = React.useState({});
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const {
        mutate,
        isSuccess,
        isError,
        isLoading,
        data
    } = useAppMutation(teacherServices.saveStudentEvaluation);

    React.useEffect(() => {

        if (appointment) {
            setValues({...appointment})
        }

    }, [appointment])


    React.useEffect(() => {

        if (isSuccess) {
            handleNext({step: 4})
        }
        if(isError){
            setErrMsg('Error in Saving....');
        }

    }, [isError, isSuccess])


    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleComplete = () => {

        mutate({...appointment})


    }


    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>

        <Div sx={{mt: 3}}>

            <FormControl noValidate autoComplete="off">
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 1.4,
                            mt: 0.5
                        },
                    }}
                >


                    {appointment?.cancel && (
                        <Div sx={{mt: 2}}>
                            <Typography variant="h5" gutterBottom component="div"
                                        sx={{pr: 10, pl: 10, textAlign: 'center'}}
                            >
                                {t("teacher-appointment-form.title.txt1")}
                            </Typography>

                            <FormControl fullWidth>
                                <TextField
                                    label={t("teacher-appointment-form.title.reason")}
                                    type="text"
                                    rows={3}
                                    multiline
                                    value={values.reason}
                                    sx={{width: "100%"}}
                                    onChange={handleChange('reason')}
                                />
                            </FormControl>

                        </Div>
                    )}

                    {!appointment?.cancel && (
                        <Div>

                            <Typography variant="h5" gutterBottom component="div"
                                        sx={{pr: 10, pl: 10, textAlign: 'center'}}
                            >
                                {t('appointmentForm.txt')}
                            </Typography>

                            <Divider sx={{backgroundColor: 'lightgrey', height: 1.5, mb: 2, mx: 'auto', width: '75%'}}/>


                            <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center', mt: 2}}>
                                {t('appointmentForm.overall')}: {values.evaluation?.overall ?  values.evaluation?.overall
                                : '---'}
                            </Typography>

                            <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center', mt: 1}}>
                                {t('appointmentForm.personality')}: {values.evaluation?.personality && values.evaluation?.personality.length > 0
                                ? values.evaluation?.personality
                                : '---'}
                            </Typography>

                            <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                                {t('appointmentForm.actualLevel')}: {values.evaluation?.actualLevel && values.evaluation?.actualLevel.length > 0
                                ? values.evaluation?.actualLevel
                                : '---'}
                            </Typography>

                            <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                                {t('appointmentForm.objectiveLevel')}: {values.evaluation?.objectiveLevel && values.evaluation?.objectiveLevel.length > 0
                                ? values.evaluation?.objectiveLevel
                                : '---'}
                            </Typography>

                            <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                                {t('appointmentForm.learningStyle')}: {values.evaluation?.learningStyles && values.evaluation?.learningStyles.length > 0
                                ? values.evaluation?.learningStyles.join(', ')
                                : '---'}
                            </Typography>

                            <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                                {t('appointmentForm.needs')}: {values.evaluation?.needsLinguistic && values.evaluation?.needsLinguistic.length > 0
                                ? values.evaluation?.needsLinguistic
                                : '---'}
                            </Typography>

                            <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                                {t('appointmentForm.booksRec')}: {values.evaluation?.booksRecommended && values.evaluation?.booksRecommended.length > 0
                                ? values.evaluation?.booksRecommended
                                : '---'}
                            </Typography>

                            <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                                {t('appointmentForm.teacherRec')}:  {values.evaluation?.teachersRecommended && values.evaluation?.teachersRecommended.length > 0
                                ? values.selectedTeachersName
                                : 'No teachers recommended'}
                            </Typography>

                            <Typography variant="body1" gutterBottom component="div" sx={{textAlign: 'center'}}>
                                {t('appointmentForm.program')}: {values.evaluation?.programFrequencyRecommended && values.evaluation?.programFrequencyRecommended.length > 0
                                ? values.evaluation?.programFrequencyRecommended
                                : '---'}
                            </Typography></Div>)}


                    <Stack
                        sx={{
                            mb: 2,
                            width: '90%',
                            mx: 'auto',
                            display: 'block',
                            position: 'relative', // Add position relative to enable absolute positioning of loader
                        }}
                        spacing={2}>
                        {errMsg && <Alert severity="error">{errMsg}</Alert>}
                    </Stack>

                    <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                        <Button
                            onClick={() => {
                                hide()
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.closeBtn")}
                        </Button>
                        <Button
                            onClick={() => {

                                if (appointment.cancelled) {
                                    setAppointment({...appointment, cancel: false})
                                    handleNext({step: 1})

                                } else {
                                    handleNext({step: 2})

                                }
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.backBtn")}
                        </Button>
                        <Div sx={{flex: '1 1 auto'}}/>

                        <LoadingButton
                            variant="variant"
                            loading={isLoading}
                            onClick={handleComplete}
                        >
                            {t("common.finishBtn")}
                        </LoadingButton>

                    </Div>

                </Div>
            </FormControl>
        </Div>
    </Div>;

}

export default Step1;
