import React from "react";
import Div from "@jumbo/shared/Div";
import {
    Alert,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    TextField
} from "@mui/material";
import Select from "@mui/material/Select";
import {actualLevels, learningStyle, objectiveLevel, programFrequency} from "../../../../utils/constants/appActions";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import {useTranslation} from "react-i18next";
import {useCalendarContext} from "../context";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Evaluation = ({showForm, teachers, evalutation}) => {

    const [errMsg, setErrMsg] = React.useState('');
    const {globalState, setGlobalState} = useCalendarContext();
    const {t} = useTranslation();
    const [loaded, setLoaded] = React.useState(false);

    const [values, setValues] = React.useState({});
    const [appointmentId, setAppointmentId] = React.useState(undefined);
    const {
        mutate,
        isSuccess,
        isError,
        isLoading,
        data
    } = useAppMutation(adminServices.saveStudentEvaluation);


    React.useEffect(() => {

        if (!loaded) {
            console.log(evalutation)
            if (evalutation) {
                setValues({...evalutation})
            } else {
                setValues({
                    personality: '',
                    actualLevel: '',
                    objectiveLevel: '',
                    learningStyles: [],
                    needsLinguistic: '',
                    booksRecommended: '',
                    teachersRecommended: [],
                    programFrequencyRecommended: ''
                });
            }
            setLoaded(true);
        }
    }, [loaded])

    React.useEffect(() => {

        if (globalState.appointment?.appointmentId) {
            setAppointmentId(globalState.appointment.appointmentId)
        }

    }, [globalState])

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    React.useEffect(() => {

        if (isSuccess) {
            setGlobalState({...globalState, refreshList: true, appointment: undefined, addEditAppointment: false})

        }

        if (isError) {
            setErrMsg(t('errMsg.evaluation'));
        }

    }, [isSuccess, isError])

    const handleChangeMultiple = (name) => (event) => {
        const {
            target: {value},
        } = event;

        // Use the name prop to dynamically set the state
        setValues((prevValues) => ({
            ...prevValues,
            [name]: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    const getTeacherNameByIds = (teacherIds) => {

        let name = '';
        if (teachers) {
            for (const id of teacherIds) {
                const teacher = teachers.find(t => t.teacherId === id)

                if(!teacher){
                    continue;
                }
                if (name) {
                    name += ', ' + teacher.firstName + ' ' + teacher.lastName
                } else {
                    name += teacher.firstName + ' ' + teacher.lastName
                }
            }
        }

        return name;

    }


    const handleSave = () => {
        if (values.personality &&
            values.actualLevel &&
            values.objectiveLevel &&
            values.learningStyles &&
            values.needsLinguistic &&
            values.booksRecommended &&
            values.teachersRecommended &&
            values.programFrequencyRecommended
        ) {
            console.log("Complete Form Values----", values);
            const evaluation = {...values}
            mutate({appointmentId, evaluation});

        } else {
            setErrMsg(t("admin-appointments.title.errorMsg"));
            setTimeout(() => {
                setErrMsg("");
            }, 5000);
        }
    };


    if (!loaded) {
        return null
    }

    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Div sx={{mt: 3}}>
            <FormControl noValidate autoComplete="off">
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 2,
                            mt: 0.5
                        },
                    }}
                >


                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t("appointmentForm.personality")}
                            fullWidth
                            value={values?.personality}
                            onChange={handleChange('personality')}
                        />
                    </FormControl>


                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel
                            id="demo-simple-select-label">{t("appointmentForm.actualLevel")}</InputLabel>
                        <Select labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values?.actualLevel}
                                label={t('appointmentForm.actualLevel')}
                                onChange={handleChange('actualLevel')}
                        >
                            {actualLevels?.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel
                            id="demo-simple-select-label">{t("admin-appointments.title.objectiveLevel")}</InputLabel>
                        <Select labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values?.objectiveLevel}
                                label={t("admin-appointments.title.objectiveLevel")}
                                onChange={handleChange('objectiveLevel')}
                        >
                            {objectiveLevel?.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{mb: 1.5}}>
                        <InputLabel
                            id="demo-simple-select-label">{t("admin-appointments.title.learningStyle")}</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={values?.learningStyles}
                            label={t("admin-appointments.title.learningStyle")}
                            onChange={handleChangeMultiple('learningStyles')}
                            multiple
                            input={<OutlinedInput label="Learning Style"/>}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {learningStyle?.map((item) => (
                                <MenuItem key={item} value={item}>
                                    <Checkbox
                                        checked={values?.learningStyles?.indexOf(item) > -1}
                                        onChange={(event) => {
                                            const checked = event.target.checked;
                                            handleChangeMultiple('learningStyle')({
                                                target: {
                                                    value: checked
                                                        ? [...values.learningStyles, item]
                                                        : values.learningStyles.filter((value) => value !== item),
                                                },
                                            });
                                        }}
                                    />
                                    <ListItemText primary={item}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t("admin-appointments.title.needsLinguistic")}
                            fullWidth
                            value={values.needsLinguistic}
                            onChange={handleChange('needsLinguistic')}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t("admin-appointments.title.books")}
                            fullWidth
                            value={values.booksRecommended}
                            onChange={handleChange('booksRecommended')}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mb: 1.5}}>
                        <InputLabel
                            id="demo-simple-select-label">{t("admin-appointments.title.teacherRec")}</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={values.teachersRecommended}
                            onChange={handleChangeMultiple('teachersRecommended')}
                            multiple
                            input={<OutlinedInput label={t("admin-appointments.title.teacherRec")}/>}
                            renderValue={(selected) => {
                                return getTeacherNameByIds(selected);
                            }}
                            MenuProps={MenuProps}
                        >
                            {teachers && teachers.map((item, index) => (
                                <MenuItem key={'key-teacher' + index} value={item.teacherId}>
                                    <Checkbox
                                        checked={values?.teachersRecommended?.indexOf(item.teacherId) > -1}
                                    />
                                    <ListItemText primary={item.firstName + ' ' + item.lastName}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{mb: 1.5}}>
                        <InputLabel
                            id="demo-simple-select-label">{t("admin-appointments.title.programRec")}</InputLabel>
                        <Select labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values?.programFrequencyRecommended}
                                label={t("admin-appointments.title.programRec")}
                                onChange={handleChange('programFrequencyRecommended')}
                        >
                            {programFrequency.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Stack
                        sx={{
                            mb: 2,
                            width: '90%',
                            mx: 'auto',
                            display: 'block',
                            position: 'relative', // Add position relative to enable absolute positioning of loader
                        }}
                        spacing={2}>
                        {errMsg && <Alert severity="error">{errMsg}</Alert>}
                    </Stack>

                    <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                        <Button
                            onClick={() => {
                                setGlobalState({...globalState, appointment: undefined, addEditAppointment: false})
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.closeBtn")}
                        </Button>
                        <Button
                            onClick={() => {
                                showForm();
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.backBtn")}
                        </Button>
                        <Div sx={{flex: '1 1 auto'}}/>

                        <LoadingButton variant={"variant"} onClick={handleSave}>
                            {t("common.saveBtn")}
                        </LoadingButton>

                    </Div>


                </Div>
            </FormControl>
        </Div>
    </Div>;
}


export default Evaluation;
