import Div from "@jumbo/shared/Div";
import React from "react";
import {
    Alert,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    TextField
} from "@mui/material";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from "@mui/material/Select";
import {actualLevels, learningStyle, objectiveLevel, programFrequency} from "../../../../utils/constants/appActions";
import {useAppMutation} from "../../../../services";
import teacherServices from "../../../../services/teacher-services";
import {useTranslation} from "react-i18next";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const teachers1 = [
    'Kamran',
    'Tayyab',
    'ShahAlam',
    'Danish',
];

const Step1 = ({appointment, handleNext, hide, setAppointment}) => {

    const {
        mutate: loadTeacher,
        isSuccess: isTSuccess,
        isError: isTError,
        data: tData
    } = useAppMutation(teacherServices.loadTeachers);
    const [values, setValues] = React.useState({
        personality: '',
        actualLevel: '',
        objectiveLevel: '',
        learningStyles: [],
        needsLinguistic: '',
        booksRecommended: '',
        teachersRecommended: [],
        programFrequencyRecommended: '',
        overall: ''
    });
    const [errMsg, setErrMsg] = React.useState('');
    const [teachers, setTeacher] = React.useState('');
    const {t} = useTranslation();


    React.useEffect(() => {

        console.log('appointment---------------', appointment);
        if (appointment.evaluation) {
            setValues({...appointment.evaluation})
        }
    }, [appointment])


    React.useEffect(() => {

        if (tData && isTSuccess) {
            setTeacher(tData.teachers)
        } else if (isTError) {

        } else {
            loadTeacher({ page: 1, size : 1000})
        }


    }, [isTError, isTSuccess, tData])


    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const getTeacherNameByIds = (teacherIds) => {

        let name = '';
        if (teachers) {
            console.log(teacherIds,teachers)
            for (const id of teacherIds) {
                const teacher = teachers.find(t => t.teacherId === id)
                if (!teacher) {
                    continue;
                }
                if (name) {
                    name += ', ' + teacher?.firstName + ' ' + teacher?.lastName
                } else {
                    name += teacher?.firstName + ' ' + teacher?.lastName
                }
            }

        }

        return name;

    }


    const handleSave1 = () => {

        setAppointment({...appointment, ...values})


        handleNext({step: 3})

    };

    const handleSave = () => {
        if (values.personality &&
            values.actualLevel &&
            values.objectiveLevel &&
            values.learningStyles &&
            values.needsLinguistic &&
            values.booksRecommended &&
            values.teachersRecommended &&
            values.overall &&
            values.programFrequencyRecommended
        ) {
            console.log("Complete Form Values----", values);
            const evaluation = {...values}
            setAppointment({
                ...appointment, evaluation,
                selectedTeachersName: getTeacherNameByIds(values.teachersRecommended)
            })
            console.log("Appointment----", appointment);
            handleNext({step: 3})

        } else {
            setErrMsg(t("common.inputError"));
            setTimeout(() => {
                setErrMsg("");
            }, 5000);
        }
    };


    const handleChangeMultiple = (name) => (event) => {
        const {
            target: {value},
        } = event;

        // Use the name prop to dynamically set the state
        setValues((prevValues) => ({
            ...prevValues,
            [name]: typeof value === 'string' ? value.split(',') : value,
        }));
    };


    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>
        <Div sx={{mt: 3}}>
            <FormControl noValidate autoComplete="off">
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 2,
                            mt: 0.5
                        },
                    }}
                >

                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t('appointmentForm.overall')}
                            fullWidth
                            value={values.overall}
                            onChange={handleChange('overall')}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t("appointmentForm.personality")}
                            fullWidth
                            value={values.personality}
                            onChange={handleChange('personality')}
                        />
                    </FormControl>


                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel id="demo-simple-select-label">{t("appointmentForm.actualLevel")}</InputLabel>
                        <Select labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.actualLevel}
                                label={t("appointmentForm.actualLevel")}
                                onChange={handleChange('actualLevel')}
                        >
                            {actualLevels.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel
                            id="demo-simple-select-label">{t("teacher-appointment-form.title.objectiveLevel")}</InputLabel>
                        <Select labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.objectiveLevel}
                                label={t("teacher-appointment-form.title.objectiveLevel")}
                                onChange={handleChange('objectiveLevel')}
                        >
                            {objectiveLevel.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{mb: 1.5}}>
                        <InputLabel id="demo-simple-select-label">{t("appointmentForm.learningStyle")}</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={values.learningStyles}
                            label={t("appointmentForm.learningStyle")}
                            onChange={handleChangeMultiple('learningStyles')}
                            multiple
                            input={<OutlinedInput label="Learning Style"/>}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {learningStyle.map((item) => (
                                <MenuItem key={item} value={item}>
                                    <Checkbox
                                        checked={values.learningStyles.indexOf(item) > -1}
                                        onChange={(event) => {
                                            const checked = event.target.checked;
                                            handleChangeMultiple('learningStyle')({
                                                target: {
                                                    value: checked
                                                        ? [...values.learningStyles, item]
                                                        : values.learningStyles.filter((value) => value !== item),
                                                },
                                            });
                                        }}
                                    />
                                    <ListItemText primary={item}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t("appointmentForm.needs")}
                            fullWidth
                            value={values.needsLinguistic}
                            onChange={handleChange('needsLinguistic')}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t("appointmentForm.booksRec")}
                            fullWidth
                            value={values.booksRecommended}
                            onChange={handleChange('booksRecommended')}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mb: 1.5}}>
                        <InputLabel id="demo-simple-select-label">{t("appointmentForm.teacherRec")}</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={values.teachersRecommended}
                            onChange={handleChangeMultiple('teachersRecommended')}
                            multiple
                            input={<OutlinedInput label="Teachers Recommended"/>}
                            renderValue={(selected) => {
                                return getTeacherNameByIds(selected);
                            }}
                            MenuProps={MenuProps}
                        >
                            {teachers && teachers.map((item, index) => (
                                <MenuItem key={'key-teacher' + index} value={item.teacherId}>
                                    <Checkbox
                                        checked={values.teachersRecommended.indexOf(item.teacherId) > -1}
                                    />
                                    <ListItemText primary={item.firstName + ' ' + item.lastName}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{mb: 1.5}}>
                        <InputLabel id="demo-simple-select-label">{t("appointmentForm.program")}</InputLabel>
                        <Select labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.programFrequencyRecommended}
                                label={t("appointmentForm.program")}
                                onChange={handleChange('programFrequencyRecommended')}
                        >
                            {programFrequency.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Stack
                        sx={{
                            mb: 2,
                            width: '90%',
                            mx: 'auto',
                            display: 'block',
                            position: 'relative', // Add position relative to enable absolute positioning of loader
                        }}
                        spacing={2}>
                        {errMsg && <Alert severity="error">{errMsg}</Alert>}
                    </Stack>

                    <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                        <Button
                            onClick={() => {
                                hide()
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.closeBtn")}
                        </Button>
                        <Button
                            onClick={() => {
                                handleNext({step: 1})
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.backBtn")}
                        </Button>
                        <Div sx={{flex: '1 1 auto'}}/>


                        <LoadingButton variant={"variant"} onClick={handleSave}>
                            {t("common.nextBtn")}
                        </LoadingButton>

                    </Div>

                </Div>
            </FormControl>
        </Div>
    </Div>;

}

export default Step1;
