import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import {Alert, Box, Button, FormControl, InputLabel, Select, TextField,} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {useAppMutation} from "../../../../services";
import adminServices from "../../../../services/admin-services";
import {Form, Formik} from "formik";
import {useCoursesContext} from "../context";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import commonServices from "../../../../services/common-services";
import Autocomplete from "@mui/material/Autocomplete";

const Step4 = ({hideDialog, stepsData, handleNext, setStepsData}) => {

    // const [values, setValues] = React.useState({});
    const [errMsg, setErrMsg] = React.useState('');
    const {globalState, setGlobalState} = useCoursesContext();
    const {
        mutate: saveCourse,
        isError,
        isLoading: isProgramSaving,
        isSuccess,
        data: program,
        error
    } = useAppMutation(adminServices.saveCourse);

    const {
        mutate: searchQuizzes,
        isLoading: isSearchingQuiz,
        data: searchResult
    } = useAppMutation(adminServices.searchQuiz);

    const [quizzesData, setQuizzesData] = React.useState([]);
    const [selectedQuizzes, setSelectedQuizzes] = React.useState([]);

    const [values, setValues] = React.useState({
        amount: '',
        validity: '',
        compareAmount:'',
    });

    useEffect(() => {
            if (searchResult) {
                let quizzes = searchResult.quizzes;
                //alert(JSON.stringify(quizzes))
                if (selectedQuizzes && selectedQuizzes.length > 0) {
                    for (const q of selectedQuizzes) {

                        const index = quizzes.findIndex(ii => ii._id == q._id);
                        if (index > -1) {
                            quizzes.splice(index, 1);
                        }
                    }
                }

                setQuizzesData(quizzes);
            }
        }, [searchResult]
    );


    useEffect(() => {
        console.log("stepsData------>",stepsData);
        if (stepsData.name) {
            setValues({...stepsData});
            setSelectedQuizzes(stepsData.quizzes || []);
        }
    }, [stepsData])

    useEffect(() => {

        if (isSuccess && program) {
            handleNext(5);
        }

        if (isError && error) {
            console.log('Error-----', isError, error);
        }

    }, [isSuccess, program, isError, error])

    const handleSearch = ({search}) => {
        searchQuizzes(search);
    };


    const submitStep0 = async () => {
        setErrMsg('');
        const {amount, compareAmount} = values;
        if (stepsData.newImage) {
            let b = await fetch(stepsData.newImage).then(r => r.blob());
            stepsData.imageBase64 = await commonServices.blobToBase64(b)
        }
        if (selectedQuizzes) {
            values.quizzes = []
            for (const quiz of selectedQuizzes) {
                values.quizzes.push(quiz._id)
            }
        }
        const data = {...stepsData, ...values};
        setStepsData(data);
        saveCourse(data);
    };

    const submitStep = async () => {
        setErrMsg('');

        if (stepsData.newImage) {
            // Convert image to Base64
            const blob = await fetch(stepsData.newImage).then(r => r.blob());
            stepsData.imageBase64 = await commonServices.blobToBase64(blob);
        }

        // Set quizzes to `values.quizzes` using `selectedQuizzes` or fallback to `stepsData.quizzes`
        values.quizzes = (selectedQuizzes.length > 0 ? selectedQuizzes : stepsData.quizzes || [])
            .map(quiz => quiz.quizId || quiz._id);

        const data = { ...stepsData, ...values };

        setStepsData(data);  // Update the stepsData state
        saveCourse(data);    // Save the course
    };




    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const {t} = useTranslation();
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                //validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({isSubmitting}) => (
                    <Form noValidate autoComplete="off">

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'}
                        }}>
                            <TextField
                                required
                                label={t("admin-plans.title.amount")}
                                value={values.amount}
                                onChange={handleChange('amount')}

                            />
                        </FormControl><FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'}, mt:1.5
                        }}>
                            <TextField
                                label={t("Comapre Amount")}
                                value={values.compareAmount}
                                onChange={handleChange('compareAmount')}

                            />
                        </FormControl>

                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'}, mt:2
                        }}>
                            <InputLabel id="selectValidity">{t("admin-plans.title.validity")}</InputLabel>
                            <Select
                                required
                                labelId="selectValidity"
                                id="selectValidity"
                                value={values.validity}
                                label={t("admin-plans.title.validity")}
                                onChange={handleChange('validity')}
                                sx={{ml: 1, mr: 1}}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={11}>11</MenuItem>
                                <MenuItem value={12}>12</MenuItem>

                            </Select>
                        </FormControl>

                        <FormControl sx={{width: {xs: '100%', sm: '100%', xl: '100%'}, mt: 2}}>
                            <Autocomplete
                                id="quizzes-select"
                                multiple
                                value={selectedQuizzes}
                                onChange={(event, newValue, v) => {
                                    console.log(event, newValue, v);
                                    setSelectedQuizzes(newValue);
                                }}
                                filterSelectedOptions
                                options={quizzesData}
                                getOptionLabel={(option) => `${option.name} ${option.title}`}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={selectedQuizzes.length === 0 ? "Select Quizzes" : ""}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => {
                                            handleSearch({search: e.target.value});
                                        }}
                                    />
                                )}
                            />
                        </FormControl>

                    </Form>


                )}</Formik>


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                    onClick={() => {
                        handleNext(2)
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.backBtn")}
                </Button>
                <Button
                    onClick={() => {
                        hideDialog()
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.cancelBtn")}
                </Button>

                <Div sx={{flex: '1 1 auto'}}/>


                <LoadingButton loading={isProgramSaving} variant={"variant"} onClick={() => {
                    submitStep()
                }
                }>
                    {values.courseId ? t("common.updateBtn") : t("admin-plans.title.createButton")}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step4;
