import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import {Alert, Box, Button, FormControl} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import {Form, Formik} from "formik";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useTransactionsContext} from "../context";
import {useTranslation} from "react-i18next";

let Editor = () => <React.Fragment/>;

const Step2 = ({hideDialog, stepsData, handleNext, setStepsData}) => {
    const [isLoading] = React.useState(false);
    const {globalState, setGlobalState} = useTransactionsContext();
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [values, setValues] = React.useState({});
    const {t} = useTranslation();


    const handleEditorChange = (editorState) => {
        console.log('editorState', editorState)
        const descriptionHTML = draftToHtml(convertToRaw(editorState.getCurrentContent())); // Convert EditorState to HTML
        setValues({...values, descriptionHTML})
        setEditorState(editorState);
        //  setValues({...values, editorState, descriptionHTML}); // Update editorState and descriptionHTML in values
    };

    useEffect(() => {
        // Editor = require('react-draft-wysiwyg').Editor;
        // setValues({...values, editorState: EditorState.createEmpty()}); // Initialize editorState properly
    }, []);

    const [errMsg, setErrMsg] = React.useState('');

    const submitStep2 = async () => {
        setErrMsg('');

        const {descriptionHTML} = values;
        console.log(values);
        if (descriptionHTML) {
            const data = {...stepsData, ...values}
            setStepsData(data)
            console.log("Values--------", data)
            handleNext(3);

        } else {
            setErrMsg(t("admin-plans.title.fieldNotFilled"));
        }


    };

    useEffect(() => {
        Editor = require('react-draft-wysiwyg').Editor;

        if (stepsData?.descriptionHTML) {
            // Convert HTML to ContentState
            // setEditorState(EditorState.createEmpty());

            const blocksFromHTML = convertFromHTML(stepsData.descriptionHTML);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            // Set EditorState
            const editorState1 = EditorState.createWithContent(contentState);
            setEditorState(editorState1)
            setValues({...stepsData}); // Update editorState in values
        } else {
            const blocksFromHTML = convertFromHTML('');
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            // Set EditorState
            const editorState1 = EditorState.createWithContent(contentState);
            setEditorState(editorState1)

            setValues({...values, descriptionHTML : '' })
        }
    }, [stepsData]);


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1},

            }}
            noValidate
            autoComplete="off"
        >
            <Formik
                validateOnChange={true}
                initialValues={values}
                enableReinitialize={true}
                //validationSchema={validationSchema}
                onSubmit={() => {
                }}
            >
                {({isSubmitting}) => (
                    <Form noValidate autoComplete="off">


                        <FormControl sx={{
                            width: {xs: '100%', sm: '100%', xl: '100%'},
                            mt: 1,
                        }}>

                            <Editor
                                editorState={editorState} // Use editorState from values
                                onEditorStateChange={handleEditorChange}
                                wrapperClassName="editor-wrapper"
                                editorStyle={{
                                    width: '100%',
                                    minHeight: 100,
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor: 'lightgray',
                                }}
                            />


                        </FormControl>


                    </Form>


                )}</Formik>


            <Stack sx={{width: '100%'}} spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>
            <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                    onClick={() => {
                        handleNext(1)
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.backBtn")}
                </Button>
                <Button
                    onClick={() => {
                        hideDialog()
                    }}
                    sx={{mr: 1}}
                >
                    {t("common.cancelBtn")}
                </Button>
                <Div sx={{flex: '1 1 auto'}}/>


                <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep2}>
                    {t("common.nextBtn")}
                </LoadingButton>

            </Div>
        </Box>);

};

export default Step2;
