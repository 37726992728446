import React, {useState} from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import CalendarWrapper from "./CalendarWrapper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {useTranslation} from "react-i18next";
import {Alert, Dialog, DialogContent, DialogTitle, IconButton,} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Stack from "@mui/material/Stack";
import Snackbar from '@mui/material/Snackbar';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";
import {useBookingContext} from "../context";
import {useAppMutation} from "../../../../services";
import parentServices from "../../../../services/parent-services";
import styled from "@emotion/styled";
import 'moment/locale/fr';


const StyledCircle = styled('div')(({theme, color}) => ({
    backgroundColor: color,
    width: '10px',
    height: '10px',
    borderRadius: '50%',
}));

const localizer = momentLocalizer(moment);

const dayFormat = (date, culture, localizer) => localizer.format(date, 'DD/MM/YYYY', culture);
const dayRangeHeaderFormat = ({
                                  start,
                                  end
                              }, culture, localizer) => localizer.format(start, 'DD/MM/YYYY', culture) + '-' + localizer.format(end, 'DD/MM/YYYY', culture);

const dateFormat = 'DD';
const dayHeaderFormat = 'DD/MM/YYYY';


// const today = new Date();
// const currentYear = today.getFullYear();

const EventComponent = ({event, index}) => {
    console.log('event----', event)
    const formattedStartTime = new Date(event.start).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    return (
        <Div sx={{display: 'flex', alignItems: 'center', height: 20}} key={'calendar-' + index}>
            <Typography sx={{fontSize: 10}} variant="caption" gutterBottom component="div">
                {event.student.firstName}
            </Typography>
            <Typography sx={{fontSize: 10, ml: .5}} variant="caption" gutterBottom component="div">
                {event.student.lastName}
            </Typography>
            <Typography sx={{fontSize: 10, ml: .5}} variant="caption" gutterBottom component="div">
                {"(" + formattedStartTime + ")"}
            </Typography>
            {event.attended === 'not-attended' && <Div sx={{ml: 1}}><StyledCircle color="#D22B2B"/></Div>}

        </Div>
    );
}
const AgendaEventComponent = ({event}) => {
    const formattedStartTime = new Date(event.start).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    let color = '';

    if (event.bookingId) {
        color = event.teacherFeedback?.ratings?.length > 0 ? '#1D6ADE' : '#59B2FF';
    }
    return (
        <Div>
            <Typography variant="h6" gutterBottom component="div">
                {event.title}
            </Typography>
            <Typography variant="h6" gutterBottom component="div">
                {event.student.firstName} {event.student.lastName}
            </Typography>
        </Div>
    );
};

const CalendarBasic = () => {
    const {globalState, setGlobalState} = useBookingContext();
    const {availableDates, setAvailableDates} = useBookingContext();
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    const [events, setEvents] = useState([]);
    const params = useParams();
    const [searchStartDate, setSearchStartDate] = useState(new Date());

    moment.locale("fr");

    const {
        mutate: loadBookings,
        isSuccess: isLBSuccess,
        data: lbResult,
        isLoading: isLBLoading,
        isError: isLBError
    } = useAppMutation(parentServices.loadBookings);

    const {
        mutate: loadAvailableDates,
        isSuccess: isLDSuccess,
        isLoading: isLDLoading,
        isError: isLDError,
        data: ldResult
    } = useAppMutation(parentServices.loadAvailableDates);

    React.useEffect(() => {
        if (!minDate) {
            const currentMax = new Date();
            const min = new Date();
            setMinDate(min);
            currentMax.setDate(currentMax.getDate() + 30);
            setMaxDate(currentMax);
            const maxDate = new Date();
            maxDate.setMonth(maxDate.getMonth() + 3);
            maxDate.setHours(0, 0, 0, 0);
            loadAvailableDates({startDate: min, endDate: maxDate, type: 'courses'});
        }
    }, [minDate])


    React.useEffect(() => {
        if (isLDSuccess) {
            setAvailableDates(ldResult?.availableDates)
        } else if (isLDError) {
            setAvailableDates([])
        }
    }, [isLDError, isLDSuccess])

    React.useEffect(() => {

        if (globalState.refreshList) {
            loadBookings({startDate: searchStartDate})
            setGlobalState({...globalState, refreshList: false})
        }

    }, [globalState])

    React.useEffect(() => {

        if (searchStartDate) {
            loadBookings({startDate: searchStartDate})
        }

    }, [searchStartDate]);


    React.useEffect(() => {

        if (isLBSuccess) {
            console.log('Events-1----------', lbResult)
            const ev = [];
            for (const e of lbResult) {
                ev.push({...e, start: new Date(e.startDate), end: new Date(e.endDate)})
            }
            setEvents(ev);
        }


    }, [isLBSuccess])


    const isPastDate = (date) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        return date < currentDate;
    };

    const handleSelectSlot = (slotInfo) => {
        const selectedDate = slotInfo.start;

        // Set the time of the current date to midnight
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        // Check if the selected date is in the past
        if (selectedDate < currentDate) {
            setErrMsg(t('errMsg.pastDateMsg'));
            setSnackbarOpen(true);
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        const maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth() + 3);
        maxDate.setHours(0, 0, 0, 0);

        // Check if the selected date is within the next month
        if (selectedDate < currentDate || selectedDate >= maxDate) {
            setErrMsg(t("errMsg.monthMsg"));
            setSnackbarOpen(true);
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        if (isPastDate(selectedDate)) {
            setErrMsg(t('errMsg.pastDateMsg'));
            setSnackbarOpen(true);
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }

        if (availableDates && availableDates.indexOf(moment(selectedDate).format('YYYY-MM-DD')) ===  -1) {
            setErrMsg(t('errMsg.availableDate'));
            setSnackbarOpen(true);
            setTimeout(() => {
                setErrMsg("");
            }, 3000);
            return;
        }


        setSelectedDate(selectedDate);


        // Adjust time zone offset when formatting the date
        const adjustedDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000);
        const booking = {
            sstartDate: adjustedDate,
            startDate: moment(adjustedDate).format('YYYY-MM-DD'), // Format the adjusted date for date textfield
        }
        setGlobalState({...globalState, booking, minDate, maxDate, addEditBooking: true})
        loadAvailableDates({startDate: minDate, endDate: maxDate, type: 'courses'});


    };

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setErrMsg('');
    };

    const handleEventClick = (event) => {
        const v = {
            teacherId: event.teacher?.teacherId,
            bookingId: event.bookingId,
            description: event.description,
            sstartDate: new Date(event.startDate),
            attended: event.attended,
            startDate: moment(event.start).format('YYYY-MM-DD'),
            startTime: moment(event.start).format('HH:mm'),
        };
        setGlobalState({...globalState, booking: v, minDate, maxDate, addEditBooking: true})
    };


    const handleNavigate = (newDate, view, action) => {
        console.log('Navigating:', action, 'to', newDate, view);
        if (newDate.getMonth() != searchStartDate.getMonth()) {
            setSearchStartDate(newDate);
        }
    };

    const renderSubscriptionAlert = () => {

        if (globalState && globalState.subscription) {
            const {subscription} = globalState;
            if (subscription.noOfUsedBooking < subscription.noOfBooking) {

                return <Stack sx={{width: '100%'}} spacing={2}>
                    <Alert severity="success" onClose={() => {
                    }}>Booking Utilize {subscription.noOfUsedBooking}/{subscription.noOfBooking}</Alert>

                </Stack>

            } else if (subscription.noOfBooking == subscription.noOfUsedBooking) {
                return <Stack sx={{width: '100%'}} spacing={2}>
                    <Alert severity="warning" onClose={() => {
                    }}>Booking Utilize {subscription.noOfUsedBooking}/{subscription.noOfBooking}</Alert>
                </Stack>
            }
        }

        return null

    }


    return (
        <React.Fragment>
            {/*<Typography variant={"h1"} mb={3}>{t('pages.title.basicCalendar')}</Typography>*/}
            <Stack
                sx={{
                    mb: 2,
                    mt: -2
                }}
                spacing={2}>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </Stack>

            {renderSubscriptionAlert()}


            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                culture={"pt-FR"}
                open={snackbarOpen}
                autoHideDuration={2500}
                startAccessor="startDate"
                endAccessor="endDate"
                onClose={handleSnackbarClose}
                message={errMsg}
                action={
                    <IconButton color={"primary"} size={"small"}>
                        <WarningAmberOutlinedIcon/>
                    </IconButton>
                }
            />

            <Card>
                <CardContent>
                    <CalendarWrapper>
                        <Calendar
                            formats={{dayHeaderFormat, dateFormat, dayFormat, dayRangeHeaderFormat}}
                            messages={{
                                next: "Suivant",
                                previous: "Précédent",
                                today: "Aujourd’hui",
                                month: "Mois",
                                week: "Semaine",
                                day: "Jour",
                                agenda: 'Agenda',
                                event: 'Évènement',
                                date: 'Date',
                                time: 'Créneau',
                            }}
                            localizer={localizer}
                            events={events}
                            step={15}
                            defaultDate={new Date()}
                            onNavigate={handleNavigate}
                            style={{height: 600, overflowX: 'auto'}}
                            selectable={true} // Enable user selection
                            onSelectSlot={handleSelectSlot}
                            startAccessor="start"
                            endAccessor="end"
                            onSelectEvent={(event) => {
                                handleEventClick(event)
                            }}
                            eventPropGetter={(event, start, end, isSelected) => {
                                console.log('Event------11------------', event);

                                const style = {
                                    backgroundColor: isSelected ? '#454545' : (isPastDate(start) ? 'purple' : event.color),
                                    color: isSelected ? 'black' : event.color,
                                }
                                let color = '';

                                if (event.bookingId) {
                                    console.log(event.teacherFeedback)
                                    color = event.teacherFeedback?.ratings?.length > 0 ? '#1D6ADE' : '#59B2FF';

                                    color = event.cancelled ? '#D22B2B' : color;
                                }

                                return {
                                    style: {
                                        backgroundColor: color,
                                        color: isSelected ? '#FFF' : "#FFF",
                                    },
                                };

                            }}
                            components={{
                                event: EventComponent,
                                agenda: {
                                    event: AgendaEventComponent,
                                },
                            }}
                        />
                    </CalendarWrapper>
                </CardContent>
            </Card>

            <Div>
                <Dialog open={openDialog}>
                    <DialogTitle>{t("parent.title.title")}</DialogTitle>
                    <DialogContent>


                    </DialogContent>
                </Dialog>
            </Div>
        </React.Fragment>

    )
        ;
};

export default CalendarBasic;
