import React from 'react';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import styled from "@emotion/styled";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Span from "@jumbo/shared/Span";
import Div from "@jumbo/shared/Div";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import StudentDetail from "./StudentDetail";
import JumboGridItem from "@jumbo/components/JumboList/components/JumboGridItem";
import DDImage from "../../../component/DDImage";
import {useStudentContext} from "./context";
import EscalatorWarningOutlinedIcon from '@mui/icons-material/EscalatorWarningOutlined';
import {useTranslation} from "react-i18next";
import moment from "moment/moment";

const Item = styled(Span)(({theme}) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1),
}));

const StudentItem = ({student, view}) => {
    const {showDialog, hideDialog} = useJumboDialog();
    const {globalState, setGlobalState} = useStudentContext();
    const {t} = useTranslation();

    const deleteStudentMutation = async () => {
        return null
    }


    const showStudentDetail = React.useCallback(() => {
        showDialog({
            content: <StudentDetail student={student} onClose={hideDialog}/>
        })
    }, [showDialog, student]);


    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'edit':
                const openAddEdit = true;
                const studentId = student.studentId;
                setGlobalState({...globalState, studentId, openAddEdit});
                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: t("admin-student.title.deleteStudent"),
                    content: t("admin-student.title.deleteTxt"),
                    onYes: () => deleteStudentMutation.mutate(student),
                    onNo: hideDialog
                })
        }
    };

    if (view === "grid") {
        return (
            <JumboGridItem xs={12} lg={6}>
                <Card variant="outlined" elevation={0}>
                    <CardHeader
                        avatar={student.images?.length > 0 ? (
                                <DDImage path={student.images[0]} alt={student.firstName + ' ' + student.lastName}/>) :
                            (<Avatar alt={student.firstName + ' ' + student.lastName}/>)
                        }
                        action={
                            <React.Fragment>
                                <JumboDdMenu
                                    icon={<MoreHorizIcon/>}
                                    menuItems={[
                                        {icon: <EditIcon/>, title: t("common.edit"), action: "edit"},
                                        {/*{icon: <DeleteIcon/>, title: "Delete", action: "delete"}*/}
                                    ]}
                                    onClickCallback={handleItemAction}
                                />
                            </React.Fragment>
                        }
                        title={
                            <Typography
                                variant={"h6"}
                                color={"text.primary"}
                                mb={.25}
                            >
                                {student.firstName} {student.lastName}
                            </Typography>}
                        subheader={
                            <Typography
                                variant={"body1"}
                                color={"text.secondary"}
                            >
                                {student.designation}
                            </Typography>}
                    />
                    <CardContent sx={{pt: 0}}>
                        <Divider sx={{mb: 2}}/>
                        {<List disablePadding>
                            {/*<ListItem sx={{p: theme => theme.spacing(.75, 1.5)}}>
                                <ListItemIcon sx={{minWidth: 50}}>
                                    <EscalatorWarningOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={"Student"}
                                    secondary={student?.firstName+' '+student?.lastName}
                                />
                            </ListItem>*/}
                            <ListItem sx={{px: 1.5}}>
                                <ListItemIcon sx={{minWidth: 50}}>
                                    <AlternateEmailIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={student?.email}
                                />
                            </ListItem>
                            <ListItem sx={{px: 1.5}}>
                                <ListItemIcon sx={{minWidth: 50}}>
                                    <PhoneIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={student?.phone?.fullNumber}
                                />
                            </ListItem>
                        </List>}
                        <Divider sx={{my: 2}}/>
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >

                            <JumboChipsGroup
                                chips={[{label: "Student"}]}
                                mapKeys={{label: "name"}}
                                spacing={1}
                                size={"small"}
                                defaultColor={"#146ae4"}
                            />

                        </Div>
                    </CardContent>
                </Card>
            </JumboGridItem>
        )
    }
    return (
        <React.Fragment>
            <JumboListItem
                componentElement={"div"}
                itemData={student}
                secondaryAction={
                    <JumboDdMenu
                        icon={<MoreHorizIcon/>}
                        menuItems={[
                            {icon: <EditIcon/>, title: t("common.edit"), action: "edit"}
                        ]}
                        onClickCallback={handleItemAction}
                    />
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    }
                }}
            >

                <ListItemAvatar onClick={showStudentDetail}>
                    {student.images?.length > 0 ? (
                            <DDImage path={student.images[0]} alt={student.firstName + ' ' + student.lastName}/>) :
                        (<Avatar alt={student.firstName + ' ' + student.lastName}/>)}
                </ListItemAvatar>
                <ListItemText
                    onClick={showStudentDetail}
                    primary={
                        <Typography variant={"body1"} component={"div"}>
                            <Stack direction={"row"} alignItems={"center"} sx={{minWidth: 0}}>
                                <Item
                                    sx={{
                                        flexBasis: {xs: '100%', sm: '50%', md: '25%'}
                                    }}
                                >
                                    <Typography variant={"h5"} fontSize={14} lineHeight={1.25} mb={0}
                                                noWrap>{student.firstName} {student.lastName}</Typography>
                                    <Typography
                                        variant={"body1"}
                                        noWrap
                                        color={'text.secondary'}

                                    >
                                        {moment(student.createdAt).format('DD/MM/YYYY, hh:mm A')}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: {sm: '50%', md: '28%'},
                                        display: {xs: 'none', sm: 'block'}
                                    }}
                                >
                                    <Typography variant={"body1"} noWrap>{"Email"}</Typography>
                                    <Typography variant={"body1"} noWrap>{student?.email}</Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: {md: '25%'},
                                        display: {xs: 'none', md: 'block'}
                                    }}
                                >
                                    {/*<Typography variant={"body1"} noWrap>{student?.phone?.fullNumber}</Typography>*/}
                                    <Typography variant={"body1"} noWrap>
                                        {student?.phone?.fullNumber ? `+${student.phone.fullNumber}` : ''}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: {md: '22%'},
                                        display: {xs: 'none', md: 'block'}
                                    }}
                                >
                                    <JumboChipsGroup
                                        chips={[{label: "Student"}]}
                                        mapKeys={{label: "name"}}
                                        spacing={1}
                                        size={"small"}
                                        defaultColor={"#146ae4"}
                                    />
                                </Item>
                            </Stack>
                        </Typography>
                    }
                />
            </JumboListItem>
        </React.Fragment>
    );
};
/* Todo student, view prop define */
export default StudentItem;
