import React, {useEffect} from 'react';
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import JumboListToolbar from "@jumbo/components/JumboList/components/JumboListToolbar";
import ButtonGroup from "@mui/material/ButtonGroup";
import {Button, Card, Dialog, DialogContent, DialogTitle, useMediaQuery} from "@mui/material";
import {useParams} from "react-router-dom";
import JumboSearch from "@jumbo/components/JumboSearch";
import ListIcon from "@mui/icons-material/List";
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import {USE_IMAGE_PLACEHOLDERS} from "../../../utils/constants/paths";
import Item from "./Item";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import Header from "./header";
import Sidebar from "./sidebar";
import {TransactionsProvider, useTransactionsContext} from "./context";
import Stack from "@mui/material/Stack";
import {useJumboTheme} from "@jumbo/hooks";
import FolderDropdown from "./FolderDropdown";
import adminServices from "../../../services/admin-services";
import ProgramFab from "./TransactionFab";
import ProgramForm from "./RequestForm";
import {useTranslation} from "react-i18next";

export const ASSET_IMAGES = process.env.REACT_APP_IMAGES_PATH;

export const ASSET_AVATARS = ASSET_IMAGES + "/avatar";


export const getAssetPath = (url, size) => {
    if (USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }

    return url;
};

const BulkActions = () => {
    return <>Test</>
}


const Children = () => {
    return <TransactionsProvider><InnerChildren/></TransactionsProvider>
}

const InnerChildren = () => {

    const {globalState, setGlobalState} = useTransactionsContext();
    const {theme} = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));

    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);


    const params = useParams();
    const listRef = React.useRef();


    const [view, setView] = React.useState("list");

    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: "transactions",
        queryParams: { },
        countKey: "count",
        dataKey: "transactions",
    });

    /*React.useEffect(() => {
        setQueryOptions(state => ({
            ...state,
            queryParams: {...state.queryParams, category: params.category, id: params.id}
        }))
    }, [params]);*/

    useEffect(() => {
        if (globalState?.refreshTransactionsList) {
            listRef.current.refresh();
            setGlobalState({...globalState, refreshTransactionsList: false})
        }
    }, [globalState]);


    const renderProgram = React.useCallback((program) => {
        return (<Item program={program} view={view}/>)
    }, [view]);


    const loadTransactions = async ({queryKey}) => {


        const {page, limit: size,queryParams} = queryKey[1];
        const result = await adminServices.loadTransactions({page: page + 1, size,search: queryParams?.keywords});

        return {transactions: result.transactions, count: result.totalRecords};


    }


    const handleOnChange = React.useCallback((keywords) => {
        setQueryOptions(state => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                keywords: keywords,
            }
        }))
    }, []);
    const {t} = useTranslation();

    return (
        <JumboContentLayout
            /*sidebar={<Sidebar/>}*/
            header={
                <Header title={t("admin-transactions.title.title1")}
                        subheader={t("admin-transactions.title.subheader")}/>
            }
            layoutOptions={layoutOptions}
        >

            {
                lg && (
                    <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                        {/* <FolderDropdown/> */}
                        <ProgramFab/>
                    </Stack>
                )
            }
            <JumboRqList
                ref={listRef}
                wrapperComponent={Card}
                primaryKey={"transactionId"}
                queryOptions={queryOptions}
                itemsPerPage={8}
                itemsPerPageOptions={[8, 15, 20]}
                renderItem={renderProgram}
                service={loadTransactions}
                componentElement={"div"}
                // sx={view === 'grid' && {p: theme => theme.spacing(1, 3, 3)}}
                wrapperSx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
                toolbar={
                    <JumboListToolbar
                        hideItemsPerPage={true}
                        bulkActions={false}
                        actionTail={
                            <ButtonGroup
                                variant="outlined"
                                disableElevation
                                sx={{
                                    '& .MuiButton-root': {
                                        px: 1,
                                    }
                                }}
                            >
                                <Button variant={view === "list" ? "contained" : "outlined"}
                                        onClick={() => setView("list")}><ListIcon/></Button>
                                <Button variant={view === "grid" ? "contained" : "outlined"}
                                        onClick={() => setView("grid")}><ViewComfyIcon/></Button>
                            </ButtonGroup>
                        }
                    >
                        <JumboSearch
                            onChange={handleOnChange}
                            sx={{
                                display: {xs: 'none', md: 'block'}
                            }}
                        />
                    </JumboListToolbar>
                }
                view={view}
            />
            <Dialog open={globalState.openAddEditProgram}>
                <DialogTitle>{t("admin-transactions.title.addPlans")}</DialogTitle>
                <DialogContent sx={{width: '100%'}}><ProgramForm/></DialogContent>
            </Dialog>
        </JumboContentLayout>

    );
};

export default Children;
