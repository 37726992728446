import React from 'react';
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import {SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import List from "@mui/material/List";
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const JumboVerticalNavbar = ({items}) => {
    const {sidebarOptions} = useJumboLayoutSidebar();
    const {isSuperAdmin,  isTeacher, isStudent} = useSelector(state => state.auth);
    const {t} = useTranslation();

    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);

    return (
        <List
            disablePadding
            sx={{
                mr: isMiniAndClosed ? 0 : 2,
                pb: 2
            }}
        >
            {isStudent && (<Typography variant="subtitle2" display="block" component="div" align="center">
                {t('sidebar.menuItem.student')}
            </Typography>)}

            {isSuperAdmin && (<Typography variant="subtitle2" display="block" component="div" align="center">
                    {t('sidebar.menuItem.administrator')}
            </Typography>)}

            {isTeacher && (<Typography variant="subtitle2" display="block" component="div" align="center">
                {t('sidebar.menuItem.teacher')}
            </Typography>)}

            {
                items.map((item, index) => (
                    <JumboNavIdentifier translate item={item} key={index}/>
                ))
            }
        </List>
    );
};

JumboVerticalNavbar.defaultProps = {
    items: PropTypes.array,
    translate: false
};

export default JumboVerticalNavbar;
