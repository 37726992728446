import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useAppMutation } from "../../../../services";
import studentServices from "../../../../services/student-services";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ProjectItemCard from "./ProjectItemCard";
import Payment from "../Payment";
import Div from "@jumbo/shared/Div";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CardHeader from "@mui/material/CardHeader";

const ProjectsList = () => {
  const {
    mutate: loadCourses,
    isError,
    data,
    isSuccess,
    isLoading,
  } = useAppMutation(studentServices.loadCourses);
  const [loaded, setLoaded] = useState(false);
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!loaded) {
      loadCourses({ size: 100 });
      setLoaded(true);
    }
  }, [loaded]);

  useEffect(() => {
    if (isSuccess) {
      setCourses(data.courses);
    }
  }, [isSuccess, data]);

  const hideCourse = () => {
    setSelectedCourse({});
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  return (
    <React.Fragment>
      <Typography variant={"h2"} mb={3}>
        {t("student-course.title.courses")}
      </Typography>

      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={56} />
      ) : (
        <OutlinedInput
          fullWidth
          placeholder={t("student-course.title.searchCourse")}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              {searchQuery && (
                <IconButton onClick={handleClearSearch} edge="end">
                  <CloseIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      )}

      <Payment course={selectedCourse} hide={hideCourse} />

      <Grid container spacing={3.75} mt={2}>
        {isLoading ? (
          // Show skeleton cards while loading
          Array.from(new Array(3)).map((_, index) => (
            <Grid item key={index} xs={12} sm={6} lg={4}>
              <Div
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#FFF",
                  pt: 5,
                  pb: 5,
                }}
              >
                <Skeleton variant="circular" width={100} height={100} />
                <Skeleton width="60%" height={30} style={{ marginTop: 10 }} />
                <Skeleton width="40%" height={30} />
                <Skeleton width="40%" height={30} />
                <Skeleton width="40%" height={30} />
              </Div>
            </Grid>
          ))
        ) : courses.filter((course) =>
            course.name.toLowerCase().includes(searchQuery.toLowerCase()),
          ).length > 0 ? (
          courses
            .filter((course) =>
              course.name.toLowerCase().includes(searchQuery.toLowerCase()),
            )
            .map((course, index) => (
              <Grid item key={index} xs={12} sm={6} lg={4}>
                <ProjectItemCard item={course} setCourse={setSelectedCourse} />
              </Grid>
            ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {t("student-course.title.noCourse")}
            </Typography>
            <Typography variant="h6" align="center" sx={{ mt: 1 }}>
              {t("student-course.title.noCourseSubTitle")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default ProjectsList;
