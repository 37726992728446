import React, {useEffect} from 'react';
import {Alert, Autocomplete, Box, Button, FormControl, TextField} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {COUNTRIES} from "../../../utils/constants/appActions";
import {useAppMutation} from "../../../services";
import parentServices from "../../../services/parent-services";
import studentServices from "../../../services/student-services";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTranslation} from "react-i18next";

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];


const Step2 = ({stepsData, handleNext}) => {


    const [values, setValues] = React.useState({
        location: {
            lCountry: {
                code: 'FR',
                label: 'France',
                phone: '33'
            },
            country: 'FR'
        }
    });
    const {mutate, error, isError, data, isLoading, isSuccess} = useAppMutation(studentServices.completeStep2);
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();

    useEffect(() => {
        console.log('Step 1', values, stepsData);
        if (stepsData?.location) {
            const country = COUNTRIES.find(item => item.code === stepsData.location?.country)
            const location = {...stepsData.location};
            if (country) {
                location.lCountry = country;
            } else {
                location.lCountry = {
                    code: 'FR',
                    label: 'France',
                    phone: '33'
                };
            }
            setValues({location});
        }
    }, [stepsData])


    useEffect(() => {

        if (isSuccess) {
            handleNext(3);
        } else if (error && isError) {
            setErrMsg(error.message || error);
        }

    }, [isSuccess, isError])


    const handleChange = (prop) => (event) => {
        const {location} = values;
        location[prop] = event.target.value;
        console.log(location);
        setValues({...values, location});
    }


    const submitStep2 = async () => {

        setErrMsg('')

        const {location} = values;


        if (location?.country) {
            mutate({...values})
        } else {
            setErrMsg(t('errMsg.notFill'));
        }


    };


    const backToStep1 = async () => {
        handleNext(1);
    };

    return (<Box
        component="form"
        sx={{
            '& .MuiTextField-root': {m: 1, mt: 5},
        }}
        noValidate
        autoComplete="off"
    >
        <Div>


            <Div sx={{
                mt: -2,
            }}>
                <FormControl sx={{
                    width: 500,
                    maxWidth: '100%'
                }}>
                    <TextField
                        label={t("parent.title.address1")}
                        InputLabelProps={{shrink: true}}
                        value={values.location.address1}
                        onChange={handleChange('address1')}
                    />
                </FormControl>
                <FormControl sx={{
                    width: 500,
                    maxWidth: '100%',

                }}>
                    <TextField
                        label={t("parent.title.address2")}
                        InputLabelProps={{shrink: true}}
                        value={values.location.address2}
                        onChange={handleChange('address2')}
                    />
                </FormControl>
            </Div>

            <Div sx={{
                mt: -2,
            }}>
                <FormControl sx={{
                    width: 500,
                    maxWidth: '100%'
                }}>
                    <TextField
                        label={t("common.city")}
                        value={values.location.city}
                        InputLabelProps={{shrink: true}}
                        onChange={handleChange('city')}/>
                </FormControl>

                <FormControl sx={{
                    width: 485,
                    maxWidth: '100%',

                }}>
                    <Autocomplete

                        id="country-select-demo"
                        sx={{maxWidth: '100%'}}
                        options={COUNTRIES}
                        autoHighlight
                        value={values.location.lCountry}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                />
                                {option.label} ({option.code})
                            </Box>
                        )}
                        disableClearable={true}
                        onChange={(event, value) => {
                            const {location} = values;
                            location.country = value.code;
                            location.lCountry = value;
                            setValues({location: {...location}});
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('parent-signup.title.country')}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }}
                                required
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <React.Fragment>
                                            {values.country?.code && (
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${values.location?.lCountry?.code?.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${values.location?.lCountry?.code?.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                            )}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </FormControl>
            </Div>
            <Div sx={{
                mt: -3,
            }}>
                <FormControl sx={{
                    width: 500,
                    maxWidth: '100%',

                }}>
                    <TextField
                        label={t("common.postalCode")}
                        InputLabelProps={{shrink: true}}
                        value={values.location.postalCode}
                        onChange={handleChange('postalCode')}

                    />
                </FormControl>
            </Div>
        </Div>
        <Stack sx={{width: '100%'}} spacing={2}>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
        </Stack>
        <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
            <Button
                onClick={backToStep1}
                sx={{mr: 1}}
            >
                {t("common.backBtn")}
            </Button>
            <Div sx={{flex: '1 1 auto'}}/>


            <LoadingButton loading={isLoading} variant={"variant"} onClick={submitStep2}>
                {t("common.nextBtn")}
            </LoadingButton>
        </Div>
    </Box>);

};

export default Step2;
