import React, { useEffect } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import styled from "@emotion/styled";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Span from "@jumbo/shared/Span";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ContactDetail from "./ContactDetail";
import JumboGridItem from "@jumbo/components/JumboList/components/JumboGridItem";
import { useAppMutation } from "../../../services";
import adminServices from "../../../services/admin-services";
import DDImage from "../../../component/DDImage";
import { useRequestsContext } from "./context";
import { useTranslation } from "react-i18next";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import BadgeIcon from "@mui/icons-material/Badge";
import EmailIcon from "@mui/icons-material/Email";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Chip from "@mui/material/Chip";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import DoNotDisturbOffIcon from "@mui/icons-material/DoNotDisturbOff";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import VerifiedIcon from "@mui/icons-material/Verified";

const Item = styled(Span)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 0,
  padding: theme.spacing(0, 1),
}));

const ProgramItem = ({ program, view }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const { globalState, setGlobalState } = useRequestsContext();
  const { t } = useTranslation();
  const {
    mutate: setCourseRequestStatus,
    isError: isCRSError,
    isSuccess: isCRSSuccess,
    isLoading: isCRSLoading,
  } = useAppMutation(adminServices.setCourseRequestStatus);

  const {
    mutate: setCourseRequestApproved,
    isError: isCRAError,
    isSuccess: isCRASuccess,
    isLoading: isSCRALoading,
  } = useAppMutation(adminServices.setCourseRequestApproved);

  useEffect(() => {
    if (isCRSSuccess || isCRASuccess) {
      if (isCRASuccess) {
        hideDialog(false);
      }
      setGlobalState({ ...globalState, refreshRequestsList: true });
    }
  }, [isCRSError, isCRSSuccess, isCRASuccess]);

  const showContactDetail = React.useCallback(() => {
    showDialog({
      content: <ContactDetail program={program} onClose={hideDialog} />,
    });
  }, [showDialog, program]);

  const handleItemAction = (menuItem, program) => {
    console.log("menuItem", menuItem, program);
    switch (menuItem.action) {
      case "approve":
        showDialog({
          variant: "confirm",
          title: t("admin-requests.title.approvedTitle"),
          content: t("admin-requests.title.approvedContent"),
          onYes: () => {
            if(!isSCRALoading) {
              setCourseRequestApproved({
                courseRequestId: program.courseRequestId,
                approved: true,
              });
            }
          },
          onNo: hideDialog,
        });

        break;

      case "cancel":
        showDialog({
          variant: "confirm",
          title: t("admin-requests.title.cancelTitle"),
          content: t("admin-requests.title.cancelContent"),
          onYes: () => {
            setCourseRequestApproved({
              courseRequestId: program.courseRequestId,
              approved: false,
            });
          },
          onNo: hideDialog,
        });
    }
  };

  const handleEditHorizClick = () => {
    handleItemAction({ action: "edit" }); // This will open the menu or perform the default action.
  };

  const handleDeleteHorizClick = () => {
    handleItemAction({ action: "delete" }); // This will open the menu or perform the default action.
  };

  const handleRequestStatus = (obj) => {
    setCourseRequestStatus(obj);
  };

  if (view === "grid") {
    return (
      <JumboGridItem xs={12} lg={6}>
        <Card variant="outlined" elevation={0}>
          <CardHeader
            avatar={
              program.course?.images?.length > 0 ? (
                <DDImage path={program.course.images[0]} alt={program.name} />
              ) : (
                <Avatar alt={program?.title}>
                  <InsertDriveFileIcon />
                </Avatar>
              )
            }
            action={
              program.status !== "completed" ? (
                <JumboDdMenu
                  icon={<MoreHorizIcon />}
                  menuItems={[
                    {
                      icon: <TaskAltIcon />,
                      title: t("admin-requests.title.approved"),
                      action: "approve",
                    },
                    {
                      icon: <DoNotDisturbOffIcon />,
                      title: t("admin-requests.title.cancel"),
                      action: "cancel",
                    },
                  ]}
                  onClickCallback={(menu) => handleItemAction(menu, program)}
                />
              ) : (
                <VerifiedIcon sx={{ mr: 1, mt: 1 }} />
              )
            }
            title={
              <Typography variant={"h6"} color={"text.primary"} mb={0.25}>
                {program.title}
              </Typography>
            }
            subheader={
              <Typography variant={"body1"} color={"text.secondary"}>
                {program.designation}
              </Typography>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Divider sx={{ mb: 2 }} />
            <List disablePadding>
              <ListItem sx={{ p: (theme) => theme.spacing(0.75, 1.5) }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <ListItemAvatar
                    sx={{ display: { xs: "none", sm: "block" } }}
                    onClick={showContactDetail}
                  >
                    {program.student?.images?.length > 0 ? (
                      <DDImage
                        path={program.student?.images[0]}
                        alt={program.title}
                      />
                    ) : (
                      <Avatar alt={program?.title}>
                        <PermIdentityIcon />
                      </Avatar>
                    )}
                  </ListItemAvatar>
                </ListItemIcon>
                <ListItemText
                  primary={t("admin-requests.title.studentName")}
                  secondary={`${program.student?.firstName} ${program.student?.lastName}`}
                />
              </ListItem>
              <ListItem sx={{ px: 1.5 }}>
                <ListItemIcon sx={{ minWidth: 50 }}>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t("admin-requests.title.email")}
                  secondary={program.student?.email}
                />
              </ListItem>

              <ListItem sx={{ px: 1.5 }}>
                {program.status === "new" && (
                  <Chip
                    color={"error"}
                    size={"small"}
                    label={t("admin-requests.title.newRequest")}
                    onClick={() => {
                      handleRequestStatus({
                        courseRequestId: program.courseRequestId,
                        status: "in-processing",
                      });
                    }}
                  />
                )}
                {program.status === "in-processing" && (
                  <Chip
                    color={"warning"}
                    size={"small"}
                    label={t("admin-requests.title.inProcessing")}
                  />
                )}
                {program.status === "completed" && (
                  <Chip
                    color={"success"}
                    size={"small"}
                    label={t("admin-requests.title.completed")}
                  />
                )}
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </JumboGridItem>
    );
  }
  return (
    <React.Fragment>
      <JumboListItem
        componentElement={"div"}
        itemData={program}
        secondaryAction={
          program.status !== "completed" ? (
            <JumboDdMenu
              icon={<MoreHorizIcon />}
              menuItems={[
                {
                  icon: <TaskAltIcon />,
                  title: t("admin-requests.title.approved"),
                  action: "approve",
                },
                {
                  icon: <DoNotDisturbOffIcon />,
                  title: t("admin-requests.title.cancel"),
                  action: "cancel",
                },
              ]}
              onClickCallback={(menu) => handleItemAction(menu, program)}
            />
          ) : (
            <VerifiedIcon sx={{ mr: 1 }} />
          )
        }
        sx={{
          cursor: "pointer",
          borderTop: 1,
          borderColor: "divider",
          "&:hover": {
            bgcolor: "action.hover",
          },
        }}
      >
        <ListItemAvatar onClick={showContactDetail}>
          {program.course?.images?.length > 0 ? (
            <DDImage path={program.course?.images[0]} alt={program.title} />
          ) : (
            <Avatar alt={program?.title}>
              <InsertDriveFileIcon />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          //onClick={showContactDetail}
          primary={
            <Typography variant={"body1"} component={"div"}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{ minWidth: 0 }}
              >
                <Item
                  sx={{
                    flexBasis: { xs: "100%", sm: "50%", md: "35%" },
                  }}
                  onClick={showContactDetail}
                >
                  <Typography
                    variant={"h5"}
                    fontSize={14}
                    lineHeight={1.25}
                    mb={0}
                    noWrap
                  >
                    {program.title}
                  </Typography>
                  <Typography
                    variant={"body1"}
                    noWrap
                    color={"text.secondary"}
                    sx={{
                      display: { sm: "none" },
                    }}
                  >
                    {program.email}
                  </Typography>
                </Item>
                <ListItemAvatar
                  sx={{ display: { xs: "none", sm: "block" } }}
                  onClick={showContactDetail}
                >
                  {program.student?.images?.length > 0 ? (
                    <DDImage
                      path={program.student?.images[0]}
                      alt={program.title}
                    />
                  ) : (
                    <Avatar alt={program?.title}>
                      <PermIdentityIcon />
                    </Avatar>
                  )}
                </ListItemAvatar>
                <Item
                  sx={{
                    flexBasis: { sm: "50%", md: "28%" },
                    display: { xs: "none", sm: "block" },
                  }}
                  onClick={showContactDetail}
                >
                  <Typography variant={"body1"} noWrap>
                    {t("admin-requests.title.studentName")}
                  </Typography>
                  <Typography variant={"body1"} noWrap>
                    {program?.student?.firstName} {program?.student?.lastName}
                  </Typography>
                </Item>
                <Item
                  sx={{
                    flexBasis: { md: "30%" },
                    display: { xs: "none", md: "block" },
                  }}
                  onClick={showContactDetail}
                >
                  <Typography variant={"body1"} noWrap>
                    {t("admin-requests.title.email")}
                  </Typography>
                  <Typography variant={"body1"} noWrap>
                    {program.student?.email}
                  </Typography>
                </Item>

                <Item
                  sx={{
                    flexBasis: { md: "20%" },
                    display: { xs: "none", md: "block" },
                    textAlign: "center",
                  }}
                >
                  {program.status === "new" && (
                    <Chip
                      color={"error"}
                      size={"small"}
                      label={t("admin-requests.title.newRequest")}
                      onClick={() => {
                        handleRequestStatus({
                          courseRequestId: program.courseRequestId,
                          status: "in-processing",
                        });
                      }}
                    />
                  )}
                  {program.status === "in-processing" && (
                    <Chip
                      color={"warning"}
                      size={"small"}
                      label={t("admin-requests.title.inProcessing")}
                    />
                  )}
                  {program.status === "completed" && (
                    <Chip
                      color={"success"}
                      size={"small"}
                      label={t("admin-requests.title.completed")}
                    />
                  )}
                </Item>

                <Item
                  sx={{
                    flexBasis: { md: "22%" },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <JumboChipsGroup
                    chips={program.labels}
                    mapKeys={{ label: "name" }}
                    spacing={1}
                    size={"small"}
                    max={1}
                  />
                </Item>
              </Stack>
            </Typography>
          }
        />
      </JumboListItem>
    </React.Fragment>
  );
};
/* Todo program, view prop define */
export default ProgramItem;
