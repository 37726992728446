import React, {useEffect, useRef, useState} from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import {useNavigate} from "react-router-dom";
import {
    Accordion,
    AccordionDetails,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    LinearProgress,
    Typography,
} from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import DDImage from "../../../../../../component/DDImage";
import {useAppMutation} from "../../../../../../services";
import studentServices from "../../../../../../services/student-services";
import {useTranslation} from "react-i18next";
import {PictureAsPdf} from "@mui/icons-material";

const ProjectItem = ({item, data}) => {
    const {
        mutate: loadCourses,
        isError,
        data: quizData,
        isSuccess,
        isLoading,
    } = useAppMutation(studentServices.loadCourseQuizzes);

    const {
        mutate,
        error,
        isError: isErrorEvaluation,
        data: evaluation,
        isLoading: isLoadingEvaluation,
        isSuccess: isSuccessEvaluation,
    } = useAppMutation(studentServices.evaluateQuiz);

    const {
        mutate: evaluateFirstQuizPDF,
        isError: isErrorEvaluationFirstPDF,
        data: evaluationFirstPDF,
        isLoading: isLoadingEvaluationFirstPDF,
        isSuccess: isSuccessEvaluationFirstPDF,
    } = useAppMutation(studentServices.evaluateFirstQuizPDF);

    const {
        mutate: bookingPDF,
        isError: isErrorBookingPDF,
        data: BookingPDFData,
        isLoading: isLoadingBookingPDF,
        isSuccess: isSuccessBookingPDF,
    } = useAppMutation(studentServices.bookingPDF);

    const navigate = useNavigate();
    const {t} = useTranslation();
    const [quizzes, setQuizzes] = useState([]);
    const [expanded, setExpanded] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogType, setDialogType] = useState("");
    const [openDialogResult, setOpenDialogResult] = React.useState(false);

    const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
    const [timer, setTimer] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const timerRef = useRef(null);
    const [showInstructions, setShowInstructions] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [quizResults, setQuizResults] = useState(null);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const [errorMessage, setErrorMessage] = useState("");
    const [resultMessage, setResultMessage] = useState("");
    const [resultScreen, setResultScreen] = useState(false);
    const [selectedAnswersArray, setSelectedAnswersArray] = useState([]);

    const [errMsg, setErrMsg] = React.useState("");
    const [successMsg, setSuccessMsg] = React.useState("");

    const [startTime, setStartTime] = useState(null);
    const [evaluationType, setEvaluationType] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [attemptedTime, setAttemptedTime] = useState(null);
    const [duration, setDuration] = useState(15 * 60 * 1000);

    useEffect(() => {
        if (isSuccess) {
            setQuizzes(quizData);
            console.log("Course-Quiz----------->", quizData);
        }
    }, [isSuccess, quizData]);

    useEffect(() => {
        if (isSuccessEvaluationFirstPDF) {
            // Decode Base64 string and create a Blob URL
            const byteCharacters = atob(evaluationFirstPDF.base64); // Decode Base64 string
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: "application/pdf"});
            const blobUrl = URL.createObjectURL(blob);

            // Log the Blob URL for debugging
            console.log("Blob URL:", blobUrl);

            const fileName =
                evaluationType === "start"
                    ? `First Evaluation Result (${item.name}).pdf`
                    : `Final Evaluation Result (${item.name}).pdf`;

            // Create a link element and click it to trigger the download
            const link = document.createElement("a");
            link.href = blobUrl;
            //link.download = 'First Evaluation Result.pdf'; // Specify the filename
            //link.download = `First Evaluation Result (${item.name}).pdf`;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if (isErrorEvaluationFirstPDF) {
            console.error("Error evaluating PDF:", isErrorEvaluationFirstPDF);
        }
    }, [
        isSuccessEvaluationFirstPDF,
        isErrorEvaluationFirstPDF,
        evaluationFirstPDF,
    ]);

    useEffect(() => {
        if (isSuccessBookingPDF) {
            // Decode Base64 string and create a Blob URL
            const byteCharacters = atob(BookingPDFData.base64); // Decode Base64 string
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: "application/pdf"});
            const blobUrl = URL.createObjectURL(blob);

            // Log the Blob URL for debugging
            console.log("Blob URL:", blobUrl);

            // Create a link element and click it to trigger the download
            const link = document.createElement("a");
            link.href = blobUrl;
            //link.download = 'First Evaluation Result.pdf'; // Specify the filename
            link.download = `Bookings (${item.name}).pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if (isErrorBookingPDF) {
            console.error("Error evaluating PDF:", isErrorBookingPDF);
        }
    }, [isSuccessBookingPDF, isErrorBookingPDF, BookingPDFData]);

    useEffect(() => {
        if (isSuccessEvaluation) {
            setSuccessMsg("Evaluation Completed Successfully");
            setOpenDialogResult(true);
        } else if (error && isErrorEvaluation) {
            setErrMsg(error.message || error);
        }
    }, [isSuccessEvaluation, isErrorEvaluation, error]);

    useEffect(() => {
        let timerId;

        // Check if there is a valid timer to run
        if (timer > 0) {
            timerId = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(timerId);
                        handleEndQuiz(); // Trigger end quiz handler
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000); // Update every second
        }

        // Cleanup interval on component unmount or when timer changes
        return () => clearInterval(timerId);
    }, [timer]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    const handleStartEvaluation = () => {
        loadCourses({courseId: item.courseId}); // Load quizzes for the specific course
        setDialogType("instructions");
        setOpenDialog(true);
        setShowInstructions(true);
    };

    const startQuiz = (index) => {
        const totalDuration = quizzes.reduce(
            (total, quiz) => total + quiz.duration,
            0,
        );
        setTimer(totalDuration * 60 || 0);

        setCurrentQuizIndex(index);
        //setTimer(quizzes[index]?.duration * 60 || 0); // Set timer duration in seconds
        //setIsTimerRunning(true);
        setShowInstructions(false); // Hide instructions
        setStartTime(new Date().getTime());
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        // Clear selected answers when closing the dialog
        setSelectedAnswers({});
    };

    const handleCheckboxChange = (questionId, choiceId) => {
        setSelectedAnswers((prevState) => ({
            ...prevState,
            [questionId]: choiceId,
        }));
    };

    const handleEndQuiz = () => {
        const answersArray = [];

        quizzes.forEach((quiz) => {
            quiz.questions.forEach((question) => {
                const userAnswer = selectedAnswers[question.questionId];
                answersArray.push({
                    questionId: question.questionId,
                    choiceId: userAnswer,
                    quizId: quiz.quizId,
                });
            });
        });

        const endTime = new Date().getTime();
        const attemptedTimeInSeconds = Math.max((endTime - startTime) / 1000, 0); // Time in seconds
        const attemptedTimeFormatted = formatTime(attemptedTimeInSeconds);

        console.log("Attempted Time:", attemptedTimeFormatted);

        // Pass answers to the backend for calculation
        mutate({
            transactionId: item.transactionId,
            userAnswers: answersArray,
            courseId: item.courseId,
            type: !item?.startEvaluation ? "start" : "end",
            attemptedTime: attemptedTimeFormatted, // Pass the formatted attempted time
            attemptedDateTime: new Date().toISOString(),
            startTime: new Date(startTime).toISOString(),
        });

        setSelectedAnswersArray(answersArray);
        console.log("Selected Answers Array:", answersArray);

        // Optional: Update the UI state if needed
        setQuizResults({});
        setSelectedAnswers({});
        setCurrentQuestionIndex(0); // Reset question index
        setCurrentQuizIndex(0); // Optionally reset quiz index
        setOpenDialog(false);
    };

    const handleNextQuestion = () => {
        const currentQuiz = quizzes[currentQuizIndex];
        const currentQuestion = currentQuiz.questions[currentQuestionIndex];

        if (!selectedAnswers[currentQuestion.questionId]) {
            setErrorMessage("Please select an answer before proceeding.");
            setTimeout(() => {
                setErrorMessage("");
            }, 5000);
            return;
        }

        setErrorMessage("");

        if (currentQuestionIndex < currentQuiz.questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else if (currentQuizIndex < quizzes.length - 1) {
            setCurrentQuizIndex(currentQuizIndex + 1);
            setCurrentQuestionIndex(0);
            //setTimer(quizzes[currentQuizIndex + 1]?.duration * 60 || 0); // Reset timer for the next quiz
        } else {
            //setIsTimerRunning(false);
            handleEndQuiz();
        }
    };

    const renderInstructionContent = () => (
        <FormControl fullWidth noValidate autoComplete="off">
            <Div>
                <CardContent
                    sx={{
                        pt: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Div
                        sx={{
                            display: "flex",
                            alignItems: "left",
                            flexDirection: "column",
                            textAlign: "center",
                        }}
                    >
                        <Div sx={{textAlign: "left"}}>
                            <Typography variant="body1" sx={{mb: 2}}>
                                Please read the following instructions carefully before starting
                                the quiz:
                            </Typography>
                        </Div>

                        <Typography
                            variant="body2"
                            sx={{marginBottom: "10px", textAlign: "left"}}
                        >
                            1. Read the questions carefully and answer
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{marginBottom: "10px", textAlign: "left"}}
                        >
                            2. Each quiz has a time slot. You must complete the quiz within
                            the given time slot.
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{marginBottom: "10px", textAlign: "left"}}
                        >
                            3. You can attempt the quiz only once. Once you press the next
                            button, you cannot go back to the previous quiz.
                        </Typography>

                        {quizData &&
                            (() => {
                                // Calculate the total duration of all quizzes
                                const totalDuration = quizData.reduce(
                                    (acc, quiz) => acc + quiz.duration,
                                    0,
                                );

                                return (
                                    <>
                                        {quizData.map((quiz, index) => {
                                            console.log(`Quiz ${index + 1}:`, quiz); // Logs the quiz object for debugging

                                            return (
                                                <Div
                                                    key={quiz.quizId}
                                                    sx={{textAlign: "left", mt: 1}}
                                                >
                                                    <Typography variant="body2" sx={{}}>
                                                        Topic {index + 1} : {quiz.title} {"(Duration: "}
                                                        {quiz.duration} mins)
                                                    </Typography>
                                                    <Typography variant="body2" sx={{mb: 1}}>
                                                        Total Questions: {quiz.questions.length}
                                                    </Typography>
                                                </Div>
                                            );
                                        })}
                                        {/* Display total time of all quizzes */}
                                        <Typography variant="body2" sx={{mb: 1}}>
                                            Total Time: {totalDuration} mins
                                        </Typography>
                                    </>
                                );
                            })()}

                        {/* Adding the quiz instructions here */}
                    </Div>

                    <Div
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                        }}
                    >
                        {/* Buttons for starting quiz and closing the dialog */}
                        <Button
                            variant="contained"
                            size="small"
                            sx={{width: "100%", marginBottom: "10px"}}
                            onClick={() => {
                                setShowInstructions(false);
                                startQuiz(0); // Start the first quiz
                            }}
                        >
                            Start Quiz
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{width: "100%"}}
                            onClick={() => setOpenDialog(false)}
                        >
                            Close
                        </Button>
                    </Div>
                </CardContent>
            </Div>
        </FormControl>
    );

    const renderQuizContent = () => {
        if (quizzes.length === 0)
            return <Typography>No quizzes available</Typography>;

        const currentQuiz = quizzes[currentQuizIndex];
        const isLastQuiz = currentQuizIndex === quizzes.length - 1;
        const currentQuestion = currentQuiz.questions[currentQuestionIndex];

        if (!currentQuestion) return null; // Handle case where there are no questions

        return (
            <FormControl fullWidth noValidate autoComplete="off">
                <Div>
                    <CardContent
                        sx={{
                            pt: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Div
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                textAlign: "center",
                            }}
                        >
                            <Typography variant="h5" sx={{marginBottom: "20px"}}>
                                {currentQuiz.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    marginBottom: "20px",
                                    textAlign: "center",
                                    fontStyle: "italic",
                                }}
                            >
                                {/*(time: {currentQuiz.duration} min)*/}
                                <span style={{marginLeft: "10px", fontWeight: "bold"}}>
                  Duration: {formatTime(timer)}
                </span>
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{marginBottom: "20px"}}
                                dangerouslySetInnerHTML={{
                                    __html: currentQuestion.questionText,
                                }}
                            />
                            {currentQuestion.choices &&
                                currentQuestion.choices.map((choice, choiceIndex) => (
                                    <Div
                                        key={choice.choiceId}
                                        sx={{
                                            marginBottom: "15px",
                                            textAlign: "left",
                                            width: "100%",
                                        }}
                                    >
                                        <Typography variant="body2" sx={{marginLeft: "20px"}}>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    selectedAnswers[currentQuestion.questionId] ===
                                                    choice.choiceId
                                                }
                                                onChange={() =>
                                                    handleCheckboxChange(
                                                        currentQuestion.questionId,
                                                        choice.choiceId,
                                                    )
                                                }
                                            />
                                            {String.fromCharCode(97 + choiceIndex)}){" "}
                                            {choice.choiceText}
                                        </Typography>
                                    </Div>
                                ))}
                            {currentQuestionIndex < currentQuiz.questions.length - 1 ||
                            currentQuizIndex < quizzes.length - 1 ? (
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{mr: 1, width: "50%", mb: 1, mt: 2}}
                                    onClick={handleNextQuestion}
                                >
                                    Next
                                </Button>
                            ) : (
                                isLastQuiz && (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{mr: 1, width: "50%", mb: 1, mt: 2}}
                                        onClick={handleEndQuiz}
                                        disabled={isLoadingEvaluation}
                                    >
                                        Submit Quiz
                                    </Button>
                                )
                            )}
                            <Button
                                variant="contained"
                                size="small"
                                sx={{mr: 1, width: "50%"}}
                                onClick={handleCloseDialog}
                            >
                                Close
                            </Button>
                            {errorMessage && (
                                <div style={{color: "red", marginTop: "8px"}}>
                                    {errorMessage}
                                </div>
                            )}
                            {resultMessage && (
                                <div style={{color: "green", marginTop: "8px"}}>
                                    {resultMessage}
                                </div>
                            )}
                        </Div>
                    </CardContent>
                </Div>
            </FormControl>
        );
    };

    const renderDialogContent = () => {
        return showInstructions ? renderInstructionContent() : renderQuizContent();
    };

    const handleClick = () => {
        if (data.find((course) => course.id === item.id)) setExpanded(!expanded);
    };

    const handleBookingButton = () => {
        navigate("/dashboard/bookings");
    };

    const handleStartPDF = () => {
        setEvaluationType("start");
        evaluateFirstQuizPDF({
            transactionId: item.transactionId,
            type: item.startEvaluation?.type,
        });
    };

    const handleBookingPDF = () => {
        bookingPDF({
            transactionId: item.transactionId,
        });
    };

    const handleFinalPDF = () => {
        setEvaluationType("end");
        evaluateFirstQuizPDF({
            transactionId: item.transactionId,
            type: item.endEvaluation?.type,
        });
    };

    const progressValue = ({item}) => {

        const {noOfBooking, noOfUsedBooking} = item;
        const total = noOfBooking + 1 + 1;
        let count = noOfUsedBooking;
        if (item.startEvaluation) {
            count++;
            if (item.endEvaluation) {
                count++;
            }
        }
        return ((count / total) * 100).toFixed(2);
    }
    return (
        <Card sx={{mb: 1}}>
            <Accordion expanded={expanded} square sx={{borderRadius: 2}}>
                <AccordionSummary
                    expandIcon={
                        <Div onClick={handleClick} sx={{mt: 1}}>
                            {expanded ? <RemoveIcon/> : <AddIcon/>}
                        </Div>
                    }
                    /*expandIcon={expanded ? <RemoveIcon/> : <AddIcon/>}*/
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: "row-reverse",

                        "& .MuiAccordionSummary-content": {
                            alignItems: "center",

                            "&.Mui-expanded": {
                                margin: "12px 0",
                            },
                        },
                        ".MuiAccordionSummary-expandIconWrapper": {
                            borderRadius: 1,
                            border: 1,
                            color: "text.secondary",
                            borderColor: "divider",
                            transform: "none",
                            height: 28,
                            width: 28,
                            alignItems: "center",
                            justifyContent: "center",
                            mr: 1,

                            "&.Mui-expanded": {
                                transform: "none",
                                color: "primary.main",
                                borderColor: "primary.main",
                            },

                            "& svg": {
                                fontSize: "1.25rem",
                            },
                        },
                    }}
                >
                    <Div sx={{flexShrink: 0, px: 1}}>
                        {item.images?.length > 0 ? (
                            <DDImage
                                sx={{width: 52, height: 52}}
                                path={item.images[0]}
                                alt={item.firstName + " " + item.lastName}
                            />
                        ) : (
                            <Avatar
                                sx={{width: 52, height: 52}}
                                alt={item.firstName + " " + item.lastName}
                            />
                        )}
                    </Div>
                    <Div
                        sx={{
                            width: {xs: "auto", lg: "20%"},
                            flexShrink: 0,
                            px: 1,
                            flex: {xs: "1", lg: "0 1 auto"},
                        }}
                    >
                        <Typography variant={"h5"} mb={0.5} fontSize={14}>
                            {item.name}
                        </Typography>
                        <Typography
                            fontSize={"12px"}
                            variant={"body1"}
                            color={"text.secondary"}
                        >
                            <CalendarTodayOutlinedIcon
                                sx={{
                                    verticalAlign: "middle",
                                    fontSize: "1rem",
                                    mt: -0.25,
                                    mr: 1,
                                }}
                            />
                            {new Intl.DateTimeFormat("en-US", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                            }).format(new Date(item.startDate))}
                        </Typography>
                    </Div>

                    <Div
                        sx={{
                            display: {xs: "none", lg: "block"},
                            width: "15%",
                            flexShrink: 0,
                            px: 1,
                        }}
                    >
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={0.25}
                        >
                            {t("student.title.deadline")}
                        </Typography>
                        <Typography variant={"body1"}>
                            {new Intl.DateTimeFormat("en-US", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                            }).format(new Date(item.expiredDate))}
                        </Typography>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={0.25}
                            mt={0.25}
                        >
                            {t("student.title.progress")}{" "}
                            {progressValue({item})}
                            %
                        </Typography>

                        <LinearProgress
                            variant={"determinate"}
                            color={"info"}
                            value={progressValue({item})}
                            sx={{
                                height: 6,
                                borderRadius: 2,
                                backgroundColor: "#E9EEEF",
                                mt: 0.5,
                            }}
                        />
                    </Div>

                    <Div
                        sx={{
                            display: {xs: "none", lg: "block"},
                            width: "10%",
                            flexShrink: 0,
                            px: 1,
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={0.25}
                        >
                            {t("student.title.noOfBookings")}
                        </Typography>
                        <Typography
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                            variant={"body1"}
                        >
                            {item.noOfBooking}
                        </Typography>
                    </Div>
                    <Div
                        sx={{
                            display: {xs: "none", lg: "block"},
                            width: "10%",
                            flexShrink: 0,
                            px: 1,
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={0.25}
                        >
                            {t("student.title.noOfUsedBookings")}
                        </Typography>
                        <Typography
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                            variant={"body1"}
                        >
                            {item.noOfUsedBooking}
                        </Typography>
                    </Div>
                    <Div
                        sx={{
                            display: {xs: "none", lg: "block"},
                            width: "10%",
                            flexShrink: 0,
                            px: 1,
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={0.25}
                        >
                            {t("student.title.noOfhours")}
                        </Typography>
                        <Typography
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                            variant={"body1"}
                        >
                            {item.noOfHours}
                        </Typography>
                    </Div>
                    <Div
                        sx={{
                            display: {xs: "none", lg: "block"},
                            flexShrink: 0,
                            px: 1,
                            textAlign: "center",
                            width: "12%",
                        }}
                    >
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={0.25}
                            sx={{
                                display: {xs: "none", lg: "block"},
                            }}
                        >
                            {t("student.title.status")}
                        </Typography>
                        {/*<Chip color={item?.status?.chip_color} size={"small"} label={item.status?.label}/>*/}
                        {!item?.endEvaluation && !item?.startEvaluation && (
                            <Chip
                                color={"default"}
                                size={"small"}
                                label={t("student.title.notStarted")}
                            />
                        )}
                        {item?.startEvaluation && !item?.endEvaluation && (
                            <Chip
                                color={"warning"}
                                size={"small"}
                                label={t("student.title.inProgress")}
                            />
                        )}
                        {item?.endEvaluation && item?.startEvaluation && (
                            <Chip
                                color={"success"}
                                size={"small"}
                                label={t("student.title.completed")}
                            />
                        )}

                        {/*<Chip color={item.endEvaluation ? "warning" : "success"} size={"small"}
                              label={item.endEvaluation ? "Completed" : "In Progress"}/>*/}
                    </Div>
                    <Div
                        sx={{
                            display: {xs: "none", lg: "block"},
                            width: "20%",
                            flexShrink: 0,
                            px: 1,
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={0.25}
                            sx={{
                                display: {xs: "none", lg: "block"},
                            }}
                        >
                            {t("student.title.action")}
                        </Typography>

                        {/* Chip component with an onClick event */}
                        {!item.startEvaluation && (
                            <Chip
                                color={"error"}
                                size={"small"}
                                label={t("student.title.startEvaluation")}
                                onClick={handleStartEvaluation} // Add onClick here
                            />
                        )}

                        {item.startEvaluation &&
                            item.noOfUsedBooking < item.noOfBooking && (
                                <Chip
                                    color={"error"}
                                    size={"small"}
                                    label={t("student.title.startBooking")}
                                    onClick={handleBookingButton} // Add onClick here
                                />
                            )}

                        {item.startEvaluation &&
                            item.noOfBooking === item.noOfUsedBooking && (
                                <Chip
                                    color={"error"}
                                    size={"small"}
                                    label={t("student.title.endEvaluation")}
                                    onClick={handleStartEvaluation} // Add onClick here
                                />
                            )}
                    </Div>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        borderTop: 1,
                        borderColor: "divider",
                        p: (theme) => theme.spacing(2, 2, 2, 8.25),
                    }}
                >
                    {/*<Typography variant={"h5"}>Description</Typography>*/}
                    <Div
                        sx={{
                            display: {xs: "flex", lg: "none"},
                            minWidth: 0,
                            flexDirection: "column",
                        }}
                    >
                        <Div
                            sx={{
                                display: "flex",
                                minWidth: 0,
                                alignItems: "center",
                                justifyContent: "space-between",
                                mt: 1,
                                mb: 2,
                            }}
                        >
                            <Div>
                                <Typography variant={"h5"}>Description</Typography>
                            </Div>

                            {!item?.endEvaluation && !item?.startEvaluation && (
                                <Chip
                                    color={"default"}
                                    size={"small"}
                                    label={t("student.title.notStarted")}
                                />
                            )}
                            {item?.startEvaluation && !item?.endEvaluation && (
                                <Chip
                                    color={"warning"}
                                    size={"small"}
                                    label={t("student.title.inProgress")}
                                />
                            )}
                            {item?.endEvaluation && item?.startEvaluation && (
                                <Chip
                                    color={"success"}
                                    size={"small"}
                                    label={t("student.title.completed")}
                                />
                            )}
                        </Div>
                    </Div>
                    <Div
                        sx={{
                            display: {xs: "flex", lg: "none"},
                            minWidth: 0,
                            flexDirection: "column",
                        }}
                    >
                        <Div
                            sx={{
                                display: "flex",
                                minWidth: 0,
                                alignItems: "center",
                                justifyContent: "space-between",
                                mt: 1,
                                mb: 2,
                            }}
                        >
                            <Div>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={0.25}
                                >
                                    {t("student.title.deadline")}
                                </Typography>
                                <Typography variant={"body1"}>
                                    {new Intl.DateTimeFormat("en-US", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                    }).format(new Date(item.expiredDate))}
                                </Typography>
                            </Div>

                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={0.25}
                                sx={{
                                    display: {xs: "none", lg: "block"},
                                }}
                            >
                                Evaluation
                            </Typography>

                            {/* Chip component with an onClick event */}
                            {!item.startEvaluation && (
                                <Chip
                                    color={"error"}
                                    size={"small"}
                                    label={t("student.title.startEvaluation")}
                                    onClick={handleStartEvaluation} // Add onClick here
                                />
                            )}

                            {item.startEvaluation &&
                                item.noOfUsedBooking < item.noOfBooking && (
                                    <Chip
                                        color={"error"}
                                        size={"small"}
                                        label={t("student.title.startBooking")}
                                        onClick={handleBookingButton} // Add onClick here
                                    />
                                )}

                            {item.startEvaluation &&
                                item.noOfBooking === item.noOfUsedBooking && (
                                    <Chip
                                        color={"error"}
                                        size={"small"}
                                        label={t("student.title.endEvaluation")}
                                        onClick={handleStartEvaluation} // Add onClick here
                                    />
                                )}
                        </Div>
                        <Div sx={{mb: 3, maxWidth: 280}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={1}
                            >
                                {t("student.title.progress")}{" "}
                                {progressValue({item})}
                                %
                            </Typography>
                            <LinearProgress
                                variant={"determinate"}
                                color={"info"}
                                value={
                                    progressValue({item})
                                }
                                sx={{
                                    height: 6,
                                    borderRadius: 2,
                                    backgroundColor: "#E9EEEF",
                                    mt: 0.5,
                                }}
                            />
                        </Div>
                    </Div>
                    <Typography
                        variant="body1"
                        mb={0.5}
                        dangerouslySetInnerHTML={{
                            __html: item.descriptionHTML,
                        }}
                    />
                    <Grid
                        container
                        spacing={1}
                        sx={{mt: 2, justifyContent: "flex-start"}}
                    >
                        {item.startEvaluation && item.startEvaluation.type === "start" && (
                            <Grid
                                item
                                xs="auto"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Chip
                                    color={"primary"}
                                    size={"small"}
                                    label={t("student.title.startPdf")}
                                    onClick={handleStartPDF}
                                    icon={<PictureAsPdf/>}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        px: 1, // Padding on the x-axis
                                        py: 0.5, // Padding on the y-axis
                                        "& .MuiChip-icon": {
                                            marginRight: -0.5, // Margin to the right of the icon
                                        },
                                    }}
                                />
                            </Grid>
                        )}

                        {item.bookingId && item.bookingId.length > 0 && (
                            <Grid
                                item
                                xs="auto"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Chip
                                    color={"primary"}
                                    size={"small"}
                                    label={t("student.title.bookingPdf")}
                                    onClick={handleBookingPDF}
                                    icon={<PictureAsPdf/>}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        px: 1, // Padding on the x-axis
                                        py: 0.5, // Padding on the y-axis
                                        "& .MuiChip-icon": {
                                            marginRight: -0.5, // Margin to the right of the icon
                                        },
                                    }}
                                />
                            </Grid>
                        )}

                        {item.endEvaluation && item.endEvaluation.type === "end" && (
                            <Grid
                                item
                                xs="auto"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Chip
                                    color={"primary"}
                                    size={"small"}
                                    label={t("student.title.endPdf")}
                                    onClick={handleFinalPDF}
                                    icon={<PictureAsPdf/>}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        px: 1, // Padding on the x-axis
                                        py: 0.5, // Padding on the y-axis
                                        "& .MuiChip-icon": {
                                            marginRight: -0.5, // Margin to the right of the icon
                                        },
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </AccordionDetails>

                <Div>
                    <Dialog
                        fullWidth
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                    >
                        <DialogTitle>
                            {showInstructions ? "Instructions" : "Quiz"}
                        </DialogTitle>
                        <DialogContent>{renderDialogContent()}</DialogContent>
                    </Dialog>
                </Div>

                <Div>
                    <Dialog fullWidth open={openDialogResult}>
                        {/*<DialogTitle>{(t('common.resetPassword'))}</DialogTitle>*/}
                        <Div sx={{position: "relative", textAlign: "center"}}>
                            <DialogTitle>{"Quiz Result"}</DialogTitle>
                            <IconButton
                                onClick={() => setOpenDialogResult(false)}
                                sx={{position: "absolute", top: "8px", right: "16px"}}
                            >
                                <img src="/images/icons/close.png" alt="Close" width="18"/>
                            </IconButton>
                        </Div>
                        <DialogContent
                            sx={{
                                overflowY: "scroll",
                                height: "auto",
                                "::-webkit-scrollbar": {
                                    display: "none",
                                },
                                "-ms-overflow-style": "none", // IE and Edge
                                "scrollbar-width": "none", // Firefox
                            }}
                        >
                            <CardContent
                                sx={{
                                    pt: 0,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Div
                                    sx={{
                                        display: "flex",
                                        alignItems: "left",
                                        flexDirection: "column",
                                        textAlign: "center",
                                    }}
                                >
                                    {successMsg && (
                                        <Typography variant="body1">{successMsg}</Typography>
                                    )}
                                    <Typography variant="body1">
                                        Total Questions : {evaluation?.totalQuestions}
                                    </Typography>

                                    <Typography variant="body1" sx={{mb: 2}}>
                                        Correct Answers : {evaluation?.totalCorrectAnswers}
                                    </Typography>
                                </Div>

                                <Div
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        textAlign: "center",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{width: "100%"}}
                                        onClick={() => {
                                            setOpenDialogResult(false);
                                            window.location.reload();
                                        }}
                                        //onClick={() => setOpenDialogResult(false)}
                                    >
                                        Close
                                    </Button>
                                </Div>
                            </CardContent>
                        </DialogContent>
                    </Dialog>
                </Div>
            </Accordion>
        </Card>
    );
};
/* Todo item prop define */
export default ProjectItem;
