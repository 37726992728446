import React, { useEffect, useState } from "react";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import { useAppMutation } from "../../../../services";
import { useTranslation } from "react-i18next";
import teacherServices from "../../../../services/teacher-services";
import { useCalendarContext } from "../context";

const ChildInfoForm = ({}) => {
  const [stepsData, setStepsData] = useState(undefined);
  const [activeStep, setActiveStep] = React.useState(0);
  const { globalState, setGlobalState } = useCalendarContext();
  const {
    mutate: loadStudent,
    isError,
    data,
    isSuccess,
    isLoading,
  } = useAppMutation(teacherServices.loadStudentByTeacher);
  const { t } = useTranslation();
  const steps = [
    t("childForm.step1"),
    t("childForm.step2"),
    t("childForm.step3"),
    t("childForm.step4"),
  ];

  useEffect(() => {
    console.log("global----", globalState.studentId);
    if (globalState && globalState.studentId) {
      loadStudent(globalState.studentId);
    }
  }, [globalState]);

  useEffect(() => {
    if (isSuccess) {
      console.log("Steps--------", isSuccess, data);
      setStepsData(data);
    } else if (isError) {
    } else if (globalState && globalState.studentId) {
      // setStepsData({})
      // loadStudent(globalState.studentId);
    }
  }, [isSuccess, isError]);

  const handleNext = (step) => {
    // alert(step);

    if (step === 5) {
      hideDialog(false);
    } else {
      setActiveStep(step - 1);
    }
  };

  const hideDialog = () => {
    delete globalState.showStudentInfo;
    delete globalState.studentId;
    setGlobalState({ ...globalState });
  };

  return (
    <Div sx={{ width: "100%" }}>
      {activeStep === steps.length ? (
        <Div>
          <Typography sx={{ mt: 2, mb: 1, justifyContent: "center" }}>
            All steps completed - you&apos;re finished
          </Typography>
        </Div>
      ) : (
        <Div>
          {activeStep + 1 === 1 && (
            <Step1
              hideDialog={hideDialog}
              handleNext={handleNext}
              stepsData={stepsData}
              setStepsData={setStepsData}
            />
          )}
          {activeStep + 1 === 2 && (
            <Step2
              hideDialog={hideDialog}
              handleNext={handleNext}
              stepsData={stepsData}
              setStepsData={setStepsData}
            />
          )}
          {activeStep + 1 === 3 && (
            <Step3
              hideDialog={hideDialog}
              handleNext={handleNext}
              stepsData={stepsData}
              setStepsData={setStepsData}
            />
          )}
          {activeStep + 1 === 4 && (
            <Step4
              hideDialog={hideDialog}
              handleNext={handleNext}
              stepsData={stepsData}
              setStepsData={setStepsData}
            />
          )}

          {false && activeStep + 1 === 5 && (
            <Step5
              handleNext={handleNext}
              stepsData={stepsData}
              setStepsData={setStepsData}
            />
          )}
        </Div>
      )}
    </Div>
  );
};

export default ChildInfoForm;
