import Div from "@jumbo/shared/Div";
import React from "react";
import {Alert, Button, FormControl, TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import {useAppMutation} from "../../../../services";
import teacherServices from "../../../../services/teacher-services";
import {useTranslation} from "react-i18next";


const steps = ['Basic Info', 'Complete', 'Finish'];

const Step1 = ({booking, handleNext, hide, setBooking}) => {

    const [values, setValues] = React.useState({
        comments:'',
        bookName:'',
        pageNumber:'',
        workedOn:'',
        completed:'',
        homeWorkLink2:'',
        homeWorkLink:'',
        homeWorkLinkPassword:''
    });
    const [errMsg, setErrMsg] = React.useState('');
    const {t} = useTranslation();
    const {
        mutate,
        isSuccess,
        isError,
        isLoading,
        data
    } = useAppMutation(teacherServices.saveBookingFeedBack);

    React.useEffect(() => {

        if (booking.teacherFeedback) {
            setValues({...booking.teacherFeedback})
        }

    }, [booking])


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        if (prop === 'pageNumber') {

            const value = event.target.value;

            if(/^-?\d+$/.test(value)){
                setValues({...values, [prop]: event.target.value});
            }else{
            }

        } else {
            setValues({...values, [prop]: event.target.value});

        }

    };

    const handleComplete = () => {
        if (!validateForm()) {
            setErrMsg(t("common.inputError"));
            return;
        }

        const updatedBooking = { ...booking, teacherFeedback: { ...booking.teacherFeedback, ...values } };
        setBooking(updatedBooking);
        handleNext({ step: 3 });

    }
    const validateForm = () => {
        const requiredFields = ['comments', 'bookName', 'pageNumber', 'workedOn', 'completed'];
        for (const field of requiredFields) {
            if (!values[field]) {
                return false;
            }
        }
        return true;

    };


    return <Div sx={{width: '100%', minHeight: 100, minWidth: {xs: 500, sm: 300, xl: 500}}}>

        <Div sx={{mt: 3}}>

            <FormControl noValidate autoComplete="off">
                <Div
                    sx={{
                        '& .MuiTextField-root': {
                            mb: 1.4,
                            mt: 0.5
                        },
                    }}
                >


                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t('feedbackForm.generalFeedback')}
                            multiline
                            rows={3}
                            fullWidth
                            value={values.comments}
                            onChange={handleChange('comments')}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t('feedbackForm.bookName')}
                            placeholder="Book Name"
                            fullWidth
                            value={values.bookName}
                            onChange={handleChange('bookName')}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t('feedbackForm.pageNo')}
                            placeholder="Page Number"
                            fullWidth
                            value={values.pageNumber}
                            onChange={handleChange('pageNumber')}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t('feedbackForm.worked')}
                            placeholder="Worked On Text Here"
                            fullWidth
                            value={values.workedOn}
                            onChange={handleChange('workedOn')}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            required
                            label={t('feedbackForm.completed')}
                            placeholder="Completed Text Here"
                            fullWidth
                            value={values.completed}
                            onChange={handleChange('completed')}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            label={t('feedbackForm.home')}
                            placeholder="Enter Link here"
                            fullWidth
                            value={values.homeWorkLink}
                            onChange={handleChange('homeWorkLink')}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label={t('feedbackForm.home2')}
                            placeholder="Enter Link here"
                            fullWidth
                            value={values.homeWorkLink2}
                            onChange={handleChange('homeWorkLink2')}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label={t('feedbackForm.homePass')}
                            placeholder="Enter Link Password here"
                            fullWidth
                            value={values.homeWorkLinkPassword}
                            onChange={handleChange('homeWorkLinkPassword')}
                        />
                    </FormControl>

                    <Stack
                        sx={{
                            mb: 2,
                            width: '90%',
                            mx: 'auto',
                            display: 'block',
                            position: 'relative',
                        }}
                        spacing={2}>
                        {errMsg && <Alert severity="error">{errMsg}</Alert>}
                    </Stack>

                    <Div sx={{display: 'flex', flexDirection: 'row', pt: 2}}>

                        <Button
                            onClick={() => {
                                hide()
                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.closeBtn")}
                        </Button>
                        <Button
                            onClick={() => {


                                handleNext({step: 1})


                            }}
                            sx={{mr: 1}}
                        >
                            {t("common.backBtn")}
                        </Button>
                        <Div sx={{flex: '1 1 auto'}}/>

                        <LoadingButton
                            variant="variant"
                            loading={isLoading}
                            onClick={handleComplete}
                        >
                            {t("common.nextBtn")}
                        </LoadingButton>

                    </Div>

                </Div>
            </FormControl>
        </Div>
    </Div>;

}

export default Step1;
