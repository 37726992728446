import appAxios from "./config";
import adminServices from "./admin-services";

const teacherServices = {};

teacherServices.signUp = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/signup", obj);
  return data;
};

teacherServices.forgotPassword = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/forgot-password", obj);
  return data;
};

teacherServices.loadTeacherSkills = async () => {
  const { data } = await appAxios.get("v1/teacher/teacher-skills");
  return data;
};

teacherServices.resetForgotPassword = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/reset-forgot-password", obj);
  return data;
};

teacherServices.resetPassword = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/reset-password", obj);
  return data;
};

teacherServices.saveProfileImage = async (base64) => {
  const { data } = await appAxios.post("v1/teacher/profile/image", { base64 });
  return data;
};

teacherServices.loadChildren = async () => {
  const { data } = await appAxios.post("v1/teacher/children", {});
  return data;
};

teacherServices.saveAppointment = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/appointment", obj);
  return data;
};

teacherServices.loadAppointments = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/appointments", obj);
  return data;
};

teacherServices.loadProfileForSteps = async (obj) => {
  const { data } = await appAxios.get("v1/teacher/profile/steps");
  return data;
};

teacherServices.completeStep1 = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/completestep1", obj);
  return data;
};

teacherServices.completeStep2 = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/completestep2", obj);
  return data;
};

teacherServices.completeStep3 = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/completestep3", obj);
  return data;
};

teacherServices.completeStep4 = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/completestep4", obj);
  return data;
};

teacherServices.loadProfile = async () => {
  const { data } = await appAxios.get("v1/teacher/profile");
  return data;
};

// location {  city , country, lskdfjsdlf}
teacherServices.updateProfileAddressDetails = async (obj) => {
  const { data } = await appAxios.post(
    "v1/teacher/profile/address-details",
    obj,
  );
  return data;
};

teacherServices.updateProfileContactInfo = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/profile/contact-info", obj);
  return data;
};

// meetingLink
teacherServices.updateProfileMeetingLink = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/profile/meeting-link", obj);
  return data;
};

// subscribeNewsLetter
teacherServices.updateProfileNewsLetterSubscription = async (obj) => {
  const { data } = await appAxios.post(
    "v1/teacher/profile/news-letter-subscription",
    obj,
  );
  return data;
};

teacherServices.loadTeachers = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/teachers", obj);
  return data;
};

teacherServices.saveStudentEvaluation = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/student/evaluation", obj);
  return data;
};

teacherServices.loadStudentEvaluation = async (studentId) => {
  const { data } = await appAxios.get(
    "v1/teacher/student-evaluation/" + studentId,
  );
  return data;
};

teacherServices.loadStudentFeedbacks = async (studentId) => {
  const { data } = await appAxios.get(
    "v1/teacher/student-feedbacks/" + studentId,
  );
  return data;
};

teacherServices.checkApproved = async () => {
  const { data } = await appAxios.get("v1/teacher/check-approved");
  return data;
};

teacherServices.studentAttendedBooking = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/booking/attended/", obj);
  return data;
};

teacherServices.studentAttendedAppointment = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/appointment/attended/", obj);
  return data;
};

teacherServices.saveBookingFeedBack = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/booking/feedback", obj);
  return data;
};

teacherServices.saveAvailability = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/availability", obj);
  return data;
};

teacherServices.isEvaluator = async (obj) => {
  const { data } = await appAxios.get("v1/teacher/check-evaluator");
  return data;
};

teacherServices.loadEvents = async (obj) => {
  const { data } = await appAxios.post("v1/teacher/events", obj);
  return data;
};

teacherServices.deleteAvailability = async (id) => {
  const { data } = await appAxios.delete("v1/teacher/availability/" + id);
  return data;
};

teacherServices.deleteAvailabilityByDay = async (obj) => {
  const { data } = await appAxios.post(
    "v1/teacher/delete/availability-by-day/",
    obj,
  );
  return data;
};

teacherServices.deleteAvailabilityByDayAndHour = async (obj) => {
  const { data } = await appAxios.post(
    "v1/teacher/delete/availability-by-day-and-hour/",
    obj,
  );
  return data;
};

teacherServices.loadStudentByTeacher = async (studentId) => {
  const { data } = await appAxios.get("v1/teacher/student/" + studentId);
  return data;
};

export default teacherServices;
